import { useCallback, useEffect, useState } from "react";
import styles from "./Event.module.css";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../Assests/Images/PlusIcon.png";

import InActiveCal from "../../Assests/Images/CalenderInactive.svg";
import ActiveCal from "../../Assests/Images/CalenderActive.svg";

import { Form } from "react-bootstrap";
import EventDetail from "./EventDetail";
import AddEvent from "./AddEvent";
import CustomTabButton, {
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import debounce from "debounce";
import EventList from "./EventList";
import EventListByCalender from "./EventListByCalender";
import { useLocation } from "react-router-dom";

function EventPage() {
  const { t } = useTranslation();

  const location = useLocation();

  const isAdded = location?.state?.isAdded;

  useEffect(() => {
    if (isAdded) {
      setEventTabShow(3);
    }
  }, [isAdded]);

  const [eventTabShow, setEventTabShow] = useState(1);

  const [searchKeyword, setSearchKeyword] = useState("");

  const handleChange = useCallback(
    debounce((event) => {
      setSearchKeyword(event);
    }, 100),
    []
  );

  return (
    <div className={styles.eventWrapper}>
      <div className={styles.topHead}>
        <h2>
          {eventTabShow === 1
            ? t("EVENTS")
            : eventTabShow === 2
            ? t("EVENTS > ADD EVENT")
            : t("EVENTS > ADD EVENT")}
        </h2>
      </div>
      <div className={styles.devider}></div>

      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => {
              setEventTabShow(1);
              setSearchKeyword("");
            }}
            variant={eventTabShow === 1 ? "primary" : "secondary"}
          >
            {t("EVENT_TITLE_TAB")}
          </CustomTabButton>

          {eventTabShow === 2 ? (
            <div
              className={styles.calenderActive}
              onClick={() => {
                setEventTabShow(2);
                setSearchKeyword("");
              }}
            >
              <img
                src={ActiveCal}
                alt="calender"
                width="19px"
                height="19px"
              ></img>
            </div>
          ) : (
            <div
              className={styles.calenderInActive}
              onClick={() => {
                setEventTabShow(2);
                setSearchKeyword("");
              }}
            >
              <img
                src={InActiveCal}
                alt="calender"
                width="19px"
                height="19px"
              ></img>
            </div>
          )}

          <div className={styles.plusIcon}>
            <CustomImgButton
              variant="primary"
              onClick={() => {
                setEventTabShow(3);
                setSearchKeyword("");
              }}
              src={PlusIcon}
              alt="PlusIcon"
            ></CustomImgButton>
          </div>
        </div>

        {eventTabShow === 1 ? (
          <Form.Group className={styles.formGroup}>
            <Form.Control
              className={styles.searchControl}
              type="search"
              placeholder={t("SEARCH_PLACEHOLDER")}
              onChange={(e) => handleChange(e.target.value)}
              value={searchKeyword}
            />
          </Form.Group>
        ) : (
          ""
        )}
      </div>

      {eventTabShow === 1 ? (
        <EventList searchKeyword={searchKeyword} setShowtab={setEventTabShow} setSearchKeyword={setSearchKeyword} />
      ) : eventTabShow === 2 ? (
        <EventListByCalender setShowtab={setEventTabShow} />
      ) : (
        <AddEvent setShowtab={setEventTabShow} />
      )}
    </div>
  );
}

export default EventPage;
