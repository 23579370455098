import { useTranslation } from "react-i18next";
import styles from "../Brands/Brands.module.css";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import { CustomInputField } from "../../ReactBootstrap/CustomInputField/CustomInputField";
import { useState } from "react";
import SublyApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../store/slices/userSlice";
import Toster from "../../Utils/Toster";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Utils/Loader/Loader";

function AddBrand() {
  const { userToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  //===========function for form validation using useform===========
  const { register, handleSubmit, formState, setValue, clearErrors } = useForm({
    mode: "onChange",
    defaultValues: {
      BRAND_DETAIL: "",
    },
  });

  //========= function for calling add brand api=
  function onSubmit(formdata) {
    const requestData = new FormData();
    requestData.append("brandName", formdata.BRAND_NAME);
    requestData.append("email", formdata.EMAIL);
    setIsLoading(true);
    SublyApi.addBrand(requestData, userToken).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("BRAND_ADD_SUCCESSFULLY"), "success");
        navigate("/brands");
      } else if (response.data.code === STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      }
    });
  }

  return (
    <div className={styles.addBrandWrapper}>
      {isLoading && <Loader />}
      <div className={styles.addBorder}>
        <div className={styles.topHead}>
          <h2>
            {t("BRANDS")} {">"} {t("ADD")}
          </h2>
        </div>
      </div>

      <div className={styles.addBrand}>
        <h2>{t("ADD_NEW_BRAND")}</h2>
      </div>

      <div className={styles.brandDetailInfo}>
        <p>
          {t("ADD")} <span>{t("BRAND")}</span>
        </p>
        <h3>{t("ADD_BRAND_NAME")}</h3>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.clubName}>
          <div className={styles.brandText}>
            <CustomInputField
              styleName="inputTextForBrandInfo"
              type="text"
              PlaceHolder={t("BRAND_INFO_PLACEHOLDER")}
              RegisterName={"BRAND_NAME"}
              register={register}
              formState={formState}
              clearErrors={clearErrors}
              onChange={(e) => {
                setValue("BRAND_NAME", e.target.value);
                if (e.target.value == " ")
                  setValue(e.target.name, e.target.value.trim());
              }}
            >
              <p>
                {t("BRAND_CLUB_HEADING")} <span>{t("NAME_TEXT")}</span>
              </p>
            </CustomInputField>
          </div>
        </div>
        <div className={styles.loginEmail}>
          <p>
            {t("LOGIN_EMAIL")} <span>{t("REQUIRED_EMAIL")}</span>
          </p>

          <CustomInputField
            styleName="inputTextForBrandInfo"
            type="text"
            PlaceHolder={t("ENTER_EMAIL_ADDRESS")}
            RegisterName="EMAIL"
            register={register}
            formState={formState}
            clearErrors={clearErrors}
            onChange={(e) => {
              setValue("EMAIL", e.target.value);
              setValue(e.target.name, e.target.value.replace(/\s/g, ""));
            }}
          >
            <p>{t("EMAIL_ADDRESS")} </p>
          </CustomInputField>

          <div className={styles.saveBtnSection}>
            <div className={styles.bottomBtnSection}>
              <div className={styles.cancelBtn}>
                <CustomButton
                  variant="cancelBtn"
                  type="button"
                  onClick={() => {
                    navigate("/brands");
                  }}
                >
                  {t("CANCEL_BUTTON")}
                </CustomButton>
              </div>
              <div className={styles.saveInfo}>
                <CustomButton variant="footerBtnCustom" type="submit">
                  <p>
                    <span>{t("SAVE_BUTTON")}</span>
                  </p>
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default AddBrand;
