export const CONSTANT = {
  ASPECT_RATIO_IN_BRAND_INFO: 1,
  ASPECT_RATIO_IN_ADD_MEMBER: 1,
  ASPECT_RATIO_IN_BRANDING: 1.646,
  BUTTON_TEXT_COLOR: "BTC",
  BACKGROUND_COLOR: "BC",
  TEXT_COLOR: "TC",
  ACTIVE: 1,
  INACTIVE: 0,
  START_DATE: 1,
  END_DATE: 2,

  ASPECT_RATIO_IN_ADD_NEWS: 4 / 3,

  PAGE_ONE: 1,
  SKIP_PAGE: 1,
  // TIME_FORMAT: "h:mm aa",
  TIME_FORMAT: "HH:mm aa",
  TIME_INTERVAL_ONE: 15,
  DEFAULT_COUNTRY: "South Africa",
  SELECT_DATE: 1,
  MAX_IMG_LIMIT: 12,
  REPEATING_DAY: 3,
  ZOOM_LEVEL: 16,
  INACTIVE_BACKGROUND: "#DAF8B5",
  OPACITY_RATIO: "30%",
  MIN_HRS: 1,
  MAX_HRS: 23,
  MIN_MIN: 1,
  MAX_MIN: 59,
  MIN_SEC: 1,
  MAX_SEC: 59,
  UNDEFINED_PACE: "NaN:NaN p/km",
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
  INACTIVE_COLOR: "#E1E1E1",
  SELECT_THROUGH_OPTION: 1,
  GET_DETAIL: 2,
  ADD_MANUALLY: 3,
  ALLOW_PREVIOUS: "Allow choose previous",
  EASY: 1,
  NORMAL: 2,
  HARD: 3,
  TERMS_AND_CONDITION: 2,
  PRIVACY_POLICY: 1,
  CONTACT_US: 3,
  DATE_ORDER: 1,
  ALPHABETICAL: 2,
  NAME: 1,
  SURNAME: 2,
  AGE: 3,
  EXPIRY: 4,
};
