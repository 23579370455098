import { Form } from "react-bootstrap";
import styles from "../Brands/Brands.module.css";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { useForm, useController } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import {
  fetchBrandDetail,
  saveBrandInfo,
  userLogoutClear,
} from "../../store/slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SublyApi from "../../Helpers/Api";
import Loader from "../../Utils/Loader/Loader";
import ReactQuill from "react-quill";
import CropImg from "../../Common/Cropper/CropImg";
import { cleanData, removeExtraPlus } from "../../Utils/RemovePlus/RemovePlus";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Autocomplete } from "@react-google-maps/api";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import {
  CustomCheckBox,
  CustomInputField,
  CustomPhoneInputField,
} from "../../ReactBootstrap/CustomInputField/CustomInputField";
import { CustomSelect } from "../../ReactBootstrap/CustomSelect/CustomSelect";
import { GoogleMapComp } from "../../Common/GoogleMap/GoogleMapComp";
import { UserContext } from "../../Common/Context/UserContext";
import { CONSTANT } from "../../Utils/Constant";
import parsePhoneNumber from "libphonenumber-js";
import { fetchImageAsFile } from "../../Utils/CommonFunction";
import CommonDelete from "../../Common/CommonDelete";

function BrandInfo() {
  const { isLoading, userToken, brandDetailData } = useSelector(
    (state) => state.user
  );
  const fileInputRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch("");
  const navigate = useNavigate("");
  const location = useLocation();
  const brandId = location?.state?.brand_id;
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneDialCode, setPhoneDialCode] = useState("27");
  const [phoneCountryCode, setPhoneCountryCode] = useState("za");
  const [watsappNo, setWatsappNo] = useState("");
  const [dialCodeWatsapp, setDialCodeWatsapp] = useState("27");
  const [countryCodeWatsapp, setCountryCodeWatsapp] = useState("za");
  const [countryData, setCountryData] = useState("");
  const [defaultContryCountry, setDefaulCountry] = useState("vbmOpe");
  const [selectCountry, setSelectCountry] = useState("");
  const [defaultState, setDefaulState] = useState("");
  const [stateData, setStateData] = useState("");
  const [addButtonChecked, setAddButtonChecked] = useState(false);
  const [physicalAdd, setPhysicalAdd] = useState(false);
  const [sourceGeometry, setSourceGeometry] = useState({
    address: "",
    lat: "",
    lng: "",
  });
  const autocomplete = useRef(null);
  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;
  const [previewMembershipImg, setPreviewMembershipImg] = useState(null);
  const [imageSrc, setImageSrc] = useState();
  const [isCropper, setIsCropper] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading , setLoading] = useState(false)

  const handleCloseCrop = () => {
    setShowCrop(false);
    fileInputRef.current.value = "";
  };
  const saveModalOpen = useSelector((state) => state.user.open_modal);
  const { setIsAnyChange, saveChanges, setSaveChanges } =
    useContext(UserContext);

  // //=========== function for handle image selection =====
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Use useEffect to set setSaveChanges(false) when the component mounts
  useEffect(() => {
    setSaveChanges(false);
  }, [setSaveChanges]);

  //===========function for form validation using useform===========
  const {
    register,
    handleSubmit,
    control,
    formState,
    setValue,
    getValues,
    clearErrors,
  
  } = useForm({
    mode: "onChange",
    defaultValues: {
      BRAND_DETAIL: "",
    },
  });

  //====== function for manage google maps==========
  const [position, setPosition] = useState({
    lat: "",
    lng: "",
  });

  const onMarkerDragEnd = (e) => {
    setIsAnyChange(true);
    setPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  //=========disable space========
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };



  // ====== function for select image ============
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setIsCropper(true);
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewMembershipImg(fileUrl);
      setImageSrc(fileUrl);
      setIsAnyChange(true);
    } else {
      setPreviewMembershipImg(null);
    }
  };

  //=========== country api ============
  const getCountryMetaData = () => {
    setLoading(true)
    const response = SublyApi.countryMetaData();
    response.then((res) => {
      setCountryData(res.data.countryMeta);
      setLoading(false)
    });
  };

  //=========== country state api ============
  const getStateMetaData = (countryId) => {
    setLoading(true)
    const response = SublyApi.stateMetaData(userToken, countryId);
    response.then((res) => {
      setStateData(res && res.data.stateMeta);
      setLoading(false)
    });
  };

  const handleCountryChange = (e) => {
    setSelectCountry(e.target.value);
    setValue("COUNTRY", e.target.value);
    setIsAnyChange(true);
    getStateMetaData(e.target.value);
    setValue("STATE", "");
  };

  const handleStateChange = (e) => {
    setValue("STATE", e.target.value);
    setIsAnyChange(true);
  };
  //=========== function for show default country state ============
  useEffect(() => {
    getStateMetaData(defaultContryCountry);
  }, [defaultContryCountry]);

  //=========== function for show default country  ============
  useEffect(() => {
    setValue("COUNTRY", defaultContryCountry);
  }, [countryData]);

  useEffect(() => {
    if (selectCountry != "" && selectCountry != defaultContryCountry) {
      setValue("STATE", "");
    } else {
      setValue("STATE", defaultState);
    }
  }, [stateData, defaultState]);

  //=========== function for calling getCountry data api  ============
  useEffect(() => {
    getCountryMetaData();
  }, []);

  //========= Fetch brand detail when component mounts or when brandId changes=========
  useEffect(() => {
    if (brandId) {
      getDetail();
    }
  }, [brandId]);

  //===== function for calling brand detail api===========
  const getDetail = () => {
    dispatch(fetchBrandDetail({ userToken: userToken, brandId: brandId })).then(
      (res) => {
        const response = res.payload;

        if (response.data && response.data.brandInfo) {
          if (response.data.brandInfo.countryMeta) {
            setDefaulCountry(response.data.brandInfo?.countryMeta);
            setValue("COUNTRY", response.data.brandInfo?.countryMeta);
            setDefaulState(response.data.brandInfo?.stateMeta);
          }
        }
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          navigate("/");
        }
      }
    );
  };

  // -----------function for show data prefilled---------------
  useEffect(() => {
    if (brandId) {
      if (brandDetailData && brandDetailData.brandInfo) {
        let phoneDialCode = brandDetailData.brandInfo.phoneDialCode
          ? brandDetailData.brandInfo.phoneDialCode
          : "+27";
        let phoneNumber = brandDetailData.brandInfo.phoneNumber
          ? brandDetailData.brandInfo.phoneNumber
          : "810316";
        let phoneNumberData = phoneDialCode + phoneNumber;

        let countryData = parsePhoneNumber(`${phoneNumberData}`);

        setValue("BRAND_NAME", brandDetailData.brandInfo.brandName || "");
        setUserName(brandDetailData.brandInfo.brandName || "");
        setValue("BRAND_DETAIL", brandDetailData.brandInfo.details || "");
        setValue("TOWN_CITY", brandDetailData.brandInfo.town || "");
        setValue("CONTACT_EMAIL", brandDetailData.brandInfo.contactEmail || "");
        setValue("WEBSITE_ADDRESS", brandDetailData.brandInfo.webAddress || "");
        setValue(
          "googleAddress",
          brandDetailData.brandInfo.googleAddress || ""
        );

        setValue("PHYSICAL_ADDRESS", brandDetailData.brandInfo.address || "");
        setValue("BUTTON_LABEL", brandDetailData.brandInfo.btnLbl || "");
        setValue("BUTTON_Link", brandDetailData.brandInfo.btnLink || "");
        setPhoneNumber(brandDetailData.brandInfo.phoneNumber || "");
        setPhoneDialCode(brandDetailData.brandInfo.phoneDialCode || "+27");
        setWatsappNo(brandDetailData.brandInfo.whatsappNumber || "");
        setDialCodeWatsapp(brandDetailData.brandInfo.whatsappDialCode || "+27");
        setAddButtonChecked(brandDetailData.brandInfo.isBtnAdded);
        setPhysicalAdd(brandDetailData.brandInfo.isPhysicalAddress);
        setPreviewMembershipImg(
          brandDetailData.brandInfo.membershipPageImg || ""
        );
        if (brandDetailData.brandInfo?.latitude) {
          setPosition({
            lat: brandDetailData.brandInfo?.latitude,
            lng: brandDetailData.brandInfo?.longitude,
          });
        } else {
          setPosition({
            lat: -3.745,
            lng: -38.523,
          });
        }

        setSourceGeometry({
          address: brandDetailData.brandInfo?.googleAddress,
          lat: brandDetailData.brandInfo?.latitude,
          lng: brandDetailData.brandInfo?.longitude,
        });
      }
    } else {
      setPhoneCountryCode("za");
      setPhoneDialCode("27");
      setCountryCodeWatsapp("za");
      setDialCodeWatsapp("27");
    }
  }, [brandDetailData]);

  //=== function for manage google map=====
  const handlePlaceSelected = (place, type) => {
    if (type == "sourceLocation") {
      setIsAnyChange(true);
      setSourceGeometry({
        address: autocomplete?.current?.getPlace()?.formatted_address,
        lat: autocomplete?.current?.getPlace()?.geometry?.location?.lat(),
        lng: autocomplete?.current?.getPlace()?.geometry?.location?.lng(),
      });
      setPosition({
        lat: autocomplete?.current?.getPlace()?.geometry?.location?.lat(),
        lng: autocomplete?.current?.getPlace()?.geometry?.location?.lng(),
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const selectedPlace = autocomplete.current?.getPlace();
      if (selectedPlace) {
        handlePlaceSelected(selectedPlace);
      }
    }
  };

  const fetchLocationName = async (lat, lng) => {
    const geocodingApiKey = placeApiKey;
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${geocodingApiKey}`;

    const response = await fetch(geocodingUrl);
    const data = await response.json();

    if (data.results && data.results.length > 0) {
      const formattedAddress = data.results[0].formatted_address;
      return formattedAddress;
    } else {
      return null; // No results found
    }
  };

  useEffect(() => {
    const fetchInitialLocationName = async () => {
      const locationName = await fetchLocationName(position.lat, position.lng);
      if (locationName) {
        setSourceGeometry({
          address: locationName,
          lat: position.lat,
          lng: position.lng,
        });
        setValue("GOOGLE_ADDRESS", locationName);
      }
    };

    fetchInitialLocationName();
  }, [position.lat, position.lng]);

  const fetchImageAsBinary = async (url) => {
    try {
      const imageFile = await fetchImageAsFile(`${url}`, "myImage.jpg");

      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  //========== function for submit brand info  form===============
  const onSubmit = async () => {
    setLoading(true)
    let requestData = new FormData();
    requestData.append("brandName", cleanData(getValues("BRAND_NAME")));
    requestData.append("details", cleanData(getValues("BRAND_DETAIL")));
    requestData.append(
      "phoneDialCode",
      phoneNumber ? removeExtraPlus(phoneDialCode) : ""
    );
    requestData.append("phoneNumber", phoneNumber);
    requestData.append(
      "whatsappDialCode",
      watsappNo ? removeExtraPlus(dialCodeWatsapp) : ""
    );
    requestData.append("whatsappNumber", watsappNo);
    requestData.append("contactEmail", cleanData(getValues("CONTACT_EMAIL")));
    requestData.append("webAddress", cleanData(getValues("WEBSITE_ADDRESS")));
    requestData.append("countryMeta", cleanData(getValues("COUNTRY")));
    requestData.append("stateMeta", cleanData(getValues("STATE")));
    requestData.append("town", cleanData(getValues("TOWN_CITY")));
    if (physicalAdd == 0 || physicalAdd == undefined || physicalAdd == null) {
      requestData.append("address", "");
    } else {
      requestData.append("address", cleanData(getValues("PHYSICAL_ADDRESS")));
    }
    if (addButtonChecked == 0) {
      requestData.append("isBtnAdded", 0);
      requestData.append("btnLbl", "");
      requestData.append("btnLink", "");
    } else {
      requestData.append("isBtnAdded", 1);
      requestData.append("btnLbl", cleanData(getValues("BUTTON_LABEL")));
      requestData.append("btnLink", cleanData(getValues("BUTTON_Link")));
    }
    requestData.append("googleAddress", cleanData(sourceGeometry.address));
    requestData.append("latitude", cleanData(sourceGeometry.lat));
    requestData.append("longitude", cleanData(sourceGeometry.lng));
    requestData.append("isPhysicalAddress", physicalAdd ? 1 : 0);
    if (brandId) {
      requestData.append("brandId", brandId);
    }
    if (isCropper) {
      const imageFile = await fetchImageAsBinary(previewMembershipImg);
      requestData.append("membershipPageImg", imageFile);
    } else {
      const imageFile = previewMembershipImg
        ? await fetchImageAsBinary(
            `https://api.trainza.co.za/proxy?url=${previewMembershipImg}`
          )
        : "";

      requestData.append("membershipPageImg", cleanData(imageFile));
    }

    if (
      (phoneNumber == undefined || phoneNumber.length == 0 || phoneNumber?.length > 4) &&
      (watsappNo == undefined ||
        watsappNo.length == 0 ||
        watsappNo?.length > 4)
    ) {
  
    await dispatch(
      saveBrandInfo({
        requestData: requestData,
        userToken: userToken,
      })
    ).then(async (responsejson) => {
      const response = responsejson.payload;
      const ResponseCode = STATUS_MSG[response.code || response.data.code];
      await setSaveChanges(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("INFO_UPDATE_SUCCESSFULLY"), "success");
        setIsAnyChange(false);
        navigate("/brands");
        setLoading(false)
      } else if (response.data.code === STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
        setSaveChanges(false);
      } else {
        Toster(t(ResponseCode), "error");
        setSaveChanges(false);
      }
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/login");
      } else {
        setSaveChanges(false);
      }
      setLoading(false)
    });
  }
  };

  useEffect(() => {
    if (isCropper == true) {
      setShowCrop(true);
    }
  }, [imageSrc]);

  //=========== function for manage text editor validations=========
  

  useEffect(() => {
    if (saveChanges) {
      onSubmit();
    }
  }, [saveModalOpen]);

  // =======function for calling logOut api=======
  const brandDelete = () => {
    SublyApi.deleteBrand(userToken, brandId).then((responsejson) => {
      const ResponseCode =
        STATUS_MSG[
          (responsejson && responsejson.code) ||
            (responsejson && responsejson.data.code)
        ];
      if (responsejson.code === STATUS_CODES.SUCCESS) {
        Toster(t("BRAND_DELETE_SUCCUSS"), "success");
        navigate("/brands");
      } else if (responsejson.code === STATUS_CODES.VALIDATION_ERROR) {
        Toster(responsejson.code, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "align", // Add align to formats
  ];

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  //==== handle text editor error=====
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "BRAND_DETAIL",
    control,
    rules: {
      required: {
        value: true,
        message: t("BRAND_EDITOR_ERROR"),
      },

      validate: (value) => {
        const strippedValue = stripHtmlTags(value);
        if (strippedValue === "" && value !== "") {
          return t("BRAND_EDITOR_ERROR");
        }
        return true;
      },
    },
  });

  // function for handle text editor
  const cleanPastedContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");

    elements.forEach((el) => {
      el.style.color = ""; 
    });

    return doc.body.innerHTML;
  };

  const handleChange = (value, delta, source, editor) => {
    const sanitizedValue = value.replace(
      /^(<p>(&nbsp;|\s)*<\/p>)*|^(&nbsp;|\s)*/,
      ""
    );
    if (source === "user") {
      const cleanContent = cleanPastedContent(sanitizedValue);
      onChange(cleanContent);
    } else {
      onChange(sanitizedValue);
    }
  };

  const stripHtmlTags = (input) => input.replace(/(<([^>]+)>)/gi, "").trim();

  //=== higlight error box===
  const detailRef = useRef(null);

  useEffect(() => {
    if (!formState.errors.BRAND_NAME && formState.errors.BRAND_DETAIL) {
      detailRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [formState.errors.BRAND_DETAIL]);

  return (
    <div className={styles.brandWrapper}>
      <div className={styles.brandBg}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.tabBorder}></div>
          {loading ? <Loader /> : ""}
          <div className={styles.subscription}>
            <h2>{userName}</h2>
          </div>
          <div className={styles.brandDetailInfo}>
            <p>
              {t("BRAND")} <span>{t("INFO")}</span>
            </p>
            <h3>{t("BRAND_DESCRIPTION_HEADING")}</h3>
          </div>

          <div className={styles.clubName}>
            <div className={styles.brandText}>
              <CustomInputField
                styleName="inputTextForBrandInfo"
                type="text"
                PlaceHolder={t("BRAND_INFO_PLACEHOLDER")}
                RegisterName={"BRAND_NAME"}
                register={register}
                formState={formState}
                clearErrors={clearErrors}
                onChange={(e) => {
                  setIsAnyChange(true);
                  setUserName(e.target.value);
                  setValue("BRAND_NAME", e.target.value);
                  if (e.target.value == " ")
                    setValue(e.target.name, e.target.value.trim());
                }}
              >
                <p>
                  {t("BRAND_CLUB_HEADING")} <span>{t("NAME_TEXT")}</span>
                </p>
              </CustomInputField>
            </div>
          </div>
          <div className={styles.aboutMember}>
            <div className={styles.editP}>
              <p>
                {t("BRAND_CLUB_HEADING")} <span>{t("BRAND_DETAILS")}</span>
              </p>
            </div>

            <div className="editorBox">
              <ReactQuill
                theme="snow"
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                placeholder="Details of your Brand or Club, membership info, how to become a member etc"
                className="brandTextEditor"
                ref={ref}
                modules={modules}
                formats={formats}
              />
              <div className="validateError">
                {error && <p className="errorMsg">{error.message}</p>}
              </div>
            </div>
          </div>

          <CustomPhoneInputField
            styleName="inputTextForBrandInfo"
            dialCode={phoneDialCode}
            phone={phoneNumber}
            setDialCode={setPhoneDialCode}
            countryCode={phoneCountryCode}
            setPhone={setPhoneNumber}
            setCountryCode={setPhoneCountryCode}
            setIsAnyChange={setIsAnyChange}
            dropdownIcon="selectCountry"
            errorText="MOBILE_NO_MUST_BE_ATLEAST_5_DIGIT"
            Label={
              <p>
                <span>{t("PHONE_NUMBER_LABEL")} - </span>
                {t("OPTIONAL_PHONE_NUMBER_TEXT")}
              </p>
            }
          ></CustomPhoneInputField>

          <CustomPhoneInputField
            styleName="inputTextForBrandInfo"
            dialCode={dialCodeWatsapp}
            phone={watsappNo}
            setIsAnyChange={setIsAnyChange}
            setDialCode={setDialCodeWatsapp}
            setPhone={setWatsappNo}
            countryCode={countryCodeWatsapp}
            setCountryCode={setCountryCodeWatsapp}
            dropdownIcon="selectCountry2"
            errorText="WHATSAPP_NO_MUST_BE_ATLEAST_5_DIGIT"
            Label={
              <p>
                <span>{t("WHATSAPP_NUMBER_LABEL")} -</span>{" "}
                {t("OPTIONAL_WHATSAPP_NUMBER_TEXT")}
              </p>
            }
          ></CustomPhoneInputField>

          <CustomInputField
            styleName="inputTextForBrandInfo"
            type="text"
            PlaceHolder={t("CONTACT_EMAIL_ADDRESS")}
            RegisterName={"CONTACT_EMAIL"}
            register={register}
            formState={formState}
            onChange={(e) => {
              setIsAnyChange(true);
              setValue(e.target.name, e.target.value.replace(/\s/g, ""));
            }}
            clearErrors={clearErrors}
          >
            <p className={styles.brandEmail}>
              {t("CONTACT_EMAIL_TEXT")}{" "}
              <span className={styles.emailSpan}>
                {t("OPTIONAL_CONTACT_EMAIL_TEXT")}
              </span>
            </p>
          </CustomInputField>

          <CustomInputField
            styleName="inputTextForBrandInfo"
            type="text"
            PlaceHolder={t("WEBSITE_ADDRESS")}
            RegisterName={"WEBSITE_ADDRESS"}
            register={register}
            formState={formState}
            onChange={(e) => {
              setIsAnyChange(true);
              setValue(e.target.name, e.target.value.replace(/\s/g, ""));
            }}
            clearErrors={clearErrors}
          >
            <p>
              <span> {t("WEBSITE_ADDRES_LABEL")} - </span>
              {t("OPTIONAL_WEBSITE_ADDRESS_TEXT")}
            </p>
          </CustomInputField>

          <div className={styles.devider}></div>
          <div className={styles.location}>
            <p>
              {t("LOCATION")} <span>({t("REQUIRED_TEXT")})</span>
            </p>
            <CustomSelect
              styleName="brandSelect"
              Label={<p>{t("COUNTRY_REQUIRED")}</p>}
              RegisterName={"COUNTRY"}
              register={register}
              formState={formState}
              onChangeHandler={handleCountryChange}
              defaultKey="South Africa"
              arrayData={countryData}
              disabled={false}
              defaultValue="vbmOpe"
            ></CustomSelect>

            <CustomSelect
              styleName="brandSelect"
              Label={<p>{t("STATE_PROVINCE_REQUIRED")}</p>}
              RegisterName={"STATE"}
              register={register}
              formState={formState}
              onChangeHandler={handleStateChange}
              defaultKey="Select State/Province"
              arrayData={stateData}
              disabled={stateData?.length === 0}
              defaultValue=""
            ></CustomSelect>

            <CustomInputField
              styleName="selectInput"
              type="text"
              PlaceHolder="Input Your City/Town"
              RegisterName="TOWN_CITY"
              register={register}
              formState={formState}
              clearErrors={clearErrors}
              onChange={(e) => {
                setIsAnyChange(true);
                if (e.target.value == " ")
                  setValue(e.target.name, e.target.value.trim());
              }}
            >
              <p className={styles.townCityLabel}>
                {" "}
                {t("TOWN_CITY_REQUIRED")}{" "}
              </p>
            </CustomInputField>
          </div>

          <div className={styles.phycicalAddress}>
            <div className="phycicalChek">
              <CustomCheckBox
                checked={physicalAdd}
                styleName={styles.addButton}
                setIsStateChange={setPhysicalAdd}
                setIsAnyChange={setIsAnyChange}
                label={
                  <p>
                    {t("PHYSICAL_ADDRESS")}
                    <span>{t("PHYCICAL_OPTIONAL")}</span>
                  </p>
                }
              ></CustomCheckBox>
            </div>

            {physicalAdd ? (
              <>
                <CustomInputField
                  styleName="inputTextForBrandInfo"
                  type="text"
                  PlaceHolder={t("LOCATION_PLACEHOLDER_TEXT")}
                  RegisterName={"PHYSICAL_ADDRESS"}
                  register={register}
                  formState={formState}
                  clearErrors={clearErrors}
                  onChange={(e) => {
                    setIsAnyChange(true);
                    if (e.target.value == " ")
                      setValue(e.target.name, e.target.value.trim());
                  }}
                >
                  <p>
                    {t("ADDRESS_NAME_LABEL")}{" "}
                    <span>{t("LOCATION_NAME_REQUIRED")}</span>
                  </p>
                </CustomInputField>
              </>
            ) : (
              ""
            )}
            <Form.Group className={styles.inputIcon}>
              <Form.Label className={styles.labelText}>
                <p>
                  {t("GOOGLE_ADDRESS_LABEL")}{" "}
                  <span>{t("INCLUDE_LINK_LOCATION")}</span>
                </p>
              </Form.Label>
              <Autocomplete
                placeApiKey={placeApiKey}
                onLoad={(auto) => {
                  autocomplete.current = auto;
                }}
                onPlaceChanged={(place) => {
                  handlePlaceSelected(place, "sourceLocation");
                }}
              >
                <input
                  className={styles.FormInput}
                  type="text"
                  placeholder={t("GOOGLE_PLACEHOLDER_TEXT")}
                  {...register("googleAddress", {
                    onChange: (e) => handleKeyPress(e),
                    required: {
                      value: false,
                      message: `${t("GOOGLE_ADDRESS_REQUIRED")}`,
                    },
                  })}
                  onKeyDown={handleKeyDown}
                />
              </Autocomplete>
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.GOOGLE_ADDRESS?.message)}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          {/* GOOGLE MAP */}
          <div className={styles.googleMap}>
            {position ? (
              <GoogleMapComp
                position={position}
                zoom={16}
                setPosition={setPosition}
                onMarkerDragEnd={onMarkerDragEnd}
              ></GoogleMapComp>
            ) : (
              ""
            )}
            <p className={styles.dragLocation}>{t("DRAG_LOCATION_PIN")} </p>
          </div>

          <div className={styles.membershipImage}>
            <h3>{t("MEMBERSHIP_PAGE_IMAGE")}</h3>
            <p>{t("OPTIONAL_FEATURES")}</p>
            <div className={styles.uploadLabel}>
              <CustomButton
                variant="footerBtnCustom"
                type="button"
                onClick={handleButtonClick}
              >
                <p>
                  <span>{t("SELECT_PHOTO")}</span>
                </p>
              </CustomButton>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".jpg, .jpeg, .gif, .png, .webp"
                onChange={handleFileChange}
              />
            </div>

            {previewMembershipImg && (
              <div className={styles.imgUploadInfo}>
                <img
                  src={previewMembershipImg}
                  alt="previewMembershipImg"
                  className={styles.previewImg}
                ></img>
                <IoCloseOutline
                  className={styles.closeIcon}
                  onClick={() => {
                    setPreviewMembershipImg(null);
                    fileInputRef.current.value = "";
                  }}
                />
              </div>
            )}
          </div>

          <div className={styles.addButton}>
            <div className="phycicalChek">
              <CustomCheckBox
                checked={addButtonChecked}
                styleName={styles.addButton}
                label={t("ADD_BUTTON_CHCKBOX")}
                setIsStateChange={setAddButtonChecked}
                setIsAnyChange={setIsAnyChange}
              ></CustomCheckBox>
              <p>{t("OPTIONAL_BUTTON")} </p>
            </div>
          </div>
          {addButtonChecked ? (
            <>
              <CustomInputField
                styleName="inputTextForBrandInfo"
                type="text"
                PlaceHolder={t("ADD_BUTTON_LABEL")}
                RegisterName={"BUTTON_LABEL"}
                register={register}
                formState={formState}
                clearErrors={clearErrors}
                onChange={(e) => {
                  setIsAnyChange(true);
                  if (e.target.value == " ")
                    setValue(e.target.name, e.target.value.trim());
                }}
              >
                <p>
                  <span style={{ fontWeight: "500" }}>
                    {t("BUTTON_LABEL_TEXT")}
                  </span>
                </p>
              </CustomInputField>

              <CustomInputField
                styleName="inputTextForBrandInfo"
                type="text"
                PlaceHolder={t("ADD_BUTTON_LINK")}
                RegisterName={"BUTTON_Link"}
                register={register}
                formState={formState}
                clearErrors={clearErrors}
                onChange={(e) => {
                  setIsAnyChange(true);
                  setValue(e.target.name, e.target.value.replace(/\s/g, ""));
                }}
              >
                <p>
                  <span style={{ fontWeight: "500" }}>{t("LINK_LABEL")}</span>
                </p>
              </CustomInputField>
            </>
          ) : (
            ""
          )}
          <div className={styles.devider}></div>
          <div className={styles.saveBtnSection}>
            <div className={styles.bottomBtnSection}>
              <div className={styles.deleteBtnSection}>
                <CustomButton
                  variant="cancelBtn"
                  type="button"
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                >
                  <RiDeleteBin6Line
                    size="20"
                    color="#000000"
                    style={{ marginRight: "5px" }}
                  />
                  {t("DELETE_BTN")}
                </CustomButton>
              </div>
              <div className={styles.saveInfo}>
                <CustomButton variant="footerBtnCustom" type="submit">
                  <p>
                    <span>{t("SAVE_BUTTON")}</span>
                  </p>
                </CustomButton>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <CropImg
        handleClose={handleCloseCrop}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setPreviewMembershipImg}
        aspectRatio={CONSTANT.ASPECT_RATIO_IN_BRAND_INFO}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
      />
      <CommonDelete
        show={deleteModal}
        handleClose={() => {
          setDeleteModal(false);
        }}
        handleDeleteGroup={brandDelete}
      />
    </div>
  );
}

export default BrandInfo;
