import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./Brands.module.css";
import Switch from "react-switch";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addSubscription, userLogoutClear } from "../../store/slices/userSlice";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import SublyApi from "../../Helpers/Api";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import { UserContext } from "../../Common/Context/UserContext";
import { cleanData } from "../../Utils/RemovePlus/RemovePlus";

function Subscription() {
  const { userToken, brandDetailData } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch("");
  const navigate = useNavigate("");
  const location = useLocation();
  const brandId = location?.state?.brand_id;
  const [checked, setChecked] = useState(false);
  const saveModalOpen = useSelector((state) => state.user.open_modal);
  const { setIsAnyChange, saveChanges, setSaveChanges } =
    useContext(UserContext);

  //===========function for form validation using useform===========
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  //======= function for manage toggle on/off=======
  const handleChange = async (checked) => {
    if (brandId) {
      const updateStatus = checked ? 1 : 0;
      setChecked(checked);
      let requestData = new FormData();
      if (brandId) {
        requestData.append("brandId", brandId);
      }
      requestData.append("activeMembership", updateStatus);
      await SublyApi.changeMemberShip(requestData, userToken, brandId).then(
        (responsejson) => {
          const ResponseCode =
            STATUS_MSG[
              responsejson && responsejson.data && responsejson.data.code
            ];
          if (responsejson && responsejson.code === STATUS_CODES.SUCCESS) {
            Toster(t("STATUS_CHANGE"), "success");
          } else if (responsejson.data.code === STATUS_CODES.VALIDATION_ERROR) {
            Toster(responsejson.data.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      );
    }
  };

  // -----------function for show data prefilled---------------
  useEffect(() => {
    if (brandId) {
      if (brandDetailData && brandDetailData.subscription) {
        setChecked(brandDetailData.subscription.activeMembership || "");
        setValue("memberLimit", brandDetailData.subscription.memberLimit || "");
      }
    }
  }, [brandId, brandDetailData]);

  const onSubmit = async (formdata) => {
    if (brandId) {
      let requestData = new FormData();
      requestData.append("brandId", brandId);
      requestData.append("memberLimit", cleanData(getValues("memberLimit")));
      await dispatch(
        addSubscription({
          requestData: requestData,
          userToken: userToken,
          brandId: brandId,
        })
      ).then((responsejson) => {
        const response = responsejson.payload;
        const ResponseCode =
          STATUS_MSG[(response && response.code) || response.data.code];
        if (response.code === STATUS_CODES.SUCCESS) {
          Toster(t("SUBSCRIPTION_ADD_SUCCESFULLY"), "success");
          navigate("/brands");
          setIsAnyChange(false);
        } else if (response.data.code === STATUS_CODES.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
          setSaveChanges(false);
        } else if (response.data.code === STATUS_CODES.INVALID_TOKEN) {
          dispatch(userLogoutClear());
          setSaveChanges(false);
        } else {
          Toster(t(ResponseCode), "error");
          setSaveChanges(false);
        }
        if (
          response &&
          response.data &&
          response.data.code == STATUS_CODES.INVALID_TOKEN
        ) {
        }
      });
    }
  };

  useEffect(() => {
    if (saveChanges) {
      onSubmit();
    }
  }, [saveModalOpen]);

  return (
    <>
      <div className={styles.tabBorder}></div>
      <div className={styles.subscription}>
        <h2>
          {brandDetailData &&
            brandDetailData.brandInfo &&
            brandDetailData.brandInfo.brandName}{" "}
        </h2>
      </div>
      <div className={styles.currentStatus}>
        <h3>{t("SUBSCRIPTION")}</h3>
      </div>
      <div className={styles.brandDetail}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.memberLimit}>
            <Form.Group className={styles.inputIcon}>
              <Form.Label>{t("MEMBER_LIMIT_LABEL")}</Form.Label>
              <Form.Control
                className={styles.FormInput}
                type="text"
                placeholder="Enter member limit"
                {...register("memberLimit", {
                  onChange: (e) => {
                    const value = e.target.value;
                    if (!/^\d*$/.test(value)) {
                      e.target.value = value.replace(/[^\d]/g, "");
                      return;
                    }
                    setValue("memberLimit", value);
                    setIsAnyChange(true);
                  },
                  required: {
                    value: true,
                    message: `${t("MEMBER_LIMIT_REQUIRED_MESSAGE")}`,
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter integers value",
                  },
                  validate: {
                    maxLimit: (value) =>
                      parseInt(value) <= 5000 ||
                      "Member limit cannot exceed 5000",
                  },
                })}
                autoFocus={true}
              />

              {errors.memberLimit && (
                <span className="errorMsg">{errors.memberLimit.message}</span>
              )}
            </Form.Group>
          </div>
          <div className={styles.devider}></div>
          <div className={styles.memeberActive}>
            <div className={styles.switchOn}>
              <h3>{t("ACTIVE_MEMBERSHIP")}</h3>
              <Switch
                onChange={handleChange}
                checked={checked}
                className="react-switch custom-switch"
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#FF4300"
                offColor="#d3d3d3"
              />
            </div>
            <p>{t("ACCESS_TURN")}</p>
          </div>

          <div className={styles.saveChangesBtn}>
            <div className={styles.Changes}>
              <CustomButton variant="footerBtnCustom" type="submit">
                <p>
                  <span>{t("SAVE_BUTTON")}</span>
                </p>
              </CustomButton>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Subscription;
