import { useTranslation } from "react-i18next";
import styles from "./Event.module.css";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";

function EventDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.eventSection}>
      <div className={styles.summary}>
        <div className={styles.personalDetail}>
          <p>{t("LIST_BY")} -</p>
          <h1>{t("DATE_ORDER")}</h1>
          <p>{t("ALPHABET_ORDER")}</p>
        </div>
      </div>
      <div className={styles.eventDetail}>
        <p>
          {t("CURRUNTLY_HAVE")} <span>{t("NO_EVENT_TEXT")}</span>
        </p>
        <h3 onClick={() => navigate("/add-events")}>{t("ADD_AN_EVENT")}</h3>
      </div>

      <div className={styles.eventBox}>
        <div className={styles.btnSection}>
          <div className={styles.addBtn}>
            <CustomButton variant="footerBtnCustom" type="button">
              <p>
                {t("ADD_EVENT")} <span>{t("EVENT_BOLD")}</span>
              </p>
            </CustomButton>
          </div>
          <div className={styles.eventText}>
            <p>
              0 <span>{t("EVENT")}</span>
            </p>
          </div>
          <div className={styles.loadMore}>
            <CustomButton variant="footerBtnCustom" type="button">
              <p>
                {t("LOAD__BTN")} <span>{t("MORE_BTN")}</span>
              </p>
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventDetail;
