import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import styles from "./SaveChangesModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { is_modal_close } from "../../store/slices/userSlice";
import { useContext } from "react";
import { UserContext } from "../../Common/Context/UserContext";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";

function SaveChangesModal({}) {
  const { t } = useTranslation("");
  const dispatch = useDispatch();
  const saveModalOpen = useSelector((state) => state.user.open_modal);
  const { setIsAnyChange, setSaveChanges } = useContext(UserContext);

  return (
    <div>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={saveModalOpen}
          onHide={() => {
            dispatch(is_modal_close());
          }}
        >
          <Modal.Header closeButton className="savemodalHead">
            <Modal.Title>
              <div className={styles.modalHead}>
                <p>
                  {t("CHANGES")} <span>{t("NOT_SAVED")}</span>
                </p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="saveModalBody">
            <div className={styles.modalBody}>
              <p>
                {t("CHANGES_WARNING_TEXT")} <span>{t("DISCARD_MODAL")}</span>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="saveModalFooter">
            <div className={styles.btnSection}>
              <CustomButton
                variant="cancelBtn"
                onClick={() => {
                  setIsAnyChange(false);
                  dispatch(is_modal_close());
                }}
              >
                {t("DISCARD_EXIT")}
              </CustomButton>

              <CustomButton
                variant="footerBtnCustom"
                onClick={() => {
                  dispatch(is_modal_close());
                  setSaveChanges(true);
                }}
              >
                <p>
                  {t("SAVE_BUTTON")} <span>{t("NOT_SAVED")} </span>
                </p>
              </CustomButton>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
export default SaveChangesModal;
