import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styles from "./CropImg.module.css";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";

function CropImg({
  handleClose,
  show,
  imageSrc,
  setSelectedImage,
  aspectRatio,
  setIsCropper,
  setImageSrc,
}) {
  const { t } = useTranslation();
  const cropperRef = useRef(null);

  // const handleGetCroppedImage = () => {
  //   if (cropperRef.current) {
  //     const cropper = cropperRef.current.cropper;
  //     const canvas = cropper.getCroppedCanvas();
  //     canvas.toBlob((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       setSelectedImage(url);
  //     });
  //     handleClose();
  //   }
  // };

 
  const handleGetCroppedImage = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    const MAX_WIDTH = 2000; 
    const MAX_HEIGHT = 2000;
    const scaleWidth = croppedCanvas.width > MAX_WIDTH ? MAX_WIDTH / croppedCanvas.width : 1;
    const scaleHeight = croppedCanvas.height > MAX_HEIGHT ? MAX_HEIGHT / croppedCanvas.height : 1;
    const scaleFactor = Math.min(scaleWidth, scaleHeight);
    const canvas = cropper.getCroppedCanvas({
      width: croppedCanvas.width * scaleFactor,
      height: croppedCanvas.height * scaleFactor,
    });
      canvas.toBlob(
        (blob) => {
          const url = URL.createObjectURL(blob);
          setSelectedImage(url);
          console.log("Cropped Image Size:", {
            width: canvas.width,
            height: canvas.height,
          });
          console.log("Cropped Image Blob Size:", blob.size, "bytes");
        },
        0.7
      );
      handleClose();
    }
  };

  return (
    <div>
      <Modal show={show} className="croperModal">
        <Modal.Header className={styles.ModalHeader} closeButton>
          <Modal.Title>
            <p>
              {t("CROP")} <span>{t("IMAGE")}</span>
            </p>
          </Modal.Title>
          <RxCross2
            size="30"
            color="grey"
            className={styles.crossIcon}
            onClick={() => {
              handleClose();
              setSelectedImage(null);
              setIsCropper(false);
              setImageSrc(null);
            }}
          />
        </Modal.Header>
        <Modal.Body className={styles.ModalBody}>
          <Cropper
            src={imageSrc}
            ref={cropperRef}
            style={{ height: "70vh", width: "100%" }}
            aspectRatio={aspectRatio}
            guides={false}
            minCropBoxWidth={200}
            minCropBoxHeight={200}
          />
        </Modal.Body>
        <Modal.Footer className={styles.ModalFooter}>
          <button onClick={handleGetCroppedImage}>
            <p>Crop</p>
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CropImg;
