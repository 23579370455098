import styles from "./WorkoutType.module.css";
import { useTranslation } from "react-i18next";
import Cross from "../../Assests/Images/cross.png";
import Edit from "../../Assests/Images/Edit.png";
import {
  CustomButton,
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import SublyApi from "../../Helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../store/slices/userSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteMember from "../Members/DeleteMember";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Loader from "../../Utils/Loader/Loader";

function WorkoutPage() {
  const { userToken } = useSelector((state) => state.user);
  const { t } = useTranslation("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [workoutList, setWorkoutList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [typeId, setTypeId] = useState();
  const [workoutName, setWorkoutName] = useState();

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  //===== function for manage total count============
  useEffect(() => {
    if (workoutList) {
      const count = workoutList.filter((item) => item.workoutTypeId).length;
      setTotalCount(count);
    }
  }, [workoutList]);

  const handleEdit = (workoutTypeId, name) => {
    setTypeId(workoutTypeId);
    setWorkoutName(name);
    navigate(`/workout-page/add-workoutTypes`, {
      state: { workoutTypeId, name },
    });
  };

  //===== function for calling workout type list api====
  const handleWorkoutTypeList = () => {
    setIsLoading(true);
    const response = SublyApi.getWorkoutType(userToken);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setWorkoutList(response?.data);
        setTypeId(response?.data.workoutTypeId);
      }
    });
  };

  useEffect(() => {
    handleWorkoutTypeList("");
  }, []);

  const handleDeleteWorkoutType = () => {
    const response = SublyApi.DeleteWorkoutType(userToken, typeId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (response?.code === STATUS_CODES.SUCCESS) {
        Toster(t("WORKOUT_TYPE_DELETE_SUCCESSFULLY"), "success");
        handleWorkoutTypeList("");
        setShowDelete(false);
      } else if (response.data.code == STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
      } else if (response?.data?.code === STATUS_CODES.INVALID_TOKEN) {
        Toster(response.data.message, "error");
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  return (
    <div className={styles.typesWrapper}>
      {isLoading && <Loader />}
      <div className={styles.typeHeading}>
        <h4>{t("WORKOUT_TYPES_TEXT")}</h4>
      </div>
      {workoutList.length === 0 ? (
        <p className={styles.noWork}>
          {t("NO_WORKOUT_FOUND")}{" "}
          <span
            className={styles.workHiglight}
            onClick={() => {
              navigate("/workout-page/add-workoutTypes");
              setTypeId("");
            }}
          >
            {t("ADD_WORKOUT_TYPES")}
          </span>
        </p>
      ) : (
        workoutList.map((item, index) => {
          return (
            <div className={styles.typeList} key={index}>
              <div className={styles.listItem}>
                <h3>{item.name}</h3>
              </div>
              <div className={styles.iconItem}>
                <div className={styles.editCross}>
                  <CustomImgButton
                    variant="primary"
                    src={Cross}
                    alt="cross"
                    onClick={() => {
                      handleShowDelete(item.workoutTypeId);
                      setTypeId(item.workoutTypeId);
                    }}
                  ></CustomImgButton>
                  <CustomImgButton
                    variant="primary"
                    src={Edit}
                    alt="edit"
                    onClick={() => handleEdit(item.workoutTypeId, item.name)}
                  ></CustomImgButton>
                </div>
              </div>
            </div>
          );
        })
      )}

      <div className={styles.addActivity}>
        <div className={styles.activityCount}>
          <div className={styles.memberAdd}>
            <p>
              {totalCount === 0 ? (
                <>
                  0 <span>{t("WORKOUT_TYPE")}</span>
                </>
              ) : totalCount === 1 ? (
                <>
                  1 <span>{t("WORKOUT_TYPE")}</span>
                </>
              ) : (
                <>
                  {totalCount} <span>{t("WORKOUT_TYPES")}</span>
                </>
              )}
            </p>
          </div>

          <div className={styles.addActivityBtn}>
            <CustomButton
              variant="footerBtnCustom"
              type="submit"
              onClick={() => {
                navigate("/workout-page/add-workoutTypes");
                setTypeId("");
              }}
            >
              <p>
                {t("ADD")} <span>{t("TYPE_")}</span>
              </p>
            </CustomButton>
          </div>
        </div>
      </div>
      <DeleteMember
        handleClose={handleCloseDelete}
        show={showDelete}
        handleDeleteMember={handleDeleteWorkoutType}
      />
    </div>
  );
}

export default WorkoutPage;
