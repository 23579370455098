import { useContext, useEffect, useRef, useState } from "react";
import styles from "../Brands/Brands.module.css";
import { useTranslation } from "react-i18next";
import { TiThMenu } from "react-icons/ti";
import { FaRegBell } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaMedal } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Toster from "../../Utils/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { saveBranding } from "../../store/slices/userSlice";
import { useForm } from "react-hook-form";
import { b64toBlob, getRandom } from "../../Utils/ImageConvert";
import mime from "mime";
import CropImg from "../../Common/Cropper/CropImg";
import { Form } from "react-bootstrap";
import {
  CustomBrandingButton,
  CustomBrandingExButton,
  CustomButton,
} from "../../ReactBootstrap/CustomTabButton";
import { CustomColorHolder } from "../../ReactBootstrap/CustomColorHolder/CustomColorHolder";
import { CONSTANT } from "../../Utils/Constant";
import ColorPicker from "../Brands/ColorPicker";
import { CustomColorInputField } from "../../ReactBootstrap/CustomInputField/CustomInputField";
import { UserContext } from "../../Common/Context/UserContext";
import { fetchImageAsFile } from "../../Utils/CommonFunction";

function Branding() {
  const { brandDetailData, userToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const navigate = useNavigate("");
  const dispatch = useDispatch("");
  const location = useLocation();
  const brandId = location?.state?.brand_id;
  const [btnTextColor, setBtnTextColor] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [inputId, setInputId] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);

  //======== manage image with cropper state==========
  const [imageURL, setImageURL] = useState(null);
  const [imageSrc, setImageSrc] = useState();
  const [isCropper, setIsCropper] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const saveModalOpen = useSelector((state) => state.user.open_modal);
  const { setIsAnyChange, saveChanges, setSaveChanges } =
    useContext(UserContext);
  const handleCloseColorPicker = () => setShowColorPicker(false);
  const handleShowColorPicker = () => setShowColorPicker(true);

  //===========function for form validation using useform===========
  const { register, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
  });

  // //=========== function for handle image selection =====
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // ====== function for select image ============
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setIsCropper(true);
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setImageURL(fileUrl);
      setImageSrc(fileUrl);
      setIsAnyChange(true);
      setSaveChanges(false);
    } else {
      setImageURL(null);
    }
  };

  // ==========function for handle cropper ==============
  const handleCloseCrop = () => setShowCrop(false);

  // -----------function for show data prefilled---------------
  useEffect(() => {
    if (brandId) {
      if (
        brandDetailData &&
        brandDetailData.branding?.btnTxtColor &&
        brandDetailData &&
        brandDetailData.branding?.btnBgColor &&
        brandDetailData &&
        brandDetailData.branding?.txtColor
      ) {
        setValue("BUTTON_TEXT_COLOR", brandDetailData.branding.btnTxtColor);
        setValue("BACKGROUND_COLOR", brandDetailData.branding.btnBgColor);
        setValue("TEXT_COLOR", brandDetailData.branding.txtColor);

        setBtnTextColor(brandDetailData.branding?.btnTxtColor);
        setBgColor(brandDetailData.branding?.btnBgColor);
        setTextColor(brandDetailData.branding?.txtColor);
        setImageURL(brandDetailData.branding.appLogo || "");
      } else {
        setValue("BUTTON_TEXT_COLOR", "#ffffff");
        setValue("BACKGROUND_COLOR", "#ff4300");
        setValue("TEXT_COLOR", "#ff4300");

        setBtnTextColor("#ffffff");
        setBgColor("#ff4300");
        setTextColor("#ff4300");
      }
    }
  }, [brandId, brandDetailData]);

  const fetchImageAsBinary = async (url) => {
    try {
      const imageFile = await fetchImageAsFile(`${url}`, "myImage.jpg");

      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  const onSubmit = async (formdata) => {
    if (brandId) {
      let requestData = new FormData();
      requestData.append("brandId", brandId);
      requestData.append("btnTxtColor", btnTextColor);
      requestData.append("btnBgColor", bgColor);
      requestData.append("txtColor", textColor);
      if (isCropper) {
        const imageFile = await fetchImageAsBinary(imageURL);
        requestData.append("appLogo", imageFile);
      } else {
        requestData.append("appLogo", imageURL);
      }
      if (imageURL) {
        await dispatch(
          saveBranding({
            requestData: requestData,
            userToken: userToken,
            brandId: brandId,
          })
        ).then(async (responsejson) => {
          await setSaveChanges(false);
          const response = responsejson.payload;
          const ResponseCode =
            STATUS_MSG[(response && response.code) || response.data.code];
          if (response.code === STATUS_CODES.SUCCESS) {
            Toster(t("BRANDING_INFO_SAVE"), "success");
            navigate("/brands");
            setIsAnyChange(false);
          } else if (response.data.code === STATUS_CODES.LOGO_REQUIRED) {
            Toster(t("LOGO_REQUIRED"), "error");
          } else if (response.data.code === STATUS_CODES.VALIDATION_ERROR) {
            Toster(response.data.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
          if (
            response &&
            response.data &&
            response.data.code == STATUS_CODES.INVALID_TOKEN
          ) {
          }
        });
      }
    }
  };

  //===== function for reset colour=====
  const handleReset = () => {
    setValue("buttonTextColor", "#ffffff");
    setValue("buttonBgColor", "#ff4300");
    setValue("txtColor", "#ff4300");
    setBtnTextColor("#ffffff");
    setBgColor("#ff4300");
    setTextColor("#ff4300");
  };

  useEffect(() => {
    if (inputId == "BTC") {
      setValue("BUTTON_TEXT_COLOR", selectedColor);
      setBtnTextColor(selectedColor);
    } else if (inputId == "BC") {
      setValue("BACKGROUND_COLOR", selectedColor);
      setBgColor(selectedColor);
    } else if (inputId == "TC") {
      setValue("TEXT_COLOR", selectedColor);
      setTextColor(selectedColor);
    }
  }, [selectedColor]);

  useEffect(() => {
    if (saveChanges && Object.keys(formState.errors).length === 0) {
      onSubmit();
    }
  }, [saveModalOpen, saveChanges]);

  useEffect(() => {
    if (isCropper == true) {
      setShowCrop(true);
    }
  }, [imageSrc]);

  return (
    <>
      <div className={styles.brandHeading}>
        <h3>{t("BRANDING")}</h3>
      </div>
      <div className={styles.devider}></div>
      <Form onSubmit={handleSubmit(() => onSubmit(brandId))}>
        <div className={styles.btnStyle}>
          <div className={styles.btnStyleMatch}>
            <p>
              {t("BUTTON_STYLE")} <span>{t("MATCH_BRAND_COLORS")}</span>
            </p>
          </div>
          <div className={styles.dispalyColor}>
            <div className={styles.clorInput}>
              <div className={styles.exbtnLabel}>
                <p>
                  {t("BUTTON_TEXT_COLOR")} <span>{t("COLOR_TEXT")}</span>
                </p>
              </div>

              <div className={styles.colourInput}>
                <CustomColorHolder
                  customColor={btnTextColor}
                  onClick={() => {
                    handleShowColorPicker();
                    setInputId(CONSTANT.BUTTON_TEXT_COLOR);
                  }}
                ></CustomColorHolder>

                <div className={styles.inputBox}>
                  <CustomColorInputField
                    RegisterName="BUTTON_TEXT_COLOR"
                    register={register}
                    formState={formState}
                    onChange={(e) => {
                      setIsAnyChange(true);
                      setBtnTextColor(e.target.value);
                      setValue(
                        "BUTTON_TEXT_COLOR",
                        e.target.value.replace(/\s/g, ""),
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                  ></CustomColorInputField>
                </div>
              </div>
              <p className={styles.bottomHighlight}>{t("TEXT_COLOR_BTN")}</p>
              <div className={styles.brandingError}>
                <span className={styles.errorMsgBranding}>
                  {t(formState.errors.BUTTON_TEXT_COLOR?.message)}
                </span>
              </div>
            </div>
            <div className={styles.exBtn}>
              <div className={styles.btnLabel}>
                <p>
                  {t("STYLES")} <span>{t("EXAMPLE_TITLE")}</span>
                </p>
              </div>
              <div className={styles.exBtnBtn}>
                <CustomBrandingExButton
                  variant="footerBtnCustomBranding"
                  style={{
                    backgroundColor: bgColor,
                    color: btnTextColor,
                  }}
                >
                  <p>
                    {t("EXAMPLE_BUTTON")} {""}{" "}
                    <span> {t("EXAMPLE_BUTTON_HIGHLIGHT")} </span>{" "}
                  </p>
                </CustomBrandingExButton>
              </div>
              <p className={styles.exPara}>{t("BUTTONS_NAVIGATIONS")}</p>
            </div>
          </div>

          <div className={styles.bottomBox}>
            <p>
              {t("BACKGROUND_COLOR")}{" "}
              <span> {t("BACKGROUND_COLOUR_TEXT")}</span>
            </p>
            <div className={styles.colourInput}>
              <CustomColorHolder
                customColor={bgColor}
                onClick={() => {
                  handleShowColorPicker();
                  setInputId(CONSTANT.BACKGROUND_COLOR);
                }}
              ></CustomColorHolder>

              <div className={styles.inputBox}>
                <CustomColorInputField
                  RegisterName="BACKGROUND_COLOR"
                  register={register}
                  formState={formState}
                  onChange={(e) => {
                    setIsAnyChange(true);
                    setBgColor(e.target.value);
                    setValue(
                      "BACKGROUND_COLOR",
                      e.target.value.replace(/\s/g, ""),
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                ></CustomColorInputField>
              </div>
            </div>

            <p className={styles.brandBgText}>
              {t("BACKGROUND_COLORS_BUTTON")}
            </p>
            <div className={styles.brandingError}>
              <span className={styles.errorMsgBranding}>
                {t(formState.errors.BACKGROUND_COLOR?.message)}
              </span>
            </div>
          </div>

          <div className={styles.resetColor}>
            <p>
              {t("COLOURED_TEXT")}
              <span>{t("COLORD_SPAN_TEXT")} </span>
            </p>
          </div>
          <div className={styles.dispalyColor}>
            <div className={styles.clorInput}>
              <div className={styles.exbtnLabel}>
                <p>
                  {t("TEXT_COLOR_LABEL")} <span>{t("COLOR_TEXT")}</span>
                </p>
              </div>

              <div className={styles.colourInput}>
                <CustomColorHolder
                  customColor={textColor}
                  onClick={() => {
                    handleShowColorPicker();
                    setInputId(CONSTANT.TEXT_COLOR);
                  }}
                ></CustomColorHolder>

                <div className={styles.inputBox}>
                  <CustomColorInputField
                    RegisterName="TEXT_COLOR"
                    register={register}
                    formState={formState}
                    onChange={(e) => {
                      setIsAnyChange(true);
                      setTextColor(e.target.value);
                      setValue(
                        "TEXT_COLOR",
                        e.target.value.replace(/\s/g, ""),
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                  ></CustomColorInputField>
                </div>
              </div>
              <p className={styles.bottomHighlight}>{t("TEXT_COLOR_WHITE")}</p>
              <div className={styles.brandingError}>
                <span className={styles.errorMsgBranding}>
                  {t(formState.errors.TEXT_COLOR?.message)}
                </span>
              </div>
            </div>
            <div className={styles.exBtn}>
              <div className={styles.btnLabel}>
                <p>
                  {t("STYLES")} <span>{t("EXAMPLE_TITLE")}</span>
                </p>
              </div>
              <div className={styles.colorBtn}>
                <CustomBrandingButton
                  variant="exBrandingBtn"
                  style={{
                    color: textColor,
                  }}
                >
                  <p>
                    {t("COLOURED_TEXT_BTN")}{" "}
                    <span>{t("COLOURED_TEXT_SPAN")}</span>
                  </p>
                </CustomBrandingButton>
              </div>
              <p className={styles.exPara}>{t("COLOURED_TEXT_WHITE_BG")}</p>
            </div>
          </div>
          <div className={styles.tabBar}>
            <p>
              {t("TABBAR_TEXT")} <span>{t("TABBAR_NAVIGATION")}</span>
            </p>
            <div className={styles.tabBox}>
              <div className={styles.menuItem}>
                <p className={styles.calendersvgs}>
                  <TiThMenu />
                </p>
                <p className={styles.calendersvgs}>
                  <FaRegBell />
                </p>

                <p
                  className={styles.calendersvg}
                  style={{ backgroundColor: bgColor }}
                >
                  {" "}
                  <MdOutlineCalendarMonth />
                </p>
                <p className={styles.calendersvgs}>
                  {" "}
                  <LuLayoutDashboard />
                </p>
                <p className={styles.calendersvgs}>
                  {" "}
                  <FaMedal />
                </p>
              </div>
            </div>
          </div>
          <div
            className={styles.resetDefault}
            onClick={() => {
              handleReset();
            }}
          >
            <p>
              {t("RESET_TEXT")} <span>{t("RESET_DEFAULT")}</span>
            </p>
          </div>

          <div className={styles.bottomText}>
            <p>
              {t("APP_LOGO_TEXT")}
              <span>{t("BRAND_YOUR_APP")}</span>
            </p>
            <h5>{t("LOGO_PARA")} </h5>
          </div>

          <div className={styles.BrandingbtnSection}>
            <div className={styles.uploadBox}>
              <CustomButton
                variant="footerBtnCustom"
                type="button"
                onClick={handleButtonClick}
              >
                <p>
                  <span>{t("SELECT_PHOTO")}</span>
                </p>
              </CustomButton>
              <div className={styles.imageBtn}>
                <input
                  type="file"
                  id="file-inputs"
                  accept=".jpg, .jpeg, .gif, .png, .webp"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />

                {imageURL && (
                  <div className={styles.imgUpload}>
                    <IoCloseOutline
                      className={styles.closeIcon}
                      onClick={() => {
                        setImageURL(null);
                        setIsAnyChange(false);
                      }}
                    />
                    <img
                      className={styles.afterUploadImg}
                      src={imageURL}
                      alt="Uploaded"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.devider}></div>
          <div className={styles.saveChangesBtn}>
            <div className={styles.Changes}>
              <CustomButton variant="footerBtnCustom" type="submit">
                <p>
                  <span>{t("SAVE_BUTTON")}</span>
                </p>
              </CustomButton>
            </div>
          </div>

          <CropImg
            handleClose={handleCloseCrop}
            show={showCrop}
            imageSrc={imageSrc}
            setSelectedImage={setImageURL}
            aspectRatio={CONSTANT.ASPECT_RATIO_IN_BRANDING}
          />

          <ColorPicker
            show={showColorPicker}
            handleClose={handleCloseColorPicker}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
        </div>
      </Form>
    </>
  );
}
export default Branding;
