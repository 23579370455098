import React, { useCallback, useEffect, useState } from "react";
import styles from "./Poll.module.css";
import PersonImg from "../../../../Assests/Images/person.png";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  modifySingularPlural,
} from "../../../../Utils/CommonFunction";
import Form from "react-bootstrap/Form";
import CrossImg from "../../../../Assests/Images/cross.png";
import { FaGripLines } from "react-icons/fa";
import { CustomButton } from "../../../../ReactBootstrap/CustomTabButton";
import { CONSTANT } from "../../../../Utils/Constant";
import { useForm } from "react-hook-form";
import { ValidationSchema } from "../../../../Utils/ValidationSchema";
import Toster from "../../../../Utils/Toster";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../../Utils/Loader/Loader";
import SublyApi from "../../../../Helpers/Api";
import { STATUS_MSG } from "../../../../Utils/StatusMsg";
import { STATUS_CODES } from "../../../../Utils/StatusCode";
import { userLogoutClear } from "../../../../store/slices/userSlice";
import CommonDelete from "../../../../Common/CommonDelete";

function Poll({ eventId, eventTitle, eventImage }) {
  const { register, handleSubmit, formState, reset, setValue } = useForm({
    mode: "onChange",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector((state) => state.user.userToken);

  const { t } = useTranslation();

  const [answers, setAnswers] = useState([
    { answerId: "", answer: "", votes: 0 },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const [isActive, setIsActive] = useState(CONSTANT.INACTIVE);
  const [showPerticipant, setShowPerticipant] = useState(CONSTANT.INACTIVE);

  const [showVoter, setShowVoter] = useState("");
  const [voterList, setVoterList] = useState([]);

  const [showDelete, setShowDelete] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState();

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  const img_opacity = answers?.length > 1 ? "1" : CONSTANT.OPACITY_RATIO;

  const addAnswer = () => {
    setAnswers([...answers, { answerId: "", answer: "", votes: 0 }]);
  };

  const updateAnswer = (index, value) => {
    const updatedAnswers = answers?.map((item, i) =>
      i === index ? { ...item, answerId: "", answer: value, votes: 0 } : item
    );
    setAnswers(updatedAnswers);
  };

  const deleteAnswer = (index) => {
    const updatedAnswers = answers?.filter((_, i) => i !== index);
    setAnswers(updatedAnswers);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = useCallback(
    (e, newIndex) => {
      e.preventDefault();
      const draggedImageIndex = Number(e.dataTransfer.getData("index"));
      if (draggedImageIndex !== newIndex) {
        const draggedBinary = answers[draggedImageIndex];
        const newBinary = [...answers];
        newBinary.splice(draggedImageIndex, 1);
        newBinary.splice(newIndex, 0, draggedBinary);
        setAnswers(newBinary);
      }
    },
    [answers]
  );

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  function OnSubmit(data) {
    const trimmedQuestion = data.QUESTION.trim(); 
    const isEmptyAnswer = answers?.some(
      (answer) => answer?.answer.trim() === ""
    );
    if (isEmptyAnswer) {
      Toster(t("FILL_ALL_THE_INPUT_FIELD"), "error");
      return;
    }
    const answerTooLong = answers?.some((item) => item.answer.length > 35);
    const transformDataForAPI = answers?.map((item) => ({
      answer: item.answer,
    }));
    const PostData = new FormData();
    PostData.append("question", trimmedQuestion);
    PostData.append("answers", JSON.stringify(transformDataForAPI));
    PostData.append(
      "activePoll",
      isActive ? CONSTANT.ACTIVE : CONSTANT.INACTIVE
    );
    PostData.append(
      "showParticipantsDetails",
      showPerticipant ? CONSTANT.ACTIVE : CONSTANT.INACTIVE
    );

    if (!isEmptyAnswer && !answerTooLong) {
      setIsLoading(true);
      SublyApi.AddPOll(isAuthenticated, PostData, eventId).then((response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (
          response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODES.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODES.SUCCESS) {
            Toster(t("POLL_ADDED_SUCCESSFULLY"), "success");
            handleGetEventDetail();
          } else if (response?.data?.code == STATUS_CODES.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  }

  const handleGetEventDetail = () => {
    setIsLoading(true);
    SublyApi.GetEventDetail(isAuthenticated, eventId).then((response) => {
      setIsLoading(false);

      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setValue("QUESTION", response?.data?.eventPoll?.question);

        if (response?.data?.eventPoll?.answers) {
          setAnswers(response?.data?.eventPoll?.answers);
          setIsActive(response?.data?.eventInfo?.isPollActive);
          setShowPerticipant(response?.data?.eventInfo?.showParticipantDetails);
        }
      }
    });
  };

  useEffect(() => {
    handleGetEventDetail();
  }, []);

  const handleGetVoterList = (idOfAnswer) => {
    setIsLoading(true);
    SublyApi.GetEventVoterList(
      isAuthenticated,
      CONSTANT.SKIP_PAGE,
      eventId,
      idOfAnswer
    ).then((response) => {
      setIsLoading(false);

      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setVoterList(response?.data?.pollVotersList);
      }
    });
  };

  const handleShowVoter = (id) => {
    if (showVoter == id) {
      setShowVoter();
    } else {
      handleGetVoterList(id);
      setShowVoter(id);
    }
  };

  const handleDeleteVoter = () => {
    const response = SublyApi.DeleteEventVoter(
      isAuthenticated,
      eventId,
      deleteIndex
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODES.SUCCESS) {
          Toster(t("VOTER_DELETED"), "success");
          setShowVoter();
          handleGetEventDetail();
        } else if (response.data.code == STATUS_CODES.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };


    //======== function for prevent white space==============
    const handleKeyDown = (e) => {
      if (e.target.value === "" && (e.key === " " || e.key === "Enter")) {
        e.preventDefault();
      }
    };
    const handleChange = (e) => {
      if (e.target.value.startsWith(" ")) {
        setValue(e.target.name, e.target.value.trim());
      }
    };

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.detailHeading}>
        <p>{capitalizeFirstLetter(eventTitle)}</p>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.subHeading}>
        <h1>{t("POLL_")}</h1>
        <p>{t("ADD_A_POLL_TO_YOUR_EVENT")}</p>
      </div>

      <div className={styles.devider}></div>

      <Form onSubmit={handleSubmit(OnSubmit)}>
        <Form.Group className={styles.inputFieldQuesQues}>
          <Form.Label>{t("QUESTION")}</Form.Label>
          <Form.Control
            type="text"
              as="textarea"
            placeholder={t("ENTER_QUESTION_HERE")}
            {...register("QUESTION", {
              ...ValidationSchema.QUESTION,
            })}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            isInvalid={!!formState.errors.QUESTION}
          />
          <Form.Control.Feedback type="invalid">
            {t(formState.errors.QUESTION?.message)}
          </Form.Control.Feedback>
        </Form.Group>

        <div className={styles.ansHeading}>
          <p>
            {modifySingularPlural("Answer", answers?.length)}

            <span>- {t("LIM_TO_35_CHAR")}</span>
          </p>
        </div>

        <div onDragOver={handleDragOver} style={{ padding: "0px 2px" }}>
          {answers?.map((answerObj, index) => {
            var isOverLimitAnswer = answerObj?.answer?.length > 35;

            return (
              <Form.Group
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDrop={(e) => handleDrop(e, index)}
                key={index}
              >
                <div key={index} className={styles.inputFieldAns}>
                  <Form.Control
                    type="text"
                    placeholder={t("ENTER_YOUR_ANSWER_HERE")}
                    value={answerObj.answer}
                    onChange={(e) => updateAnswer(index, e.target.value)}
                  />

                  <div className={styles.listBox}>
                    <FaGripLines color="#ffffff" size="20" />
                  </div>

                  <img
                    src={CrossImg}
                    alt="CrossImg"
                    className={styles.crossImg}
                    style={{
                      opacity: img_opacity,
                    }}
                    onClick={() => {
                      if (answers?.length > 1) {
                        deleteAnswer(index);
                      }
                    }}
                  />
                </div>

                <div className="validateError">
                  {isOverLimitAnswer && t("ANSWER_MAX_MAX_35_CH")}
                </div>

                <div
                  className={styles.votes}
                  onClick={() => {
                    if (answerObj.votes !== 0) {
                      handleShowVoter(answerObj.answerId);
                    }
                  }}
                >
                  <p>
                    {answerObj?.votes}{" "}
                    {answerObj?.votes > 1 ? t("VOTES") : t("VOTE")} -{" "}
                    <span>
                      {answerObj?.votes > 1
                        ? t("VIEW_VOTERS")
                        : t("VIEW_VOTER")}
                    </span>
                  </p>
                </div>
                {showVoter === answerObj.answerId ? (
                  <div className={styles.VoterListBox}>
                    {voterList?.map((voter, index) => {
                      return (
                        <div className={styles.voterBox} key={index}>
                          <div className={styles.voterDetail}>
                            <img
                              src={
                                voter?.profilePicture
                                  ? voter?.profilePicture
                                  : PersonImg
                              }
                              alt="PersonImg"
                            ></img>
                            <p>
                              {capitalizeFirstLetter(voter.firstName)}{" "}
                              <span>
                                {capitalizeFirstLetter(voter.lastName ? voter.lastName : "")}
                              </span>
                            </p>
                          </div>
                          <img
                            src={CrossImg}
                            alt="CrossImg"
                            className={styles.voterBoxCross}
                            onClick={() => {
                              handleDelete();
                              setDeleteIndex(voter.pollVoteId);
                            }}
                          ></img>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            );
          })}
        </div>

        <CustomButton
          variant="footerBtnCustom"
          type="button"
          onClick={addAnswer}
        >
          <p>
            {t("ADD")} <span>{t("ANSWER")}</span>
          </p>
        </CustomButton>

        <div className={styles.devider}></div>

        <div className={styles.activeEventSection}>
          <div className={styles.switchOn}>
            <h3>{t("ACTIVE_POLL")}</h3>
            <p>{t("TUEN_POLL_ON_OFF")}</p>
          </div>
          <Form.Check
            type="switch"
            id="custom-switch"
            className={styles.switchButton}
            checked={isActive}
            onChange={() => {
              setIsActive(!isActive);
            }}
          />
        </div>

        <div className={styles.activeEventSection}>
          <div className={styles.switchOn}>
            <h3>{t("SHOW_PARTICIPANT")}</h3>
            <p>{t("HIDE_WHO_VOTED")}</p>
          </div>
          <Form.Check
            type="switch"
            id="custom-switch"
            className={styles.switchButton}
            checked={showPerticipant}
            onChange={() => {
              setShowPerticipant(!showPerticipant);
            }}
          />
        </div>

        <div className={styles.submitSection}>
          <CustomButton variant="footerBtnCustom" type="submit">
            <p>
              {t("Save")} <span>{t("Changes")}</span>
            </p>
          </CustomButton>
        </div>
      </Form>

      <CommonDelete
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteVoter}
      />
    </div>
  );
}

export default Poll;
