import DOMPurify from "dompurify";

export const resetTimeToMidnight = (dateString) => {
  const originalDate = new Date(dateString);

  // Set the time part to midnight (00:00:00)
  const newDate = new Date(
    originalDate.getFullYear(),
    originalDate.getMonth(),
    originalDate.getDate(),
    0, // Hours
    0, // Minutes
    0 // Seconds
  );

  return newDate;
};

export const b64toBlob = (cropData, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  var byteCharacters = window.atob(cropData); //decode string
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const modifySingularPlural = (baseString, conditionString) => {
  return baseString + (conditionString > 1 ? "s" : "");
};
export const cleanData = (value) => {
  if (value === undefined || value === null) {
    return "";
  }
  return value;
};
export const correctFormat = (inputDate) => {
  const padToTwoDigits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (regex.test(inputDate)) {
    return inputDate;
  } else {
    const parts = inputDate?.split("-");
    if (parts?.length === 3) {
      const [year, month, day] = parts;

      const formattedMonth = padToTwoDigits(month);
      const formattedDay = padToTwoDigits(day);

      return `${year}-${formattedMonth}-${formattedDay}`;
    } else {
      return "";
    }
  }
};
export const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const date = new Date(dateString);
  return date?.toLocaleDateString("en-GB", options);
};
export const formatDateToTime = (dateString) => {
  const dateObj = new Date(dateString);

  const hours = dateObj.getHours().toString().padStart(2, "0");
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  const seconds = dateObj.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};
export const formatTime = (isoString) => {
  // Parse the ISO 8601 string to a Date object
  const date = new Date(isoString);

  // Extract hours and minutes
  const hours = date.getUTCHours().toString().padStart(2, "0"); // Format hours to 2 digits
  const minutes = date.getUTCMinutes().toString().padStart(2, "0"); // Format minutes to 2 digits

  // Return time in hh:mm format
  return `${hours}:${minutes}`;
};
export const getRandom = () => {
  return Math.floor(Math.random() * 9000000000) + 1000000000;
};

// Utility function to check if the content is effectively empty
export const isContentEmpty = (content) => {
  const sanitizedContent = DOMPurify.sanitize(content);
  return (
    !sanitizedContent ||
    sanitizedContent === "<p><br></p>" ||
    sanitizedContent === ""
  );
};

export const determineDataType = (data) => {
  if (typeof data === "string") {
    return "string";
  }

  if (
    data instanceof ArrayBuffer ||
    data instanceof Blob ||
    data instanceof Uint8Array
  ) {
    return "binary";
  }

  return "unknown";
};
export const fetchImageAsFile = async (imageUrl, fileName = "image.jpg") => {
  const response = await fetch(imageUrl);

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

export const isValidDate = (dateString) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/; // Check format YYYY-MM-DD
  if (!dateString.match(regex)) return false;

  const date = new Date(dateString);
  const [year, month, day] = dateString.split("-").map(Number);

  return (
    date.getFullYear() === year &&
    date.getMonth() + 1 === month &&
    date.getDate() === day
  );
};
