import styles from "./Distance.module.css";
import Cross from "../../Assests/Images/cross.png";
import Edit from "../../Assests/Images/Edit.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";

function Distance() {
  const { t } = useTranslation();
  const [selectDistance, setSelectDistance] = useState("");
  const [showMilesKm, setShowMilesKm] = useState(false);

  //======== Function to toggle visibility==========
  const toggleMilesKm = () => {
    setShowMilesKm(!showMilesKm);
  };

  // ====== function for handle distance ============
  const handleDistance = (event) => {
    setSelectDistance(event.target.value);
  };

  return (
    <div className={styles.distanceDEtailWrapper}>
      <div className={styles.distanceShow}>
        <div className={styles.disRange}>
          <p>
            {" "}
            5<span> KM </span>
          </p>
        </div>
        <div className={styles.iconItem}>
          <div className={styles.editCross}>
            <img src={Cross} alt="cross"></img>
            <img src={Edit} alt="edit"></img>
          </div>
        </div>
      </div>
      <div className={styles.distanceShow}>
        <div className={styles.disRange}>
          <p>
            {" "}
            15<span> KM </span>
          </p>
        </div>
        <div className={styles.iconItem}>
          <div className={styles.editCross}>
            <img src={Cross} alt="cross"></img>
            <img src={Edit} alt="edit"></img>
          </div>
        </div>
      </div>
      <div className={styles.distanceShow}>
        <div className={styles.disRange}>
          <p>
            {" "}
            15<span> KM </span>
          </p>
        </div>
        <div className={styles.iconItem}>
          <div className={styles.editCross}>
            <img src={Cross} alt="cross"></img>
            <img src={Edit} alt="edit"></img>
          </div>
        </div>
      </div>
      <div className={styles.distanceShow}>
        <div className={styles.disRange}>
          <p>
            {" "}
            50<span> MI </span>
          </p>
        </div>
        <div className={styles.iconItem}>
          <div className={styles.editCross}>
            <img src={Cross} alt="cross"></img>
            <img src={Edit} alt="edit"></img>
          </div>
        </div>
      </div>
      <div className={styles.distanceShow}>
        <div className={styles.disRange}>
          <p>
            {" "}
            55<span> MI </span>
          </p>
        </div>
        <div className={styles.iconItem}>
          <div className={styles.editCross}>
            <img src={Cross} alt="cross"></img>
            <img src={Edit} alt="edit"></img>
          </div>
        </div>
      </div>

      <div className={styles.distanceBtn}>
        <div className={styles.distanceCount}>
          <p>
            76 <span>{t("DISTANCE")}</span>
          </p>

          <CustomButton
            variant="footerBtnCustom"
            type="submit"
            onClick={toggleMilesKm}
          >
            <p>
              {t("ADD_MEMBER")} <span>{t("DISTANCE")}</span>
            </p>
          </CustomButton>
        </div>
      </div>

      {showMilesKm && (
        <div className={styles.addDistanceSection}>
          <div className={styles.addDistanceBox}>
            <p>
              {t("ADD_MEMBER")} <span> {t("DISTANCE")} </span>
            </p>
          </div>
          <div className={styles.miles}>
            <h5>{t("DISTANCE")}</h5>
            <div className={styles.milesInput}>
              <div className={styles.inputAreaOne}>
                <input type="text" min="0" max="23" />
              </div>
              <span> . </span>
              <div className={styles.inputArea}>
                <input type="text" min="0" max="59" />
              </div>
              {""}
              <label>MI</label>
            </div>
          </div>

          <div className={styles.milesKm}>
            <div className={styles.milesText}>
              <p>{t("MILES_KM_TEXT")}</p>
              <h5>{t("DISTANCE_MEASUREMENT")}</h5>
            </div>
            <div className={styles.kmInput}>
              <div className="checkboxSelectKm">
                <input
                  type="radio"
                  name="distance"
                  value="Kilometres (KM)"
                  checked={selectDistance === "Kilometres (KM)"}
                  onChange={handleDistance}
                />
                <label>{t("DISTANCE_CHECK_KM_LABEL")}</label>
              </div>
              <div className="checkboxSelectKm">
                <input
                  type="radio"
                  name="distance"
                  value="Miles (MI)"
                  checked={selectDistance === "Miles (MI)"}
                  onChange={handleDistance}
                />

                <label>{t("DISTANCE_CHECK_MI_LABEL")}</label>
              </div>
            </div>
          </div>
          <div className={styles.addBottomBtn}>
            <div className={styles.btnSection}>
              <CustomButton variant="cancelBtn" type="button">
                {t("CANCEL")}
              </CustomButton>

              <CustomButton variant="footerBtnCustom" type="submit">
                <p>
                  {t("SAVE_BUTTON")} <span>{t("DISTANCE")}</span>
                </p>
              </CustomButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Distance;
