import React, { useEffect, useState } from "react";
import styles from "./Members.module.css";
import PlusIcon from "../../Assests/Images/PlusIcon.png";
import Members from "./Members";
import Invites from "./Invites";
import AddDetail from "./AddMember";
import { useTranslation } from "react-i18next";
import { MEMBERS } from "./MockData";
import { Form } from "react-bootstrap";
import CustomTabButton, {
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import { useLocation, useNavigate } from "react-router-dom";

function MembersPage() {
  const { t } = useTranslation();
  const [showtab, setShowtab] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const location = useLocation();
  const isAdded = location?.state?.isAdded;

  useEffect(() => {
    if (isAdded) {
      setShowtab(3);
    }
  }, [isAdded]);


    // Clear search input when switching to the "Members" tab
    useEffect(() => {
      if (showtab === 1) {
        setSearchKeyword("");
      }
    }, [showtab]);
 

  // ---------function for search members---------
  const filterMembers = MEMBERS.filter(
    (item) =>
      item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.age.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.date.toLowerCase().includes(searchKeyword.toLowerCase())
  );
  return (
    <div className={styles.memberSection}>
      <div className={styles.topHeadM}>
        <h2>
          {showtab === 1
            ? t("MEMBERS")
            : showtab === 2
            ? t("MEMBERS > INVITES")
            : t("MEMBERS > ADD NEW")}
        </h2>
      </div>
      <div className={styles.devider}></div>
      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => setShowtab(1)}
            variant={showtab === 1 ? "primary" : "secondary"}
          >
            {t("MEMBERS_TITLE")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setShowtab(2)}
            variant={showtab === 2 ? "primary" : "secondary"}
          >
            {t("INVITES_TAB")}
          </CustomTabButton>

          <CustomImgButton
            variant="primary"
            src={PlusIcon}
            alt="PlusIcon"
            onClick={() => setShowtab(3)}
          ></CustomImgButton>
        </div>

        {showtab === 1 && (
          <Form.Group className={styles.formGroup}>
            <Form.Control
              className={styles.searchControl}
              type="search"
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </Form.Group>
        )}
      </div>
      <div className={styles.devider}></div>

      {showtab === 1 ? (
        <Members
          memberList={filterMembers}
          searchKeyword={searchKeyword}
          setShowtab={setShowtab}
          setSearchKeyword={setSearchKeyword}
        />
      ) : showtab === 2 ? (
        <Invites />
      ) : (
        <AddDetail setShowtab={setShowtab} />
      )}
    </div>
  );
}

export default MembersPage;
