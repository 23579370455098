import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import MapPin from "../../Assests/Images/MapPin.svg";

export const GoogleMapComp = ({ position, zoom, setPosition, isDisabled }) => {
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState(position);

  useEffect(() => {
    setMapCenter(position);
  }, [position]);

  const updateMarkerPosition = () => {
    if (map) {
      const center = map.getCenter().toJSON();
      setPosition(center);
      setMapCenter(center);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={MapPin}
        alt="MapPin"
        style={{ position: "absolute", zIndex: "999", top: "33px" }}
      ></img>
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "140px",
          borderRadius: "4px",
          marginTop: "10px",
          border: "1px solid #c1c1c1",
          borderRadius: "4px",
        }}
        center={mapCenter}
        zoom={zoom}
        draggable={true}
        onDragEnd={() => {
          if (!isDisabled) {
            updateMarkerPosition();
          }
        }}
        onLoad={(map) => setMap(map)}
        options={{
          fullscreenControl: false,
        }}
      >
        {map && window.google && window.google.maps && (
          <Marker
            position={mapCenter}
            draggable={false}
            icon={{
              url: "",
              scaledSize: new window.google.maps.Size(0, 50),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 50),
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};
