import { Form } from "react-bootstrap";
import styles from "./CustomSelect.module.css";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import { useTranslation } from "react-i18next";
import { RiArrowDropDownLine } from "react-icons/ri";
import Select from "react-select";
export const CustomSelect = ({
  Label,
  RegisterName,
  register,
  formState,
  onChangeHandler,
  defaultKey,
  arrayData,
  disabled,
  styleName,
  defaultValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{Label}</Form.Label>
      <Form.Select
        aria-label="Default select example"
        {...register(RegisterName, {
          ...ValidationSchema[RegisterName],
          onChange: onChangeHandler,
        })}
        isInvalid={!!formState.errors[RegisterName]}
        disabled={disabled}
      >
        <option value={defaultValue} defaultValue>
          {defaultKey}
        </option>

        {arrayData &&
          arrayData?.map((item, index) => (
            <option value={item.id} key={index}>
              {item.name}
            </option>
          ))}
      </Form.Select>

      <Form.Control.Feedback type="invalid">
        {t(formState.errors.COUNTRY?.message)}
      </Form.Control.Feedback>
      <RiArrowDropDownLine color="#424242" />
    </Form.Group>
  );
};

export const CustomBrandSelect = ({
  Label,
  RegisterName,
  register,
  formState,
  onChangeHandler,
  arrayData,
  disabled,
  styleName,
  value,
}) => {
  const { t } = useTranslation();

  const handleChange = (selectedOption) => {
    onChangeHandler(selectedOption);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: formState.errors[RegisterName]
        ? "red"
        : provided.borderColor,
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: "normal",
      overflowWrap: "break-word",
    }),
    menu: (provided) => ({
      ...provided,
      whiteSpace: "normal",
      overflowX: "hidden",
    }),
  };

  const options = arrayData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{Label}</Form.Label>
      <Select
        className="reactSelect"
        options={options}
        onChange={handleChange}
        isDisabled={disabled}
        styles={customStyles}
        value={value}
        placeholder="Select Brand"
      />
      {formState.errors[RegisterName] && (
        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
