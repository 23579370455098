import styles from "./Distance.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import PlusIcon from "../../Assests/Images/PlusIcon.png";
import Distance from "./Distance";
import PbDistance from "./PbDistance";
import CustomTabButton, {
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";

function DistancePage() {
  const { t } = useTranslation("");
  const [distanceTabShow, setDistanceTabShow] = useState(1);

  return (
    <div className={styles.distanceWrapper}>
      <p className={styles.heading}>
        {distanceTabShow === 1
          ? t("DISTANCE")
          : distanceTabShow === 2
          ? t("DISTANCE > PB DISTANCE")
          : t("DISTANCE > ADD NEW")}
      </p>
      <div className={styles.devider}></div>

      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => setDistanceTabShow(1)}
            variant={distanceTabShow === 1 ? "primary" : "secondary"}
          >
            {t("DISTANCE")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setDistanceTabShow(2)}
            variant={distanceTabShow === 2 ? "primary" : "secondary"}
          >
            {t("PB_DISTANCE")}
          </CustomTabButton>

          <div className={styles.plusIcon}>
            <CustomImgButton
              variant="primary"
              // onClick={() => setDistanceTabShow(3)}
              src={PlusIcon}
              alt="PlusIcon"
            ></CustomImgButton>
          </div>
        </div>

        {distanceTabShow === 1 ? (
          <Distance />
        ) : distanceTabShow === 2 ? (
          <PbDistance />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default DistancePage;
