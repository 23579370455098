import { useState } from "react";
import CustomTabButton from "../../ReactBootstrap/CustomTabButton";
import styles from "../Members/Members.module.css";
import { useTranslation } from "react-i18next";
import MemberDetail from "./MembersDetail";
import LogBook from "./LogBook";
import ScrollToTop from "../../Helpers/ScrollToTop";
import { useLocation } from "react-router-dom";

function MemberDetailTab() {
  const { t } = useTranslation();
  const location = useLocation();
  const [detailTabShow, setDetailTabShow] = useState(1);
  const member_id = location.state.member_id;
  const [userName, setUserName] = useState("");
  const [profileImg, setProfileImg] = useState("");

  return (
    <div className={styles.memberSection}>
      <div className={styles.topHead}>
        <p className={styles.heading}>
          {detailTabShow === 1
            ? t("MEMBERS > DETAILS")
            : detailTabShow === 2
            ? t("MEMBERS > LOGBOOK")
            : t("MEMBERS > PASSWORD")}
        </p>
        <div className={styles.devider}></div>

        <div className={styles.option}>
          <div className={styles.buttonSection}>
            <CustomTabButton
              onClick={() => setDetailTabShow(1)}
              variant={detailTabShow === 1 ? "primary" : "secondary"}
            >
              {t("DETAILS_TEXT")}
            </CustomTabButton>

            <CustomTabButton
              onClick={() => setDetailTabShow(2)}
              variant={detailTabShow === 2 ? "primary" : "secondary"}
            >
              {t("LOGBOOK_TEXT")}
            </CustomTabButton>

            {/* <CustomTabButton
              onClick={() => setDetailTabShow(3)}
              variant={detailTabShow === 3 ? "primary" : "secondary"}
            >
              {t("PASSWORD_TEXT")}
            </CustomTabButton> */}
          </div>
        </div>
        <div className={styles.devider}></div>

        {detailTabShow === 1 ? (
          <>
            <ScrollToTop />
            <MemberDetail
              setUserName={setUserName}
              setProfileImg={setProfileImg}
            />
          </>
        ) : detailTabShow === 2 ? (
          <LogBook
            userName={userName}
            profileImg={profileImg}
            member_id={member_id}
            setDetailTabShow={setDetailTabShow}
            detailTabShow={detailTabShow}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default MemberDetailTab;
