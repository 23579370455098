import Modal from "react-bootstrap/Modal";
import styles from "../Members/DeleteMember.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";

function DeleteMember({ show, handleClose, handleDeleteMember ,isDeleting }) {
  const { t } = useTranslation();
  
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ background: "#F5F4F4" }}>
          <Modal.Title>
            <div className={styles.modalHead}>
              <p>
                {t("CONFIRM")} <span>{t("DELETE_BTN")}</span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            <p>
              {t("YOU_ARE_SURE")} <span>{t("THIS_CANNOT_BE_UNDONE")}</span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "#F5F4F4" }}>
          <div className={styles.btnSection}>
            <CustomButton
              variant={"cancelBtn"}
              type="button"
              onClick={handleClose}
              disabled={isDeleting}
            >
              {t("CANCEL")}
            </CustomButton>

            <CustomButton
              variant="footerBtnCustom"
              type="button"
              onClick={handleDeleteMember}
              disabled={isDeleting}
            >
              <p>
                {t("CONFIRM")} <span>{t("DELETE_BTN")}</span>
              </p>
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteMember;
