import { Col, Row } from "react-bootstrap";
import styles from "./DashBoard.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../store/slices/userSlice";
import Toster from "../../Utils/Toster";
import SublyApi from "../../Helpers/Api";
import Loader from "../../Utils/Loader/Loader";

function DashBoards() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  const [dataCount, setDataCount] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleGetEvent = () => {
    setIsLoading(true);
    const response = SublyApi.GetDashboardData(isAuthenticated);

    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setDataCount(response?.data);
      }
    });
  };

  useEffect(() => {
    handleGetEvent();
  }, []);

  return (
    <div className={styles.dashBordsWrapper}>
      {isLoading && <Loader />}
      <div className={styles.topHead}>
        <h2>{t("WELCOME_TITLE")}</h2>
      </div>
      <div className={styles.devider}></div>
      <Row>
        <Col lg={4} md={4} sm={4} xs={6}>
          <div
            className={styles.dashBox}
            onClick={() => {
              navigate("/members");
            }}
          >
            <p>{t("MEMBERS")}</p>
            <h1>{dataCount?.memberCount ? dataCount?.memberCount : 0}</h1>
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={6}>
          <div
            className={styles.dashBox}
            onClick={() => {
              navigate("/brands");
            }}
          >
            <p>{t("BRANDS")}</p>
            <h1>{dataCount?.brandCount ? dataCount?.brandCount : 0}</h1>
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={6}>
          <div className={styles.dashBox}>
            <p>{t("WORKOUTS")}</p>
            <h1>{dataCount?.workoutCount ? dataCount?.workoutCount : 0}</h1>
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={6}>
          <div className={styles.dashBox}>
            <p>{t("NEWS")}</p>
            <h1>{dataCount?.newsCount ? dataCount?.newsCount : 0}</h1>
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={6}>
          <div
            className={styles.dashBox}
            onClick={() => {
              navigate("/events");
            }}
          >
            <p>{t("EVENTS")}</p>
            <h1>{dataCount?.eventCount ? dataCount?.eventCount : 0}</h1>
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={6}>
          <div className={styles.dashBox}>
            <p>{t("RESULTS")}</p>
            <h1>{dataCount?.resultCount ? dataCount?.resultCount : 0}</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={4} xs={12}>
          <div className={styles.dashBtn}>
            <CustomButton
              variant="footerBtnCustom"
              type="button"
              onClick={() => {
                navigate("/brands/add-brands");
              }}
            >
              <p>
                {t("ADD_BRAND")}
                <span>{t("BRAND_HIGHLIGHT_TEXT")}</span>
              </p>
            </CustomButton>
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12}>
          <div className={styles.dashBtn}>
            <CustomButton
              variant="footerBtnCustom"
              type="button"
              onClick={() => {
                navigate("/members", { state: { isAdded: true } });
              }}
            >
              <p>
                {t("ADD_MEMBER")} <span>{t("MEMBER_BTN")}</span>
              </p>
            </CustomButton>
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12}>
          <div className={styles.dashBtn}>
            <CustomButton
              variant="footerBtnCustom"
              type="button"
              onClick={() => {
                navigate("/events", { state: { isAdded: true } });
              }}
            >
              <p>
                {t("ADD_EVENT")} <span>{t("EVENT_BOLD")}</span>
              </p>
            </CustomButton>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default DashBoards;
