export const MEMBERS = [
  {
    name: "John Aardvark",
    age: "23",
    date: "11 November 2025",
  },
  {
    name: "adams ale",
    age: "23",
    date: "01 November 2025",
  },
  {
    name: "pablo escobar",
    age: "43",
    date: "23 November 2025",
  },
  {
    name: "bishnoi lawrence",
    age: "21",
    date: "18 November 2025",
  },
  {
    name: "dikky lance",
    age: "15",
    date: "26 November 2025",
  },
  {
    name: "richard harmster",
    age: "18",
    date: "14 November 2025",
  },
  {
    name: "calvin",
    age: "27",
    date: "12 November 2025",
  },
  {
    name: "denny desuza",
    age: "22",
    date: "12 November 2025",
  },
  {
    name: "michael jackson",
    age: "21",
    date: "12 November 2025",
  },
  {
    name: "prince chawl",
    age: "65",
    date: "12 November 2025",
  },
  {
    name: "hero janny",
    age: "23",
    date: "15 November 2025",
  },
  {
    name: "avinash panday",
    age: "32",
    date: "28 November 2025",
  },
  {
    name: "vikky dans",
    age: "33",
    date: "30 November 2025",
  },
  {
    name: "queen elezabeth",
    age: "34",
    date: "30 November 2025",
  },
  {
    name: "John Aardvark",
    age: "54",
    date: "26 November 2025",
  },
];

export const MONTH_OF_YEAR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const MONTH_CODE = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

export const MONTH_NO_CODE = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

export const DATE_TYPE = [
  {
    id: 1,
    datetype: "Single Date",
  },
  {
    id: 2,
    datetype: "Date Range",
  },
  {
    id: 3,
    datetype: "Repeating Event",
  },
];
export const MAP_CENTER = {
  lat: -30.559482,
  lng: 22.937506,
};
export const REPEATING_DAYS = [
  {
    id: 1,
    day: "Sun",
  },
  {
    id: 2,
    day: "Mon",
  },
  {
    id: 3,
    day: "Tues",
  },
  {
    id: 4,
    day: "Wed",
  },
  {
    id: 5,
    day: "Thurs",
  },
  {
    id: 6,
    day: "Fri",
  },
  {
    id: 7,
    day: "Sat",
  },
];
// sjasjksbs
