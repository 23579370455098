import React, { useEffect, useState } from "react";
import styles from "./Members.module.css";
import Person from "../../Assests/Images/person.png";
import Cross from "../../Assests/Images/cross.png";
import Edit from "../../Assests/Images/Edit.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CustomButton,
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import {userLogoutClear } from "../../store/slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../Utils/RemovePlus/RemovePlus";
import DeleteMember from "./DeleteMember";
import SublyApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import { CONSTANT } from "../../Utils/Constant";
import Loader from "../../Utils/Loader/Loader";

function Members({ searchKeyword, setShowtab ,setSearchKeyword }) {
  const { userToken} = useSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [memberId, setMemberId] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [memberListData, setMemberListData] = useState([]);
  const [sorting, setSorting] = useState(CONSTANT.NAME);
  const [totalCount, setTotalCount] = useState();
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const [isDeleting, setIsDeleting] = useState(false); 

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
  }

  //===== function for higlighted search keyword=====
  function HighlightKeyInParagraph({ keyWord, paragraph }) {
    const renderHighlightedParagraph = () => {
      const escapedKeyWord = escapeRegExp(keyWord);
      const parts = paragraph.split(new RegExp(`(${escapedKeyWord})`, "gi"));
      return parts.map((part, index) =>
        part.toLowerCase() === keyWord.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "600",
              fontSize: "15px",
              letterSpacing: "0.3px",
              background: "#CEF56E",
              margin: "0px",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return <p style={{ margin: "0px" }}>{renderHighlightedParagraph()}</p>;
  }

  //========== function for calling get brand list api==========
  function getMemberList(page, search, sorting) {
    setIsLoading(true);
    const response = SublyApi.memberList(userToken, page, search, sorting);
    response.then((response) => {
      setIsLoading(false);
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        if (page == 1) {
          setMemberListData(response?.data?.memberList);
        } else {
          setMemberListData((prevList) => [
            ...prevList,
            ...response?.data?.memberList,
          ]);
        }
        setIsLoadMore(response?.data?.loadMore);
        setTotalCount(response?.data?.count);
        setPageNo(page + 1);
      }
    });
  }

  
  useEffect(() => {
    if (!searchKeyword) {
      getMemberList(1, "", sorting);
    }
  }, [searchKeyword, sorting]);

  useEffect(() => {
    if (searchKeyword) {
      getMemberList(1, searchKeyword, sorting);
    }
  }, [searchKeyword, sorting]);


  //===== function for calling delete member api=====
  const handleDeleteMember = () => {
    setIsDeleting(true); 
    const response = SublyApi.DeleteMember(userToken, memberId);
    response.then((response) => {
      setIsLoading(false);
      setIsDeleting(false); 
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (response?.code === STATUS_CODES.SUCCESS) {
        Toster(t("MEMBER_DELETED"), "success");
        getMemberList(1, "", sorting);
        setSearchKeyword("")
      } else if (response.data.code == STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
      } else if (response?.data?.code === STATUS_CODES.INVALID_TOKEN) {
        Toster(response.data.message, "error");
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  return (
    <div>
      {isLoading ? <Loader /> : ""}
      <div className={styles.summary}>
        <div className={styles.personalDetail}>
          <p className={styles.personalDetailOff}>{t("LIST_BY")}</p>
          <p
            className={
              sorting === 1 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.NAME);
            }}
          >
            - {t("NAME")}
          </p>
          <p
            className={
              sorting === 2 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.SURNAME);
            }}
          >
            - {t("SURNAME")}
          </p>
          <p
            className={
              sorting === 3 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.AGE);
            }}
          >
            - {t("AGE")}
          </p>
          <p
            className={
              sorting === 4 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.EXPIRY);
            }}
          >
            - {t("EXPIRY_DATE")}
          </p>
        </div>
      </div>
      <div className={styles.devider}></div>

      {memberListData?.map((info, index) => {
        return (
          <div className={styles.detail} key={index}>
            <div className={styles.info} key={index}>
              <img
                src={info?.profilePicture ? info.profilePicture : Person}
                alt="Person"
                onClick={() =>
                  navigate("/members/member-details", {
                    state: { member_id: info.id },
                  })
                }
              ></img>
              <div className={styles.infoOfMember}>
                <p className={styles.name}>
                  {" "}
                  <HighlightKeyInParagraph
                    keyWord={searchKeyword}
                    paragraph={`${capitalizeFirstLetter(
                      info?.firstName
                    )} ${capitalizeFirstLetter(
                      info?.lastName ? info?.lastName : ""
                    )}`}
                  />
                </p>
                <p className={styles.gender}>
                  {info.gender === 1
                    ? t("MALE")
                    : info.gender === 2
                    ? t("FEMALE")
                    : t("N/A")}{" "}
                  - {info.age ?? 0}
                </p>
              </div>
            </div>
            <div className={styles.editCross}>
              <CustomImgButton
                variant="primary"
                onClick={() => {
                  handleShowDelete();
                  setMemberId(info.id);
                }}
                src={Cross}
                alt="cross"
              ></CustomImgButton>
              <CustomImgButton
                variant="primary"
                src={Edit}
                alt="edit"
                onClick={() =>
                  navigate("/members/member-details-edit", {
                    state: { member_id: info.id },
                  })
                }
              ></CustomImgButton>
            </div>
          </div>
        );
      })}

      {searchKeyword && memberListData?.length === 0 ? (
        <React.Fragment>
          <div className={styles.noRecordFound}>
            <p>
              {t("NO_RESULT_TEXT")} - <span>{t("FOR_SEARCH_QUERY")}</span>
            </p>
          </div>
          <div className={styles.devider}></div>
        </React.Fragment>
      ) : (
        ""
      )}

      <div className={styles.inviteormore}>
        <div className={styles.membersCount}>
          <CustomButton
            variant="footerBtnCustom"
            type="button"
            onClick={() => {
              setShowtab(2);
            }}
          >
            <p>
              {t("INVITE_MEMBER")}
              <span>{t("MEMBER_BTN")}</span>
            </p>
          </CustomButton>

          <p>
            {totalCount} <span>{totalCount <= 1 ? "Member" : "Members"}</span>
          </p>
          <CustomButton
            variant="footerBtnCustom"
            type="button"
            onClick={() => {
              if (isLoadMore) {
                getMemberList(pageNo, searchKeyword, sorting);
              }
            }}
            disabled={!isLoadMore}
            style={{
              cursor: isLoadMore ? "pointer" : "not-allowed",
              opacity: isLoadMore ? 1 : 0.5,
              borderColor: isLoadMore ? undefined : "#ff4300",
            }}
          >
            <p>
              {t("LOAD__BTN")} <span>{t("MORE_BTN")}</span>
            </p>
          </CustomButton>
        </div>
      </div>
      <DeleteMember
        handleClose={handleCloseDelete}
        show={showDelete}
        handleDeleteMember={handleDeleteMember}
        isDeleting={isDeleting} 
      />
    </div>
  );
}

export default Members;
