export const TRANSLATIONS_EN = {
  //---------- Login Form Text---------------
  SUPER_HEADING_TEXT: "SUPER",
  ADMIN_HEADING_TEXT: "ADMIN",
  EMAIL: "Email Address",
  PASSWORD: "Your Password",
  LOGIN: "LOGIN",
  EMAIL_REQUIRED: "Please enter your email address",
  INVALID_EMAIL: "Please enter a valid email address",
  PASSWORD_REQUIRED_MESSAGE: "Please enter your password",
  PASS_MAXLENGTH: "Please enter password, with a maximum of 12 characters",
  PASS_MINLENGTH: "Please enter password, with a minimum of 6 characters",
  PASSWORD_MUST_CONTAIN:
    "Please enter password must contain one uppercase, lowercase, number, and a special characters.",
  REMEMBER_ME_TEXT: "  Remember Me",
  SUPER_ADMIN_LOGIN_TEXT: "Superadmin login..",

  //----------SideBar Text------------
  SIDEBAR_SUPERADMIN: "SUPERADMIN",

  //---------- Dashboard Text---------------
  WELCOME_TITLE: "Welcome",
  WORKOUTS: "WorkOuts",
  NEWS: "News",
  EVENTS: "Events",
  RESULTS: "RESULTS",
  ADD_MEMBER: "Add ",
  MEMBER_BTN: "Member",
  ADD_EVENT: "Add ",
  EVENT_BOLD: "Event",

  //---------- Brands Text---------------
  BRANDS: "BRANDS",
  SEARCH_PLACEHOLDER: "Search...",
  BRAND: "BRAND",
  BRAND_TAB: "Brand",
  BRANDS_TAB: "Brands",
  INFO: "INFO",
  BRANDING: "BRANDING",
  ADD_BRAND: "Add ",
  BRAND_HIGHLIGHT_TEXT: "Brand",
  LOAD__BTN: "Load ",
  MORE_BTN: "More",
  EVENT: "Events",
  BRAND_INFO: "Brand Info",

  SUBSCRIPTION: "Subscription",
  EXAMPLE_BUTTON: "Example ",
  EXAMPLE_BUTTON_HIGHLIGHT: "Button",
  SELECT_IMAGE: "Select Image",
  SELECT: "Select",
  IMAGE: "Image",
  DELETE: "Delete Account",
  MEMBER_LIMIT: "Member Limit",
  CONTACT_EMAIL_ADDRESS: "Contact Email Address",
  WEBSITE_ADDRESS: "http://",
  BRAND_DESCRIPTION_HEADING: `This information will be displayed in the app on your
  brand/club page for app users/member to contact you.`,
  BRAND_CLUB_HEADING: "Brand / Club ",
  NAME_TEXT: "Name",
  LOGBOOK_UPDATED: "Logbook updated successfully",
  ALREADY_IN_USE:
    "This workout type already used in user workout list due to can't delete it",
  LOGBOOK_ADDED: "Logbook added successfully",
  BRAND_INFO_PLACEHOLDER: "Enter the name of your Brand / Club",
  BRAND_NAME_REQUIRED_MESSAGE: "Please enter your brand name",
  BRAND_DETAILS: "Details",
  PHONE_NUMBER_LABEL: " Phone Number",
  MOBILE_NO_MUST_BE_ATLEAST_5_DIGIT: "Mobile no must be atleast 5 digit",
  OPTIONAL_PHONE_NUMBER_TEXT: "Optional Phone Number Button",
  WHATSAPP_NUMBER_LABEL: " WhatsApp Number",
  WHATSAPP_NO_MUST_BE_ATLEAST_5_DIGIT: "Whatsapp no must be atleast 5 digit",
  OPTIONAL_WHATSAPP_NUMBER_TEXT: "Optional whatsApp Contact Button",
  CONTACT_EMAIL_TEXT: "Contact Email -",
  OPTIONAL_CONTACT_EMAIL_TEXT: "Optional Contact Email Adress",
  WEBSITE_ADDRES_LABEL: " Website Address",
  OPTIONAL_WEBSITE_ADDRESS_TEXT: "Optional Website address with button",
  WEBSITE_ADDRESS_REQUIRED: "Please enter your address",
  LOCATION: "LOCATION",
  REQUIRED_TEXT: "Required",
  COUNTRY_REQUIRED: "Country (required)",
  SELECT_COUNTRY_PLACEHOLDER: "Select Country",
  COUNTRY_REQUIRED_TEXT: "Please select country",
  STATE_PROVINCE_REQUIRED: "State/Province (required)",
  STATE_PLACEHOLDER_TEXT: "Select State/Province",
  TOWN_CITY_REQUIRED: "Town/City (required)",
  TOWN_CITY: "Town/City",
  TOWN_PLACEHOLDER_TEXT: "Input Your City/Town",
  CITY_TEXT: "Enter your city",
  ENTER_TOWN_CITY: "Please enter town/city name",
  TOWN_CITY_MAX_255_CH: "Town/city name should not be more than 60 characters",
  PHYSICAL_ADDRESS: "PHYSICAL ADDRESS ",
  PHYCICAL_OPTIONAL: "(Optional)",
  ADDRESS_NAME_LABEL: "Address Name -",
  LOCATION_NAME_REQUIRED: "Name of location (required)",
  LOCATION_PLACEHOLDER_TEXT: "Enter the location name",
  LOCATION_REQUIRED: "Please enter your location",
  GOOGLE_ADDRESS_LABEL: "Google Address -",
  INCLUDE_LINK_LOCATION: "Include link to location (optional)",
  GOOGLE_PLACEHOLDER_TEXT: "Type in address or leave blank",
  GOOGLE_ADDRESS_REQUIRED: "Enter your google address",
  BUTTON_STYLE: "Button Styles -",
  MATCH_BRAND_COLORS: "Match Your Brand Colours",
  BUTTON_TEXT_COLOR: "Button Text",
  TEXT_COLOR_LABEL: "Text",
  COLOR_TEXT: "Colour",
  TEXT_COLOR_BTN: "Text colour on buttons",
  TEXT_COLOR_WHITE: "Text colour on white",
  HIGHLIGHTED_COLOR: "Highlighted Colour",
  BACKGROUND_COLOR: "Background ",
  BACKGROUND_COLOUR_TEXT: "colour",
  BACKGROUND_COLORS_BUTTON: "Background colour on buttons",
  COLOURED_TEXT: "Coloured Text - ",
  COLORD_SPAN_TEXT: "Used for dates and selected titles",
  MEMBER_LIMIT_LABEL: "Member Limit",
  ACTIVE_MEMBERSHIP: "Active Membership",
  ACCESS_TURN: "Turn access on/off as required",
  STYLES: "Style",
  EXAMPLE_TITLE: "Examples",
  BUTTONS_NAVIGATIONS: "Button and Navigations",
  COLOURED_TEXT_BTN: "COLOURED ",
  COLOURED_TEXT_SPAN: "TEXT",
  COLOURED_TEXT_WHITE_BG: "Colour Text on White background",
  TABBAR_TEXT: "Tabbar",
  TABBAR_NAVIGATION: "Navigation",
  RESET_TEXT: "Reset",
  RESET_DEFAULT: "Colours to Defaults",
  APP_LOGO_TEXT: "App Logo - ",
  BRAND_YOUR_APP: "Brand Your App",
  LOGO_PARA: `The logo that displays to users within the app. Upload a
  transparent PNG..`,
  DRAG_LOCATION_PIN: "Drag pin to correct location if required.",
  MEMBERSHIP_PAGE_IMAGE: "Membership Page Image (Optional)",
  OPTIONAL_FEATURES: "Add optional feature image for this page",
  ADD_BUTTON_CHCKBOX: " Add Button",
  OPTIONAL_BUTTON: "Add optional button to link out of app to external link.",
  ADD_BUTTON_LABEL: "Add button Label (eg Regsiter Here)",
  ADD_BUTTON_LINK: "Add button link (link to web page)",
  SAVE_CHANGES: "Save Changes",
  SAVE_BUTTON: "Save",
  BRAND_INFO_EDITOR_PLACEHOLDER:
    "Details of your Brand or Club, membership info, how to become a member etc",
  NO_BRAND_FOUND: "No Brand Found",
  CLUB_MAXLENGTH: "Please enter max 60 characters",
  LOGO_REQUIRED: "App logo required",
  SUBSCRIPTION_ADD_SUCCESFULLY: "Member limit update successfully",
  STATUS_CHANGE: "Active membership status change successfully",
  MEMBER_LIMIT_REQUIRED_MESSAGE: "Please enter member limit",
  PLS_ENTER_BTN_LABEL: "Please enter button label",
  BUTTON_LINK_REQUIRED: "Please enter button link",
  BUTTON_LABEL_TEXT: "Button Label",
  LINK_LABEL: "Link",
  BRANDING_INFO_SAVE: "Branding info save successfully",
  BUTTON_TEXT_COLOR_REQUIRED: "Enter button text color",
  BUTTON_BG_COLOR_REQUIRED: "Enter button background color",
  ADD_NEW_BRAND: "Add New Brand",
  CONTACT_US: "Contact Us",
  ADD_CONTACT_DETAIL: "Add contact detail",
  CONTACT_DETAIL_UPDATED: "Contact detail updated successfully",
  ADD_BRAND_NAME: `Add the BRAND NAME and BRAND EMAIL LOGIN. A password will be auto generated and sent to the login email. `,
  EMAIL_ADDRESS: "Email address",
  LOGIN_EMAIL: "LOGIN EMAIL",
  REQUIRED_EMAIL: "(Required)",
  ENTER_EMAIL_ADDRESS: "Please enter email address",
  EMAIL_MUST_5_CH: "Email must be atleast 5 characters",
  STATE_REQUIRED_TEXT: "Please select state",
  TITLE_NOT_MORE_THAN_6_CH: "Brand name should not be more than 60 characters",
  ENTER_ADDRESS: "Please enter address",
  ENTER_BUTTON_LINK: "Please enter button link",
  BUTTON_LINK_MAX_255_MAX: "Button link should not be more than 255 characters",
  BRAND_ADD_SUCCESSFULLY: "Brand added successfully",
  BRAND_DELETE_SUCCUSS: "Brand deleted successfully",
  PLS_SELECT_COUNTRY: "Please select country",
  PLS_SELECT_STATE: "Please select state",
  PLS_SELECT_BRAND: "Select brand",
  BRAND_EDITOR_ERROR: "Please enter brand details",

  //----------SAVE CHANGE MODAL TEXT----------
  CHANGES: "Unsaved",
  NOT_SAVED: "Changes",
  CHANGES_WARNING_TEXT: "You have not saved your changes?",
  DISCARD_MODAL: " Discard or Save.",
  CANCEL_BUTTON: "Cancel",
  DISCARD_CHANGES: "Discard Changes",

  DISCARD_EXIT: "Discard & Exit",
  TERMS_AND_CONDITION: "Terms & Condition",
  PRIVACY_POLICY: "Privacy Policy",

  //-----------Members module text--------
  MEMBERS_TITLE: "Members",
  INVITES_TAB: "Invites",
  LIST_BY: "List By",
  NAME: "Name",
  SURNAME: "Surname",
  AGE: "Age",
  EXPIRY_DATE: "Expiry Date",

  INVITE_MEMBER: "Invite ",

  MEMBER_LIST: "Members",
  DETAILS_TEXT: "Details",
  LOGBOOK_TEXT: "Logbook",
  PASSWORD_TEXT: "Password",
  EDIT_BUTTON: "Edit",
  FIRST_NAME: "First Name",
  FIRST_NAME_PLACHOLDER: "Enter your member first name",
  FIRST_NAME_REQUIRED: "Please enter your member first name",
  SIR_NAME: "Surname / Last Name",
  SIR_NAME_PLACEHOLDER: "Enter your member surname/last name",
  SIR_NAME_REQUIRED: "Please enter your member sirname",
  EMAIL_LABEL: "Email",
  EMAIL_PUBLIC: "Email (public)",
  DOB_REQUIRED: "Date of Birth (required)",
  GENDER_REQUIRED: "Gender (required)",
  CONTACT_NUMBER_LABEL: "Contact Number",
  HEIGHT_LABEL: "Height (Not Public - for your coach)",
  SELECT_HEIGHT: "Select Height",
  SELECT_WEIGHT: "Select Weight",
  SELECT_HEIGHT_PLACEHOLDER: "Select Height",
  WEIGHT_LABEL: "  Weight (Not Public - for your coach)",
  SELECT_WEIGHT_PLACEHOLDER: "Select Weight",
  SELECT_PHOTO: "Select Image",
  CROP_PHOTO: "Crop Photo",
  REMOVE_PHOTO: "Remove Photo",
  DETAIL_NUMBER: "Phone Number",
  TOGGLE_INFO: "Toggles off when user deletes their account.",
  SELECT_BRAND: "Select Brand",
  MEMBER_ON_OF: "Turn member on or off as required",
  OTHER_CLUB_MEMBERS: "Can View Other Club Members",
  ALLOW_USERS_INFO: "Allows user to see other Members.",
  REMOVE_BTN: "Remove",
  REMOVE_BRAND: "Brand",
  CANCEL_BTN: "Cancel",
  DELETE_BTN: "Delete",
  NAME_PLACEHOLDER_TEXT: "Input Member Name",
  INVITE_WILL_BE_SEND_TO_THIS_MAIL:
    "An email invite will be sent to this email",
  MOBILE_NO: "Mobile Number",
  WILL_BE_SENT_TO_THIS_NO: "An SMS will be sent to this number",
  SEND_INVITE: "SEND",
  INVITE_BTN: "INVITE",
  INVITE_NOTE: "Invite are added to the Brands Invite Pending screen",
  DELETE_NOTE: "Please note this will delete your   ",
  NOTE_PARA: "TRAINZA PROFILE",
  ENTERILY_TEXT: "entirely and",
  NOTE_DONE: "CANNOT BE UNDONE",
  DELETE_PROFILE_PARA: "DELETE",
  DELETE_PROFILE_TEXT: "PROFILE",
  CONFIRM_DELETE_BTN: "CONFIRM AND DELETE",
  CONFIRM_PROFILE_DELETEION: "Confirm Profile Deletion",
  PHONE_NUMBER: "Enter Phone Number",
  NO_RESULT_TEXT: "No Results",
  FOR_SEARCH_QUERY: "for search query - Change your search query",

  BTN_MAX_LENGTH: "Button text colour should not be more then 7 characters",
  INVALID_COLOR:
    "Please enter a valid hexadecimal color code (e.g., #FFAABB or #FAB).",

  TEXT_COLOR_REQUIRED: "Enter text color",
  INVALID_URL: "Please enter valid url",
  ENTER_BUTTON_TEXT: "Please enter button text color",
  BUTTON_TEXT_MAX_7_CH:
    "Button text color should not be more than 7 characters",
  ENTER_VALID_COLOR:
    "Please enter a valid hexadecimal color code (e.g., #FFAABB or #FAB).",
  ENTER_TEXT_COLOR: "Please enter text color",
  TEXT_COLOR_MAX_7_CH: "Text color should not be more than 7 characters",
  PLS_ENTER_BACKGROUND: "Please enter background color",
  BACKGROUND_MAX_7_CH: "Background color should not be more than 7 characters",
  ENTER_VALID_LINK: "Please enter a valid link",
  PLEASE_ENTER_MEMBER_NAME: "Please enetr member name",
  ALERT: "Alert",
  WANT_TO_DELETE: "Are you sure you want to delete this brand ?",
  CANCEL: "Cancel",
  OK: "Ok",
  CONFIRM: "Confirm",
  YOU_ARE_SURE: "Are you  sure?",
  THIS_CANNOT_BE_UNDONE: "This cannot be undone.",
  MEMBER_DELETED: "Member deleted successfully",
  COUNTRY_TEXT: "Country",
  STATE_TEXT: "State/Province",
  PROFILE_TEXT: "Profile",
  PICTURE_TEXT: "Picture",

  VALID_EMAIL_ADDRESS: "Please provide valid email address",
  MEMBER_ADD_SUCCESSFULLY: "Member add successfully",
  MEMBER_UPDATE_SUCCESSFULLY: "Member Update successfully",
  ADD_NEW_MEMBER: "ADD NEW MEMBER",
  UPDATE_MEMBER: "UPDATE MEMBER",
  MEMBERSHIP_INFO: "Membership Info",
  MEMBERSHIP_VALID_UPTO: "Membership Valid Until",

  MEMBERSHIP: "Membership",
  INVALID_BRAND_OBJECT: "Invalid brands object",
  MEMBERSHIP_VALID_UNTIL: "Membership Valid Until",
  CAN_VIEW_OTHER_CLUB_MEMBER: "Can View Other Club Members",
  SHARE_PUBLICLY: "Share Publicly (displayed on profile)",
  PERSONAL_TEXT: "Personal ",
  BESTS_TEXT: "Bests",
  TRAINZA_TEXT: "Trainza",
  STATUS_TEXT: "Status",
  UPDATE_TEXT: "Update",
  PERSONAL_BEST: "Personal Bests",
  PHONE_NUMBER_EXIT: "Phone number already exist",
  WORKOUT_LOGBOOK: "Workout",

  //--------Logbook css----------------
  WORKOUT: "WORKOUT ",
  TYPE: "TYPE",
  EFFORT: "EFFORT ",
  LEVEL: "LEVEL",
  LOGBOOK_NOTES: "NOTES",
  COACH: "Coach",
  EDIT: "Edit",
  DIST: "DIST",
  KM: "KM",
  TIME: "Time",
  PACE: "PACE",
  LOGBOOK_RESULTS: "Results",
  SELECT_WORKOUT: "Select Workout",
  EASY: "Easy",
  NORMAL: "Normal",
  HARD: "Hard",
  EDIT_LOGBOOK_PLACEHOLDER: "Details of your workout",
  LOGBOOK_DELETED: "Logbook deleted successfully",

  //-----------Event Module text--------
  EVENT_TITLE_TAB: "Events",
  ADD_EVENT_BTN: "Add",
  EVENT_TEXT: "Event",
  DATE_ORDER: "Date Order",
  ALPHABET_ORDER: "Alphabetical",
  NEW_EVENT: "New Event -",
  NEW_EVENT_DESCRIPTION: "Once saved you can add additional info",
  EVENT_TITLE: "Event Title",
  EVENT_TITLE_PLACEHOLDER: "Enter the title of your Event",
  EVENT_LOCATION: "Event Location",
  LOCATION_NAME: "Location Name -",
  REQUIRED_NAME: "Name of Location (required)",
  LOCATION_PLACEHOLDER_EVENT: "Enter the Name of Location",
  INCLUDE_RESULTS: "Include Results",
  ADD_EVENT_EDITOR_PLACEHOLDER: "Details of Event",
  ADD_EVENT_LABEL: "Date and Time -",
  SINGLE_EVENT: "Single Event Date and Time",
  EVENT_INFO: "Event Info",
  EVENT_IMAGE: "Event Image",
  ADD_OPTIONAL_FEATURES: "Add optional feature image for this event",
  ADD_OPTIONAL_BTN: "Add optional button to link out of app to external link.",
  ALLOW_MEMBER_TEXT:
    "Allow member to submit results for the selected distances",
  CURRUNTLY_HAVE: " You currently have",
  NO_EVENT_TEXT: "no events -",
  ADD_AN_EVENT: "Add an Event",
  PUSH_NOTIFICATION: "Push Notification",
  NOTIFICATION_PARA: "Send a push notification to alert members of new item",
  MESSAGE_LIMIT: "Message -",
  LIMITED_TEXT: "Limited to 32 chars",
  SEND_PUSH_NOTIFICATION: "SEND PUSH NOTIFICATION",
  ACTIVE_EVENT: "Active / Inactive Event",
  EVENT_TURN: "Turn event on or off as required",
  ENTER_ABOVE_TITLE: "Enter the title inserted from above (editable)",

  //------------Distance module-----------
  DISTANCE: "Distance",
  PB_DISTANCE: "Pb Distance",
  DISTANCE_CHECK_KM_LABEL: "Kilometres (KM)",
  DISTANCE_CHECK_MI_LABEL: "Miles (MI)",
  MILES_KM_TEXT: "Miles or Kilometres",
  DISTANCE_MEASUREMENT: "Set distance measurement.",

  //-------------Workout module------------------
  WORKOUT_TYPES_TEXT: "WORKOUT  TYPES",
  ADD_EDIT_TEXT: "Add/Edit",
  WORKOUT_LABEL: "Workout Type",
  ADD_PLACEHOLDER_TEXT: "Enter Activity Type",
  ACTIVITY_TYPE_REQUIRED: "Please enter activity type",
  ADD_ACTIVITY: "Activity",

  //--------Api status code mesage text--------------
  INVALID_LOGIN_CREDENTIALS: "Invalid login credentials",
  LOGIN_SUCCUSSFULLY: "Login successfully",
  INTERNAL_SERVER_ERROR: "Internal server error",
  INFO_UPDATE_SUCCESSFULLY: "Brand info update successfully",
  BRAND_NAME_EXIT: "Brand name exist",
  EMAIL_ALREADY_EXIT: "Email already exit",
  INVALID_BRAND_FILE: "Invalid brand file extension",
  INVALID_BRAND_ID: "Invalid brand id",
  INVALID_BRAND_PICTURE_SIZE: "Invalid brand picture size",
  INVALID_APP_LOGO: "Invalid app logo picture size",
  FAILED_SEND_EMAIL: "Failed to send email",
  INVALID_PERSONAL_BEST: "Invalid personal bests object",

  //------------Page Not Found-----------------
  OOPS: "Oops!",
  PAGE_404: "404 page not found",
  SESSION_EXPIRED: "Session expired",
  INVALID_TOKEN: "Invalid Token",

  //==============================
  //==============================
  //==============================
  //==============================

  SIGN_UP: "SIGN UP",

  FIRST_NAME_: "FIRST NAME",
  BRAND_NAME_: "Brand Name",

  SURNAME_LAST_NAME: "Surname / Last Name",
  WELCOME: "WELCOME",
  LAST_NAME: "Last Name",
  LAST_NAME_: "LAST NAME",
  ACCEPT_TERMS: "Accept terms and conditions of use",
  RESGISTER: "REGISTER NOW",
  NOW: "NOW",
  OR_SIGN_UP: "or Sign Up with",
  POLL_: "POLL",
  UPDATE_NEWS: "UPDATE NEWS",
  NEW_NEWS_ITEM: "NEW NEWS ITEM",
  ADD_A_POLL_TO_YOUR_EVENT: "Add a poll to your event",
  ENTER_QUESTION_HERE: "Enter the question here",
  ENTER_YOUR_ANSWER_HERE: "Enter your answer here",
  LIM_TO_35_CHAR: "Limited to 35 chars",
  ALREADY_REGISTERED: "Already registered? Sign In",
  SING_IN: "Sign In",
  SIGN_IN_TO_CONTINUE: "Sign In to continue",

  EMAIL_ADDRESS_: "EMAIL ADDRESS",
  Email: "Email",
  YOUR_EMAIL_ADDRESS: "Your Email Address",
  ADD: "Add",
  YOU_CUIRRENTLY_HAVE_NO_EVENTS: "You currently have no events",
  YOU_CUIRRENTLY_HAVE_NO_NEWS: "You currently have no news items",

  SEQUENCE: "Sequence",
  ADD_AN_NEWS: "Add News",
  RACES_ADD_EVENT: "RACES & EVENTS > ADD EVENT",
  REACES_EVENT: "RACES & EVENTS",

  REQUIRED: "REQUIRED",
  DATE_AND_TIME: "Date and Time",
  SET_TRAINING_DATE_AND_TIME: "Set Training Date and Time",
  SELECT_TIME: "Select Time",
  ANYTIME: "ANYTIME",

  FILL_ALL_THE_INPUT_FIELD:
    "Either fill in all the fields with answers or simply remove them",

  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",

  COACH_: "COACH",
  COMMENTS: "COMMENTS",
  COMMENTS_: "Comments",
  ADD_WORKOUT_COMMNET_BACK_TO_MEMBER_HERE:
    "Add workout comments back to member here",
  ADD_CMT_HERE: "Add comments on workout here…",

  HEIGHT_NOT_PUBLIC_FOR_COACH: "Height (Not Public - for your coach)",
  WEIGHT_NOT_PUBLIC_FOR_COACH: "Weight (Not Public - for your coach)",

  SELECT_COUNTRY: "Select Country",
  COUNTRY: "Country",
  POLL_ADDED_SUCCESSFULLY: "Poll added successfully",
  STATE_PROVINCE: "State/Province(required)",
  SELECT_STATE_PROVINCE: "Select State/Province",
  STATE_PRIVINCE: "State/Province",
  TOWN_CITY_REQ: "Town/City(required)",

  INPUT_YOUR_CITY_TOWN: "Input Your City/Town",
  PROFILE_PICTURE: "Profile Picture",
  PROFILE: "Profile",
  PICTURE: "Picture",

  PERSONAL: "Personal",
  BESTS: "Bests",

  CROP: "Crop",

  REMOVE: "Remove",
  SELECT_DATE: "Select Date",

  BEST_TIME: "BEST TIME",
  WORKOUT_GRPS: "Workout Groups",

  TRAING_DATE_TIME_AND_GRP: " TRAINING DATE, TIME AND GROUP",
  WORKOUT_: "WORKOUT",
  NOTES: "NOTES",
  NOTES_: "Notes",
  DETAILS_OF_YOUR_WORKOUT: "Details of your workout",
  GROUPS_: "Groups",
  GROUP: "Group",
  UPLOADED_IMAGES: "Uploaded images",
  UPLOADED_IMAGE: "Uploaded image",
  "ALREADY_REGISTERED?": "Already registered?",
  REGISTER: "REGISTER",
  SIGN_IN: "Sign In",
  SCHOOL: "School",
  ECOMRADES_RUNNERS: " EComrades Runners",
  SOMETHING_ELSE: " Something Else",
  TRIATHLON_GRP: "Triathlon Group",
  FREESTYLERS: "Freestylers",

  THIS_INFO_WILL_DISPLAYED_IN_APP:
    " This information will be displayed in the app on your Brand/Club Page for App users/member to contact you.",
  SAVE: "Save",
  BRAND_CLUB_: "Brand / Club",

  TURN_MEMBER_ON_OFF_AS_REQUIRED: "Turn member on or off as required",

  ALLOW_USER_TO_SEE_OTHER_MEMBER: "Allows user to see other Members.",
  CENCEL: "Cancel",

  YOU_HAVE_NOT_SAVE_CHANGES:
    "You have not saved your changes. Cancel and Save.",
  TODAY: "TODAY",

  CONTACT_NO: "Contact Number",
  AN: "An",

  MOBILE_NUMBER_: "MOBILE NUMBER",

  EMAIL_: "email",
  SMS: "SMS",

  YOUR_PASSWORD: "Your Password",
  REPEAT_PASSWORD: "Repeat Password",
  ENTER_EMAIL: "Enter Email",

  RESET_PASSWORD: "Reset Password",
  PASSWORD_: "PASSWORD",

  LOGIN_: "LOGIN",
  LOG: "LOG",
  IN: "IN",
  GRP_DELETED: "Group deleted successfully",
  VOTER_DELETED: "Voter deleted successfully",
  NEWS_DELETED: "News deleted successfully",
  EVENT_DELETED: "Event deleted successfully",

  IMG_DELETED: "Image deleted successfully",
  IMAGE_ADDED: "Image has been successfully added to the gallery",
  GALLERY_UPDATED: "Sequence of gallery images has been successfully updated.",
  RESULT_UPDATED: "Result updated successfully",
  RESULT_ADDED: "Result added successfully",
  ENTER_TIME: "Please enter time",
  INVITATION_CANCELLATION_SUCCESSFULLY:
    "Invitation cancellation has been successful",
  RESULT_DELETED: "Result deleted successfully",
  ENTER_EVENT_DETAIL: "Please enter event detail",
  ENTER_CONDITION: "Please enter terms & condition",
  START_DATE_AND_TIME: "Start Date and Time",
  SELECT_EVENT_DATE_TIME: "Select Event Date and Time",
  END_DATE_TIME: "End Date and Time",
  SELECT_DAY_THAT_EVENT_REPEAT: "Select Day that Event Repeats On",
  REPEATING_DAY: "Repeating Day",
  SET_EVENT_REPEAT_ON: "Set Time Event Repeats On",
  REPEATING_TIME: "Repeating Time",

  ERROR_DETAIL: "Please enter route detail.",
  ERROR_DISTANCE: "Please enter distance values.",
  MEMBERS_INVITES: "MEMBERS > INVITES",
  MEMBERS_ADD_NEW: "MEMBERS > ADD NEW",
  LOGIN_CREDENTIAL_UPDATED: "Login credential updated successfully",
  CURRNETLY_HAVE_NO_GRP: "You currently have no training groups",
  CURRNETLY_HAVE_NO_RESULT: "You currently have no result",
  FORGET_PASSWORD: "Forgot Password?",
  DASHBOARD: "Dashboard",
  MEMBERS: "MEMBERS",
  MEMBER: "Members",
  MEMBER_SMALL: "Member",
  DISTANCE_SELECT: "Select Distance",
  ALL: "All",

  LOAD_MORE: "Load More",
  LOAD: "Load",
  MORE: "More",
  INVITES: "Invites",
  PENDING_INVITE_COUNTS: "Pending invites count towards your member total",
  INVITE: "Invite",
  SEARCH_: "Search...",
  GROUPS: "GROUPS",
  TEMPLATES: "TEMPLATES",
  TRAINING: "Training",

  EVENTS_: "Events",

  DIS_CHEM_HALF_MARATHON_2024: "DIS-CHEM HALF MARATHON 2024",
  COPY_THIS_EVENT_TO_CALENDER: "Copy this event to your calendar",

  RESULTS_: "Results",
  RESULTS__: "Result",
  NEWS_ADD: "NEWS > ADD",
  RACES_EVENT_GALLERY: "RACES & EVENTS > GALLERY",
  IMG_GALLERY: "Image Gallery",
  ADD_MULTIPLE_IMG: "Add image to this Event",
  IMAGES: "Images",
  LOGBOOK: "Logbook",
  ADD_NEWS: "ADD NEWS",

  STATUS_CHANGED: "Status has been successfully changed",
  EVENT_DEACTIVE: "Event has been deactivated",
  EVENT_ACTIVATED: "Event has been activated",

  NEWS_DEACTIVE: "News has been deactivated",
  NEWS_ACTIVATED: "News has been activated",

  LIMIT: "Limit",
  YOU_HAVE_REACHED_YOUR: "You have reached your",
  ACTIVE_MEMBER_LIMIT: "Active Member LImit.",
  REACHED: "Reached",
  YOUR_PLAN_LIMIT_IS_LIMITED_TO: "Your cureent plan is limited to",
  "200_MEMBERS": "200 members",
  UPGRADE_CANCEL: "Upgrade, Downgrade or Cancel Subscription",
  MANAGE_SUBSCRIPTION_TO_MANAGE:
    "To manage your subscription, Please visit our website.",
  MANAGE_SUBSCRIPTION: "MANAGE SUBSCRIPTION",

  MANAGE: "MANAGE",
  MEMBER___: "Member",
  NOT_RESGISTERED: "Not registered?",
  SIGN_UP__: "Sign Up",
  REGISTER_ME: "Remember Me",
  FORGET_PASSWORD_: "Forgot Password?",
  YOU_ARE_ABOUT_TO: "You are about to",
  DELETE_THIS_MEMBER_ARE_YOU_SURE: "delete this member. Are you sure?",
  ARE_YOU_SURE: "Are you sure?",

  SURE_ROUTE_DELETE: "You want to delete this route",
  ACTIVE_MEMBER: "Active Member",
  CLOSE: "Close",
  DELETE_MEMBER: "Delete Member",

  NEWS_: "News",
  ITEM: "Item",
  OR_LOGIN_WITH: "or Login with",
  ROUTES_DISTANCES: "Routes/Distances",
  ROUTE: "Route",
  ROUTE_: "ROUTE",

  DISTANCE_: "DISTANCE",
  LOGOUT_SUCCESSFULLY: "Logout successfully",
  BRAND_INFO_ADDED: "Brand info added successfully",
  MEMBER_ADDED_SUCCESSFULLY: "Member added successfully",
  MEMBER_UPDATED_SUCCESSFULLY: "Member updated successfully",

  CUSTOMERS: "Customers",
  LIVE_CLASSES: "Live Classes",
  STUDY_NOTES: "Study Notes",
  PROJECTS: "Projects",
  SUBCRIPTIONS: "Subscriptions",

  SUBSCRIPTION_: "Subscription",
  REPOSITORY: "Repository",
  SEARCH: "Search",
  SEARCH_MEMBER_NAME: "Search Member Name…",
  PENDING: "Pending",

  ACTIVE_INACTIVE_ITEM: "Active / Inactive Item",
  NEWS_GALLERY: "NEWS > GALLERY",
  NEWS_POLL: "NEWS > POLL",
  ADD_POLL_TO_NEWS_ITEM: "Add a poll to your News Item",

  ENTER_NAME_OF_BRAND_CLUB: "Enter the Name of Your Brand/Club",
  NAME_OF_ROUTE: "Name of Route",
  RESEND: "Resend",
  RESEND_: "RESEND",
  SEND: "Send",
  INVITE_: "Invite",
  INVITEs: "INVITES",
  INVITE_HAS_BEEN: "Invite has been",
  SENT_SUCCESSFULLY: "sent successfully.",
  SEND_ANOTHER: "Send Another",
  ANOTHER: "Another",

  THEIR_NAME: "Input Their Name",

  USER_NAME: "User name",
  SUBCRIPTION: "Subscription",
  UPCOMING_CLASSES: "Upcoming Classes",
  ACTION: "Action",

  EVENT_ADDED_SUCCESSFULLY: "Event added successfully",
  NEWS_ADDED_SUCCESSFULLY: "News added successfully",
  EVENT_UPDATED_SUCCESSFULLY: "Event updated successfully",
  NOTIFICATION_SEND_SUCCESSFULLY: "Notification send successfully",

  ROUTE_ADDED_SUCCESSFULLY: "Route added successfully",
  MAX_12_IMG_LIMIT: "You cannot upload more than 12 images",
  ROUTE_UPDATED_SUCCESSFULLY: "Route updated successfully",
  SURE_DELETE_ROUTE: "Route deleted successfully",
  NEWS_UPDATED_SUCCESSFULLY: "News updated successfully",
  SURE_TO_DELETE: "Are you sure you want to delete this user",
  SURE_TO_DELETE_CLASS: "Are you sure you want to delete this Class",
  SURE_TO_EDIT_CLASS: "Are you sure you want to edit this Class",
  YES: "Yes",

  TRAINING_GROUP_ADD_GROUP: "TRAINING GROUPS > ADD GROUP",
  ADD_GRP: "Add Group",
  ADD_RESULT: "Add Result",

  CONFIRM_DELETE: "Confirm Delete",
  CUSTOMER_DETAIL: "Customer Details",

  TITLE: "Title",
  ENTER_TITLE_OF_NEW_ITEM: "Enter the title of your new item",
  DATE_TIME_DETERMINES:
    "Date and Time - Determines the order of the news items",
  CONTENT: "Content",
  DETAIL_OF_YOUR_NEWS_ITEM: "Details of your news item",
  FEATURE_IMAGE: "Feature Image",
  ADD_RECOMMENDED_IMAGE:
    "Add optional featured image for this article. Recommended..",
  DETAILS: "Details",
  GALLERY: "Gallery",
  POLL: "Poll",
  PHONE_NO: "Phone Number",
  WHATSAPP_NO: "WhatsApp Number",
  SUB: "SUB",
  OPTIONAL_WHATSAPP_NO_BUTTON: "Optional WhatsApp contact button",
  CONTACT_EMAIL: "Contact Email",
  OPTIONAL_CONTACT_EMAIL_ADDRESS: "Optional contact email address",

  OPTIONAL_WEBSITE_ADDRESS_WITH_BUTTON: "Optional website address with button",
  HTTP: "http://",

  OPTIONAL_PHONE_NO_BUTTON: "Optional Phone Number button",
  DETAIL_OF_YOUR_BRAND_CLUB_MEMBERSHIP:
    "Details of your Brand or Club, membership info, how to become a member etc",
  OPTIONAL_ROUTE_INFO: "(optional route info)",
  DETAILS_: "DETAILS",
  DESCRIPTION: "Description",
  STATUS: "Status",
  DURATION: "Duration",
  CATEGORY: "Category",
  CREATE_LIVE_CLASS: "Create Live class",
  CATEGORIES: "Categories",
  DATE_TIME: "Date Time",
  VIEW: "View",
  NO_RESULTS: " No results",
  NO_EVENT_ON: " No event on",
  NO_NEWS_ON: " No news on",
  READY_MADE: " READY MADE",
  TRAINZA_EVENTS: "TRAINZA EVENTS",
  COPY: "Copy",
  COPY_EVENT:
    "Copy events with one click from the Trainza Event Calendar to YOUR calendar and then edit as you want.",

  CURRENTLY_NO_MEMBER: "You currently have no members",
  CURRENTLY_NO_TRAINING: "You currently have no training",

  TYPES: "Types",
  WORKOUT_S: "Workout/s",
  SELECT_CATEGORY: "Select Category",
  DONE: "Done",
  ENTER_TITLE: "Enter title",
  DATE: "Date",

  TIME_: "TIME",

  CLEAR_INPUTS: "CLEAR INPUTS",
  TYPE_HERE: "Type here",

  TYPE_: "Type",

  DISTANCE_AND_TIME: "DISTANCE AND TIME",
  MIN: "Min",
  LIVE_CLASS_DETAIL: "Live Class Details",
  CREATE_STUDY_NOTES: "Create Study Note",
  UPDATE_STUDY_NOTES: "Update Study Note",
  VIDEO: "Video",
  VIDEO_: "VIDEO",
  ACTIVE_POLL: "Active Poll",
  TUEN_POLL_ON_OFF: "Turn poll on or off as required",
  SHOW_PARTICIPANT: "Show Participant Details",
  HIDE_WHO_VOTED: "Hide who voted on the poll",
  VOTES: "Votes",
  VOTE: "Vote",
  VIEW_VOTERS: "View Voters",
  VIEW_VOTER: "View Voter",
  ADD_OPTIONAL_IMAGE_FOR_YOUR_WORKOUT: "Add optional image for your workout.",
  ADD_OPTIONAL_VIDEO_FOR_YOUR_WORKOUT: "Add optional video for your workout.",

  INCLUDE_RESULT: "Include Results",
  ALLOW_MEMBER_TO_SUBMIT_RESULT:
    "Allow member to submit results for the selected distances",
  SELECT_RESULT_DISTANCE: "Please select result distance",

  MSG_LIMIT_TO_32: "Message - Limited to 32 chars",
  INSERT_TITLE: "Enter the title inserted from above (editable)",
  SEND_PUSH_NOTIFICATION_SEND: "SEND PUSH NOTIFICATION",
  ACTIVE_INACTIVE_EVENT: "Active / Inactive Event",
  ON_OFF_AS_REQUIRED: "Turn event on or off as required",
  SAVE_CHANGES_: "Save Changes",
  LINK_TO_VIDEO: "Link to Video",
  ADD_VIDEO_LINK: "Add Video Link…",
  LINK: "Link",
  LINK_: "LINK",
  LINK_TO_ROUTE: "Link to Route",
  STRAVE_MAPBOX: "Strave, Mapbox, Plotaroute etc",
  ADD_LINK: "Add Link…",
  ADD_OPTIONAL_BUTTON:
    "Add optional button to link out of app to external link.",
  OPTIONAL_: "optional",
  ADD_OPTIONAL_ROUTE: "Add optional route image (map image or scene).",
  SURE_TO_DELETE_NOTES: "Are you sure you want to delete this Study notes",
  SURE_TO_EDIT_NOTES: "Are you sure you want to edit this Study notes",
  UPLOAD_VIDEO: "Upload Video",
  UPLOAD: "Upload",
  CREATE_PROJECT: "Create Project",
  DETAIL_ROUTE_PLACEHOLDER: "Details of Route...",
  LINK_PLACEHOLDER: "Add Link...",

  CREATE_NOTE: "Create Note",
  UPDATE_NOTE: "Update Note",
  NOTES_DETAIL: "Study Notes Details",
  QUESTION_ANS: "Questions & Answer",
  ADD_QUESTION: "Add Question",
  QUESTION: "Question",
  OPTION: "Option",
  ANSWER: "Answer",
  QUESTION_DETAIL: "Question & Answer Details",
  STUDENT_LIST: "Students List",
  STUDENT_NAME: "Student Name",
  ENTER_QUESTION: "Enter question",
  ENTER_OPTION: "Enter option",
  SELECT_ANSWER: "Select Answer",

  SAVED_ROUTE: "Saved Route",
  WORKOUT_TYPE: "Workout Type",
  WORKOUT_TYPES: "Workout Types",
  SAVED_WORKOUT: "Saved Workout",
  QUICKLY_ADD_IN_A: "Quickly add in a",
  SAVED_WORKOUT_ABOVE: "saved workout above,",
  ADD_MANUALLY_BELOW: "add manually below",
  MANUALLY: "Manually",
  TRAINING_GROUP: "Training Group/s",
  LOGIN_SUCCESSFULLY: "Login successfully",
  HOURS: "Hours",
  MINUTES: "Minutes",
  MINS: "Mins",
  SECS: "Secs",
  SECONDS: "Seconds",
  BRANDING_ADDED_SUCCESSFULLY: "Branding added successfully",
  STUDY_NOTES_ADDED: "Study note added successfully",
  STUDY_NOTES_UPDATED: "Study note updated successfully",
  UPDATE: "Update",
  PLAN: "Plan",
  Please_UPLOAD_VIDEO: "Please upload video",

  ADD_TRAINING_: "Add Training",
  GOOGLE_PLAY: "Google Play",
  GET_IT_ON: "GET IT ON",
  DOWNLOAD_ON_THE: "Download on the",
  APPLE_STORE: "Apple Store",
  AVAILABLE_ON: "Available on",
  GALAXY_STORE: "Galaxy Store",
  EXPLORE_IT_ON: "Explore IT ON",
  APPGALAXY: "AppGalaxy",

  SUNDAY: "Sunday",
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
  JANUARY: "January",
  FEB: "February",
  MARCH: "March",
  APRIL: "April",
  MAY: "May",
  JUNE: "June",
  JULY: "July",
  AUGUST: "August",
  SEP: "September",
  OCT: "October",
  NOV: "November",
  DEC: "December",
  TRAINING_GROUPS: "TRAINING GROUPS",
  TRAINING_GRP: "Training Group",
  GRP_NAME: "Group Name",
  INPUT_GRP_NAME: "Input Group Name",
  SUBTITLE_GRP_TRAINER_OPTIONAL:
    "Subtitle / Group Trainers / Description (Optional)",
  INPUT_SUBTITLE: "Input Subtitle",
  ABOUT_INFO: "About Info",
  OTP_RESEND: "OTP resend successfully",
  OTP: "OTP",
  INFO_: "INFO",
  OPTONAL_DESCRIPTION: "Optional Description…",
  BRAND_CLUB_SIGN: "BRAND/CLUB SIGN",
  BRAND_CLUB: "BRAND/CLUB",
  UP: "UP",
  OPTIONAL: "OPTIONAL",
  OPTIONAL__: "Optional",
  ADDRESS_NAME: "Address Name",
  NAME_OF_LOCATION_REQUIRED: "Name of Location (required)",
  ENTER_LOCATION_NAME: "Enter the Location Name",
  BRAND_NAME: "BRAND NAME",
  GOOGLE_ADDRESS: "Google Address",
  MEMBERSHIP_PAGE_IMG: "Membership Page Image (Optional)",
  ADD_OPTIONAL_FEATURE_IMG: "Add optional feature image for this page",
  SELECT_IMG: "Select Image",
  ADD_BUTTON: "Add Button",
  BUTTON_LABEL: "Button Lable",

  ADD_OPTIONAL_BUTTON_TO_LINK:
    "Add optional button to link out of app to external link.",
  INCLUDE_LINK_TO_LOCATION: "Include link to location (optional)",
  TYPE_ADDRESS_OR_LEAVE_BLANK: "Type in address or leave blank",
  DRAG_PIN_TO_CORRENT_LOCATION: "Drag pin to correct location if required.",
  ASK_YOUR_MEMBER:
    "Ask your member or athlete to download the TRAINZA app and sign up once they have create a profile search and invite them here.",
  BRAND_SIGN_UP_SUCCESSFULLY: "Brand signup successfully",

  EVENT_IMG: "Event Image",
  OPTIONAL_FEATURE_IMG: "Add optional feature image for this event",
  CHANGE_PASSWORD: "CHANGE PASSWORD",
  SAVED: "Saved",
  REQUIRED_: "required",
  REQUIRED__: "Required",
  ADD_DISTANCE_INDICATION: "Add distance indication (in kilometres or miles)",
  KILOMATERS: "Kilometres",
  MILES_MI: "Miles (MI)",
  EMAIL__: "EMAIL",
  CODE: "CODE",
  VERIFICATION: "VERIFICATION",
  PHONE: "PHONE",
  VERIFY: "VERIFY",

  BRANDING_: "Branding",

  ROUTES: "ROUTES",
  ROUTEES: "Routes",
  ROUTE__: "Route",
  ROUTES_ADD_NEW: "ROUTES > ADD/EDIT",
  DISTANCES: "Distances",
  ALPHABETICAL: "Alphabetical",
  UPDATE_EVENT: "UPDATE EVENT",

  ONCE_SAVED_YOU_CAN_ADD: "Once saved you can add additional info",
  LOW_DISTANCES: "Low Distance",

  ENTER_EVENT_TITLE: "Enter the title of your Event",
  RACES_EVENTS_POLL: "RACES & EVENTS > POLL",
  RACES_EVENTS_RESULTS: "RACES & EVENTS > RESULTS",
  RACES_EVENTS_DETAILS: "RACES & EVENTS > DETAILS",
  HIGH_DISTANCES: "High Distance",
  CURRENTLY_NO_ROUTES: "You currently have no saved routes",
  ADD_ROUTES: "Add Route",
  COLOR: "Color",

  EXAMPLE: "EXAMPLE",
  EXAMPLE_: "Example",
  BUTTON: "BUTTON",
  TEXT_COLOR_ON_BTN: "Text colour on buttons",

  BACKGROUND_COLORS_ON_BUTTON: "Background colour on buttons",

  COLOURED: "COLOURED",
  TEXT: "TEXT",

  Email_: "Email",

  APP_LOGO: "App Logo",
  BRAND_INFO_SUBSCRIPTION: "BRAND INFO > SUBSCRIPTION",
  DUPLICATE: "Duplicate",
  N_A: "N/A",
  ROUTE_LINK: "Route Link (optional)",
  ROUTE_LINK_DETAIL: "Add optional button to link out of app to external link.",
  ROUTE_LINK_LABEL: "(Strave, Mapbox, Plotaroute etc)",
  ROUTE_INFO: "Route Info (optional)",
  ROUTE_INFO_DETAIL: "Add optional route image (map image or scene).",
  ROUTE_DIS: " Route Distance (required)",
  ROUTE_DIS_DETAIL: "Add distance indication (in kilometres or miles)",
  OPTIONA: "(optional)",
  KILOMETER: " Kilometres (KM)",
  MILES: " Miles (MI)",
  ACTIVE_INACTIVE_: "Active / Inactive Route",
  ACTIVE_DETAIL: "Turn on or off as required (will be hidden from selection).",

  "===================VALIDATION==============": "======================",

  WEBADDRESS_MAX_250_CH:
    "Website address should not be more than 255 characters",
  ANSWER_MAX_35_CH: "ANSWER_MAX_35_CH",

  ADDRESS_MAX_60_CH: "Address should not be more than 60 characters",
  GOOGLE_ADDRESS_MAX_255_CH:
    "Goole address should not be more than 255 characters",

  BUTTON_LABEL_MAX_50_CH: "Button label should not be more than 50 characters",

  ENTER_ROUTE_LINK: "Please enter route link.",
  ROUTE_LINK_MAX_255_MAX: "Route link should not be more than 255 characters.",
  ROUTE_NAME_MAX_255_MAX: "Route name should not be more than 255 characters.",

  VALID_WEB_ADDRESS: "Please enter a valid website address",

  UPLOAD_APP_LOGO: "Please upload app logo",
  ENTER_PASSWORD: "Password is required",
  PASSWORD_NOT_MATCHED: "Password not matched",

  PROVIDE_VALID_EMAIL: "Please provide valid email address",
  PASSWORD_REQUIRE: "Please enter password",
  CON_PASS_IS_REQ: "Please repeat your password",
  NAME_IS_REQUIRED: "Please enter brand name",
  LAST_NAME_IS_REQUIRED: "Please enter last name",

  PASSWORD_MUST_6_CH: "Password must be atleast 6 characters",

  PASSWORD_NOT_MORE_THAN_12_CH:
    "Password should not be more than 12 characters",
  Please_ENTER_MOBILE_NO: "Please enter mobile number",

  TITLE_MORE_THAN_6_CH: "Title must be atleast 6 characters",

  GROUP_ADDED_SUCCESSFULLY: "Group addedd successfully",
  PLS_ENTER_BRAND_DETAIL: "Please enter brand detail",
  EMAIL_NOT_MORE_THAN_6_CH: "Email should not be more than 100 characters",
  DATE_REQUIRED: "Date is required",
  TIME_REQUIRED: "Time is required",
  ENTER_DESCRIPTION: "Enter description",
  DESCRIPTION_MUST_6_CH: "Description must be atleast 6 characters",
  DESCRIPTION_NOT_MORE_THAN_6_CH:
    "Description must be less than 500 characters",
  DURATION_IS_REQUIRED: "Duration is required",
  QUESTION_MUST_6_CH: "Question must be atleast 6 characters",
  QUESTION_NOT_MORE_12_CH: "Question must be less than 13 characters",
  OPTION_MUST_6_CH: "Option must be atleast 6 characters",
  ANSWER_MAX_MAX_35_CH: "Answer should not be more than 35 characters",
  OPTION_NOT_MORE_THAN_12_CH: "Option must be less than 13 characters",
  PLS_ENTER_EMAIL_CODE: "Please enter email code",
  EMAIL_CODE_MUST_4_DIGIT: "Please enter complete email code",
  PLS_ENTER_PHONE_CODE: "Please enter phone code",
  PHONE_CODE_MUST_4_DIGIT: "Please enter complete phone code",

  CONTACT_NO_MUST_BE_ATLEAST_5_DIGIT: "Contact no must be atleast 5 digit",
  PLS_ENTER_EMAIL_OTP: "Please enter email otp",
  PLS_FILL_COMPLETE_OTP: "Please fill complete otp",
  ENTER_CONTENT: "Please enter content",
  PLZ_ENTER_GRP_NAME: "Please enter group name",
  GRP_NAME_MAX_50_CH: "Group name should not be more than 50 characters",
  ABOUT_INFO_MAX: "About info should not be more than 200 characters",
  PLZ_ENTER_INFO: "Please enter your info",
  ENTER_FIRST_NAME: "Please enter first name",
  FIRST_NAME_MAX_20_CH: "First name should not be more than 20 characters",
  ENTER_SURNAME_LAST_NAME: "Please enter surname/last name",
  ENTER_QUESTION_: "Please enter question",
  ANSWER_REQUIRED: "Please enter answer",
  SURBNAME_MAX_20_CH: "Surname/last name should not be more than 20 characters",
  QUESTION_MAX_255_CH: "Question should not be more than 255 characters",
  SELECT_DOB: "Please select date of birth",
  SELECT_DATE_: "Please select date",
  SELECT_GENDER: "Please select gender",
  LOCATION_NAME_MAX_250_CH:
    "Location name should not be more than 250 characters",
  TITLE_MAX_50_CH: "Title should not be more than 50 characters",
  MESSAGE_MAX_50_CH: "Message should not be more than 32 characters",
  VIDEO_LINK_MAX_255_CH: "Video link should not be more than 255 characters",
  NEWS_TITLE_MAX_100_CH: "Title should not be more than 100 characters",

  ENTER_CONTECT_NO: "Please enter contact number",
  WEIGHT: "Please select weight",
  PLEASE_TITLE: "Please enter title",
  NOTIFICATION_MSG: "Please enter message",
  START_DATE: "Please select start date",
  SELECT_LOCATION_NAME: "Please enter location name",
  RESULT_DISTANCE: "Please select result distance",
  START_TIME: "Please select start time",
  END_TIME: "Please select end time",
  REPEATING_DAYS: "Please select repeating day",
  SELECT_REPEATING_TIME: "Please select repeating time",
  SELECT_EVENT: "Please select event",
  SELECT_MEMBER: "Please select member",
  SELECT_DISTANCE: "Please select distance",
  SELECT_TIME_: "Please enter time",
  ROUTENAME_IS_REQUIRED: "Please enter route name.",

  "===================BACKEND ERROR MSG==============":
    "======================",

  PLS_ADD_A_GRP: "Please add a group",
  HEADER_IS_MISSING: "Header is missing",
  DEVICE_TYPE_NOT_ALLOWED: "Device type not allowed",
  INVALID_API_KEY: "Invalid Api Key",
  AUTH_TOKEN_IS_REQUIRED: "A token is required for authentication",

  VALIDATION_ERROR:
    "Request validation error (returning message key with this)",
  SUCCESS: "Success",
  INVALID_LOGIN_CREDENTIAL: "Invalid login credentials",
  EMAIL_EXIST: "Email already exist",

  BRAND_NAME_EXIST: "Brand name already exist",
  INVALID_VERIFICATION_CODE: "Invalid verification code",
  FAILED_TO_SEND_EMAIL: "Failed to send email",

  INVALID_IMAGE_SIZE: "Invalid brand image size",
  INVALID_BRAND_FILE_EXTENSION: "Invalid brand file extension",
  INVALID_APP_LOGO_IMAGE_SIZE: "Invalid app logo image size",
  INVALID_APP_LOGO_FILE_EXTENSION: "Invalid app logo file extension",
  APP_LOGO_REQUIRED: "App logo required",
  COUNTRY_ID_IS_REQUIRED: "Country id is required",

  INVALID_GROUP_ID: "Invalid group id",
  EMAIL_AND_PHONE_NUMBER_EXIST: "Email and phone number exist",
  INVALID_MEMBER_ID: "Invalid member id",
  INVALID_MEMBER_PICTURE_SIZE: "Invalid member picture size",
  INVALID_MEMBER_FILE_EXTENSION: "Invalid member file extension",
  GROUP_NAME_EXIST: "Group name already exist",
  GROUP_UPDATED_SUCCESSFULLY: "Group updated successfully",
  INVALID_PERSONAL_BESTS_OBJECT: "Invalid personal bests object",
  INVALID_BRANDS_OBJECT: "Invalid brands object",
  MEMBER_LIMIT_EXCEEDED: "Member limit exceeded",
  THE_GROUP_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION:
    "The group is already in use, you can't perform a delete operation.",
  PHONE_NUMBER_EXIST: "Phone number exist",
  PROFILE_PICTURE_REQUIRED: "Profile picture required",
  INVALID_EVENT_ID: "Invalid event id",
  INVALID_EVENT_PICTURE_SIZE: "Invalid event picture size",
  INVALID_EVENT_FILE_EXTENSION: "Invalid event file extension",
  INVALID_RESULT_DISTANCE_JSON_OBJECT: "Invalid result distance json object",
  INVALID_KEY_IN_RESULT_DISTANCE_JSON_OBJECT:
    "Invalid key in result distance json object",
  EVENT_NAME_ALREADY_EXIST: "Event name already exist",
  MEMBER_IS_INACTIVE: "Member is inactive",
  ACCOUNT_NOT_FOUND: "Account not found",
  INVALID_PERSONAL_BESTS_JSON_OBJECT: "Invalid personal bests json object",
  INVALID_BRANDS_JSON_OBJECT: "Invalid brands json object",
  EVENT_GALLERY_REQUIRED: "Event gallery required",
  INVALID_EVENT_GALLERY_PICTURE_SIZE: "Invalid event gallery picture size",
  INVALID_EVENT_GALLERY_FILE_EXTENSION: "Invalid event gallery file extension",
  BRANDS_FIELD_IS_REQUIRED: "Brands field is required",
  INVALID_POLL_ANSWERS_JSON_OBJECT: "Invalid poll answers json object",
  INVALID_KEY_IN_POLL_ANSWERS_JSON_OBJECT:
    "Invalid key in poll answers json object",
  DUPLICATE_POLL_ANSWER_FOUND: "Duplicate poll answer found",
  NUMBER_OF_POLL_ANSWER_CAN_BE_MAXIMUM_10:
    "Number of poll answer can be maximum 10",
  INVALID_TRAINING_GROUPS_JSON_OBJECT: "Invalid training groups json object",
  ALREADY_A_MEMBER_OF_THIS_CLUB: "Already a member of this club",
  MEMBER_INVITATION_IS_ALREADY_SENT: "Member invitation is already sent",

  INVALID_EVENT_RESULT_ID: "Invalid event result id",
  INVALID_INVITATION_ID: "Invalid invitation id",
  ACTION_ALREADY_TAKEN: "Action already taken",
  INVALID_EVENT_DISTANCE_ID: "Invalid event distance Id",
  EVENT_RESULT_ALREADY_EXISTS: "Event result already exists",
  INVALID_WORKOUT_TYPE_ID: "Invalid workout type id",
  WORKOUT_TYPE_EXISTS: "Workout type exists",
  THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION:
    "The workout type is already in Use you cant perform a delete operation",
  FAILED_TO_SEND_SMS: "Failed to send sms",
  WORKOUT_TITLE_EXISTS: "Workout title exists",
  INVALID_WORKOUT_ID: "Invalid workout id",
  INVALID_WORKOUT_PICTURE_SIZE: "Invalid workout picture size",
  INVALID_WORKOUT_PICTURE_FILE_EXTENSION:
    "Invalid workout picture file extension",
  THE_WORKOUT_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION:
    "The workout is already in use you can't perform a delete operation",
  ROUTE_NAME_EXISTS: "Route name exists",
  INVALID_ROUTE_PICTURE_SIZE: "Invalid route picture size",
  INVALID_ROUTE_PICTURE_FILE_EXTENSION: "Invalid route picture file extension",
  INVALID_ROUTE_ID: "Invalid route id",
  THE_ROUTE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION:
    "The route is already in use you can't perform a delete operation",
  INVALID_ANSWER_ID: "Invalid answer id",
  NEWS_GALLERY_REQUIRED: "News gallery required",
  INVALID_NEWS_GALLERY_PICTURE_SIZE: "Invalid news gallery picture size",
  INVALID_NEWS_GALLERY_FILE_EXTENSION: "Invalid news gallery file extension",
  INVALID_NEWS_ID: "Invalid news id",
  INVALID_TRAINING_ID: "Invalid training id",
  INVALID_NEWS_PICTURE_SIZE: "Invalid news picture size",
  INVALID_NEWS_FILE_EXTENSION: "Invalid file Extension",
  NEWS_TITLE_EXISTS: "News Title Exists",
  INVALID_EVENT_POLL_VOTE_ID: "Invalid event poll vote id",
  POLL_VOTE_EXISTS: "Poll vote exists",
  WORKOUT_TYPE_TEXT: "Enter Workout Type",
  WORKOUT_TYPE_IS_REQUIRED: "Workout type is required",
  TYPE_NOT_MORE_THAN_255_CH:
    "Workout types should not be more than 255 characters",
  TYPE_NOT_MORE_THAN_50_CH:
    "Workout types should not be more than 50 characters",
  WORKOUT_DELETE_SUCCESSFULLY: "Workout delete successfully",
  WORKOUT_TYPE_DELETE_SUCCESSFULLY: "Workout type deleted successfully",
  ADD_NEW_WORKOUT: "NEW WORKOUT - ",
  ADD_NEW_TEXT: "Save a Workout for easy selection",
  WORKOUT_TITLE_TEXT: "Workout",
  WORKOUT_TITLE_REQUIRED: "Title ",
  ADD_WORKOUT_TITLE: " Add Workout Title",
  LABEL_REQUIRED: "(required)",
  DETAILS_OF_WORKOUT: "Details of Workout...",
  NO_DATA_WORKOUT: "No workout found",
  ADD_WORKOUT: "Add workout",
  LINK_TO: "Link to",
  IMAGE_UPLOAD: "IMAGE",
  UPLOAD_IMAGE: "Upload",
  ACTIVE_INACTIVE: "Active / Inactive ",
  SWITCH_HEADING: "Turn on or off as required (hidden from selection).",
  WORKOUT_ADD_SUCCUSFULLY: "Workout added successfully",
  NO_DATA_FOUND: "You currently have no workout",
  WORKOUT_UPDATED_SUCCUSFULLY: "Workout updated successfully",
  NO_WORKOUT_TYPE: "You currently have no workout type",
  ADD_TYPE: "Add type",
  UPDATE_WORKOUT: "UPDATE WORKOUT -",
  UPDATE_WORKOUT_TYPE: "Workout type updated successfully",
  ADD_WORKOUT_TYPE: "Workout type added successfully",
  WORKOUT_TITLE_IS_REQUIRED: "Workout title is required",
  PLS_ENTER_WORKOUT_DETAIL: "Please enter workout detail",
  NEW_GROUP: "NEW GROUP TRAINING ITEM - ",
  ADD_TRAINING: "Add training to Calendar",

  TRANING_UPDATED_SUCCUSFULLY: "Training updated successfully",
  TRANING_ADD_SUCCUSFULLY: "Training added successfully",
  ROUTE_TYPE_IS_REQUIRED: "Route type is required",
  VIEW_VIDEO: "VIEW VIDEO",
  NO_ROUTE_DATA_FOUND: "No route data found",

  POLL_VOTE_EXIST: "Poll vote not exist",
  CANNOT_ADD_MORE_THAN_ONE_GRP: "Cannot add more than one group",
  CANNOT_ADD_MORE_THAN_THREE_WORKOUT_IMAGES:
    "Cannot add more than three workout images",
  MEMBER_NOT_PART_OF_GRP: "This member is not a part of this group",
  NOT_PART_OF_BRAND: "This member is not a part of this brand",
  OLD_PASS_NOT_MATCH: "Old password not matched",
  MEMBERSHIP_EXIST_FAILED: "membership exit failed",
  MEMBERSHIP_NO_LONGER_EXIST: "Membership is no longer exit",
  CURRENTLY_NO_WORKOUT: "Currently no workouts",
  NO_WORKOUT_FOUND: "No workout types found",
  ADD_WORKOUT_TYPES: "- Add workout types",
  NO_REPETING_DAYS: "No repeating days found between your start and end date",
};
