import { Outlet } from "react-router-dom";
import styles from "./CommonLayout.module.css";
import React from "react";
import SideBar from "../SideBar/SideBar";

export default function CommonLayout() {
  return (
    <div className="screenOverride">
      <React.Fragment>
        <div className={styles.AppRoot}>
          <SideBar />

          <div className={styles.MainPage}>
            <div className={styles.mainPageChild}>
            <Outlet />
            </div>
         
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}
