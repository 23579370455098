import styles from "../Login/LoginSignup.module.css";
import logoImage from "../../Assests/Images/logo.png";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { MdOutlineMailOutline } from "react-icons/md";
import { MdLockOutline } from "react-icons/md";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import Loader from "../../Utils/Loader/Loader";
import { userLogin, setLoginCredential } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomInputField,
  CustomPasswordInputFieldForLogin,
} from "../../ReactBootstrap/CustomInputField/CustomInputField";

function Login() {
  const { isLoading } = useSelector((state) => state.user);
  const predefault_loginEmail = useSelector((state) => state.user.login_email);
  const predefault_loginPassword = useSelector(
    (state) => state.user.login_password
  );
  const predefault_remember = useSelector((state) => state.user.remember_me);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isRemember, setIsRemember] = useState(false);
  //-------state for showing and hiding password------
  const [shown, setShown] = useState(false);

  //--------function for form validation using useform-----------
  const { register, handleSubmit, formState, setValue, clearErrors } = useForm({
    mode: "onChange",
  });

  //-----------function for submit login form-----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("email", formdata.EMAIL);
    requestData.append("password", formdata.PASSWORD.trim());
    dispatch(userLogin(requestData)).then((responsejson) => {
      const response = responsejson.payload;
      const ResponseCode = STATUS_MSG[response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        navigate("/dashboard");
        setValue("email", "");
        setValue("password", "");

        if (isRemember) {
          dispatch(
            setLoginCredential({
              email: formdata.EMAIL,
              password: formdata.PASSWORD,
              remember: isRemember ? 1 : 0,
            })
          );
        } else {
          dispatch(
            setLoginCredential({
              email: "",
              password: "",
              remember: false,
            })
          );
        }
      } else if (
        response &&
        response.data.code === STATUS_CODES.INVALID_LOGIN_CREDENTIALS
      ) {
        Toster(t("INVALID_LOGIN_CREDENTIALS"), "error");
      } else if (
        response &&
        response.data.code === STATUS_CODES.VALIDATION_ERROR
      ) {
        Toster(response.data.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  //=========== function for manage remember me functionality===========
  useEffect(() => {
    if (predefault_remember) {
      setIsRemember(predefault_remember);
      setValue("EMAIL", predefault_loginEmail);
      setValue("PASSWORD", predefault_loginPassword);
    }
  }, []);

  return (
    <div className={styles.bgSection}>
      {isLoading ? <Loader /> : ""}
      <div className={styles.formSection}>
        <div className={styles.logoImg}>
          <img src={logoImage}></img>
        </div>
        <div className={styles.loginForm}>
          <p>
            {t("SUPER_HEADING_TEXT")}
            <span>{t("ADMIN_HEADING_TEXT")}</span>
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CustomInputField
              styleName="signUpTabInput"
              type="type"
              PlaceHolder={t("EMAIL")}
              RegisterName="EMAIL"
              register={register}
              formState={formState}
              clearErrors={clearErrors}
              onChange={(e) => {
                setValue("EMAIL", e.target.value);
                if (e.target.value == " ")
                  setValue(e.target.name, e.target.value.trim());
              }}
              icon={<MdOutlineMailOutline style={{ top: "34px" }} />}
            ></CustomInputField>

            <CustomPasswordInputFieldForLogin
              styleName="signUpTabInput2"
              PlaceHolder={t("PASSWORD")}
              RegisterName="PASSWORD"
              register={register}
              icon={<MdLockOutline style={{ top: "10px" }} />}
              formState={formState}
              clearErrors={clearErrors}
              onChange={(e) => {
                setValue("PASSWORD", e.target.value.replace(/\s/g, ""), {
                  shouldValidate: true,
                });
              }}
              isVisible={shown}
              setIsVisible={setShown}
              lockIconStyle="lockIcon"
              position={10}
            ></CustomPasswordInputFieldForLogin>

            <div className="remeberChek">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={isRemember}
                  onChange={() => setIsRemember(!isRemember)}
                />
                <label class="form-check-label" for="flexCheckDefault">
                  {t("REMEMBER_ME_TEXT")}
                </label>
              </div>
            </div>

            <div className={styles.loginBtn}>
              <button>{t("LOGIN")}</button>
            </div>
          </Form>
        </div>
        <div className={styles.heading}>
          {" "}
          <h2>{t("SUPER_ADMIN_LOGIN_TEXT")}</h2>
        </div>
      </div>
    </div>
  );
}

export default Login;
