import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import styles from "./CustomInputField.module.css";
import PhoneInput from "react-phone-input-2";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { removeExtraPlus } from "../../Utils/RemovePlus/RemovePlus";
import SelectImg from "../../Assests/Images/SelectImg.svg";
import { MASKING } from "../../Common/PhoneMasking";

export const CustomInputField = ({
  styleName,
  type,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  children,
  onChange,
  clearErrors,
  icon,
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    if (formState.errors[RegisterName]) {
      clearErrors(RegisterName);
    }
    onChange(e);
  };

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{children}</Form.Label>
      <Form.Control
        type={type}
        placeholder={PlaceHolder}
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={handleChange}
      />

      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
      {icon}
    </Form.Group>
  );
};

export const CustomPhoneInputField = ({
  styleName,
  dialCode,
  phone,
  setIsAnyChange,
  setDialCode,
  setPhone,
  countryCode,
  setCountryCode,
  errorText,
  dropdownIcon,
  Label,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Group className={styles[styleName]}>
        <Form.Label className={styles.labelText}>{Label}</Form.Label>
        <div className="phoneInputSet">
          <div className="phoneCountry">
            <div className="customPhoneDropDown">
              <p>
                {countryCode?.toUpperCase()} {removeExtraPlus(dialCode)}
              </p>
              {/* <span>{removeExtraPlus(dialCode)}</span> */}
            </div>
          </div>

          <PhoneInput
            defaultMask={MASKING[dialCode]}
            country={countryCode}
            value={`${dialCode} ${phone}`}
            onChange={(value, country) => {
              setIsAnyChange(true);
              let dialCode = country.dialCode;
              let phone = value.slice(dialCode.length, value.length);
              setDialCode(dialCode);
              setPhone(phone);
              setCountryCode(country.countryCode);
            }}
            countryCodeEditable={false}
            copyNumbersOnly={true}
            inputExtraProps={{
              style: {
                position: "relative",
              },
            }}
            // dropdownClass={styles.customDropdown}
            dropdownClass={
              dropdownIcon === "selectCountry2"
                ? styles.customDropdownWhatsApp
                : styles.customDropdown
            }
          />

          <div className="validateError">
            {phone?.length > 0 && phone?.length <= 4 && t(errorText)}
          </div>
        </div>
        <img
          src={SelectImg}
          alt="SelectImg"
          className={styles.phoneSelectImg}
        ></img>
      </Form.Group>
    </>
  );
};

export const CustomCheckBox = ({
  checked,
  styleName,
  label,
  setIsAnyChange,
  setIsStateChange,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Check
      checked={checked}
      className={styleName}
      type={"checkbox"}
      label={label}
      onChange={() => {
        setIsAnyChange(true);
        setIsStateChange(!checked);
      }}
    />
  );
};

export const CustomColorInputField = ({
  RegisterName,
  register,
  formState,
  onChange,
}) => {
  return (
    <div className={styles.inputBox}>
      <input
        type="text"
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChange}
      ></input>
    </div>
  );
};

export const CustomPasswordInputField = ({
  styleName,
  Label,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  onChange,
  isVisible,
  clearErrors,
  setIsVisible,
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    if (formState.errors[RegisterName]) {
      clearErrors(RegisterName);
    }
    onChange(e);
  };
  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{Label}</Form.Label>
      <Form.Control
        type={isVisible ? "text" : "password"}
        placeholder={PlaceHolder}
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={handleChange}
      />
      <Form.Control.Feedback type="invalid">
        {t(formState.errors.PASSWORD?.message)}
      </Form.Control.Feedback>
      {isVisible ? (
        <FaEye
          color="#727272"
          onClick={() => {
            setIsVisible(false);
          }}
        />
      ) : (
        <FaEyeSlash
          color="#727272"
          onClick={() => {
            setIsVisible(true);
          }}
        />
      )}
    </Form.Group>
  );
};

export const CustomPasswordInputFieldForLogin = ({
  styleName,
  Label,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  onChange,
  icon,
  isVisible,
  setIsVisible,
  clearErrors,
  lockIconStyle,
  position,
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    if (formState.errors[RegisterName]) {
      clearErrors(RegisterName);
    }
    onChange(e);
  };

  return (
    <Form.Group className={styles[styleName]}>
      {Label && <Form.Label>{Label}</Form.Label>}

      {icon}

      <Form.Control
        type={isVisible ? "text" : "password"}
        placeholder={PlaceHolder}
        autoFocus={true}
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={handleChange}
      />
      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>

      <div className={styles[lockIconStyle]}>
        {isVisible ? (
          <FaEye
            color="#727272"
            style={{ top: position }}
            onClick={() => setIsVisible(!isVisible)}
          />
        ) : (
          <FaEyeSlash
            color="#727272"
            style={{ top: position }}
            onClick={() => setIsVisible(!isVisible)}
          />
        )}
      </div>
    </Form.Group>
  );
};

export const CustomInputTextArea = ({
  styleName,
  type,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  children,
  onChange,
  icon,
  as,
  clearErrors,
  onKeyDown
}) => {
  const { t } = useTranslation();

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{children}</Form.Label>
      <Form.Control
        as={as}
        type={type}
        placeholder={PlaceHolder}
        {...register(RegisterName, {
          ...ValidationSchema[RegisterName],
          // onChange: onChange,
        })}

        onChange={onChange}
        onKeyDown={onKeyDown} 
        isInvalid={!!formState.errors[RegisterName]}
        // onChange={onChange}
        autoComplete="off"
      />

      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
      {icon}
    </Form.Group>
  );
};
