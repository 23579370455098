import styles from "./Policy.module.css";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader/Loader";
import TextEditor from "../../Common/TextEditor/TextEditor";
import { useForm } from "react-hook-form";
import { useController } from "react-hook-form";
import { Form } from "react-bootstrap";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../store/slices/userSlice";
import SublyApi from "../../Helpers/Api";
import Toster from "../../Utils/Toster";
import { CONSTANT } from "../../Utils/Constant";
import { STATUS_MSG } from "../../Utils/StatusMsg";
function Policy() {
  const { userToken } = useSelector((state) => state.user);
  const { t } = useTranslation("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      POLICY: "",
    },
  });

  const stripHtmlTags = (input) => input.replace(/(<([^>]+)>)/gi, "").trim();

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "POLICY",
    control,
    rules: {
      required: {
        value: true,
        message: t("ENTER_EVENT_DETAIL"),
      },
      validate: (value) => {
        const strippedValue = stripHtmlTags(value);
        return strippedValue !== "" || t("ENTER_EVENT_DETAIL");
      },
    },
  });

    //====== function for remove coloured text=====
  const cleanPastedContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");

    elements.forEach((el) => {
      el.style.color = "";
    });

    return doc.body.innerHTML;
  };

  const handleChange = (value, delta, source, editor) => {
    const sanitizedValue = value.replace(
      /^(<p>(&nbsp;|\s)*<\/p>)*|^(&nbsp;|\s)*/,
      ""
    );
    if (source === "user") {
      const cleanContent = cleanPastedContent(sanitizedValue);
      onChange(cleanContent);
    } else {
      onChange(sanitizedValue);
    }
  };

  const OnSubmit = (data) => {
    setIsLoading(true);

    const PostData = new FormData();

    PostData.append("pageType", CONSTANT.PRIVACY_POLICY);
    PostData.append("pageContent", data.POLICY);

    SublyApi.PostPolycyAndCondition(userToken, PostData).then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODES.SUCCESS) {
          Toster(t("Privacy policy updated successfully"), "success");
          hanldeGetTermsAndCondition();
        } else if (response?.data?.code == STATUS_CODES.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const hanldeGetTermsAndCondition = () => {
    setIsLoading(true);
    const response = SublyApi.GetConditionAndPolicy(
      userToken,
      CONSTANT.PRIVACY_POLICY
    );
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setValue("POLICY", response?.data?.pageContent);
      }
    });
  };

  useEffect(() => {
    hanldeGetTermsAndCondition();
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic"],
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ align: [] }], // Add alignment options to the toolbar
      ],
    },
    clipboard: {
      matchVisual: true,
    },
  };

  return (
    <div className={styles.typesWrapper}>
      {isLoading && <Loader />}
      <div className={styles.typeHeading}>
        <h4>{t("PRIVACY_POLICY")}</h4>
      </div>
      <Form onSubmit={handleSubmit(OnSubmit)}>
        <div className={styles.textEditor}>
          <TextEditor
            placeholder="Add privacy policy"
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            ref={ref}
            error={error}
            isInvalid={formState.errors.POLICY}
            modules={modules}
          />
        </div>

        <div className={styles.save}>
          <CustomButton variant="footerBtnCustom" type="submit">
            <p>
              <span>{t("SAVE")}</span>
            </p>
          </CustomButton>
        </div>
      </Form>
    </div>
  );
}

export default Policy;
