import Modal from "react-bootstrap/Modal";
import styles from "./DeleteResult.module.css";
import { useTranslation } from "react-i18next";
// import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import { CustomButton } from "../ReactBootstrap/CustomTabButton";

function CommonDelete({ show, handleClose, handleDeleteGroup }) {
  const { t } = useTranslation();
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ background: "#F5F4F4" }}>
          <Modal.Title>
            <div className={styles.modalHead}>
              <p>
                {t("Confirm")} <span>{t("Delete")}</span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            <p>
              {t("Are you sure?")} <span>{t("This cannot be undone.")}</span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "#F5F4F4" }}>
          <div className={styles.btnSection}>
            <CustomButton
              variant={"cancelBtn"}
              type="button"
              onClick={handleClose}
            >
              {t("CANCEL")}
            </CustomButton>

            <CustomButton
              variant="footerBtnCustom"
              type="button"
              onClick={handleDeleteGroup}
            >
              <p>
                {t("Confirm")} <span>{t("Delete")}</span>
              </p>
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommonDelete;
