import { useEffect, useState } from "react";
import BrandInfo from "./BrandInfo";
import Branding from "./Branding";
import Subscription from "./SubsCription";
import CustomTabButton from "../../ReactBootstrap/CustomTabButton";
import styles from "../Brands/Brands.module.css";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../Helpers/ScrollToTop";
import { useContext } from "react";
import { UserContext } from "../../Common/Context/UserContext";
import { useDispatch } from "react-redux";
import { is_modal_open } from "../../store/slices/userSlice";

function BrandTab() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [brandInfoTabShow, setBrandInfoTabShow] = useState(1);
  const { isAnyChange, setSaveChanges } = useContext(UserContext);
  const [tabIndex, setTabIndex] = useState(1);

  //======= function for manage tab==========
  const handleTabChange = () => {
    setBrandInfoTabShow(tabIndex);
    setSaveChanges(false);
  };

  useEffect(() => {
    if (!isAnyChange) {
      handleTabChange();
    }
  }, [isAnyChange, tabIndex]);

  //=========== function for manage chnages when try to change tab without saveing data==========
  const handleTryToChangeTab = (index) => {
    setTabIndex(index);
    if (isAnyChange) {
      dispatch(is_modal_open());
    }
  };

  return (
    <>
      <div className={styles.topHead}>
        <h2>
          {" "}
          {brandInfoTabShow === 1
            ? t("BRANDS > INFO")
            : brandInfoTabShow === 2
            ? t("BRANDS > BRANDING")
            : t("BRANDS > SUBSCRIPTION")}
        </h2>
        <div className={styles.devider}></div>
        <div className={styles.option}>
          <div className={styles.buttonSection}>
            <CustomTabButton
              onClick={() => handleTryToChangeTab(1)}
              variant={brandInfoTabShow === 1 ? "primary" : "secondary"}
            >
              {t("BRAND_INFO")}
            </CustomTabButton>

            <CustomTabButton
              onClick={() => handleTryToChangeTab(2)}
              variant={brandInfoTabShow === 2 ? "primary" : "secondary"}
            >
              {t("BRANDING")}
            </CustomTabButton>

            <CustomTabButton
              onClick={() => handleTryToChangeTab(3)}
              variant={brandInfoTabShow === 3 ? "primary" : "secondary"}
            >
              {t("SUBSCRIPTION")}
            </CustomTabButton>
          </div>
        </div>
        <div className={styles.devider}></div>

        {brandInfoTabShow === 1 ? (
          <>
            {" "}
            <ScrollToTop />
            <BrandInfo />
          </>
        ) : brandInfoTabShow === 2 ? (
          <Branding />
        ) : (
          <Subscription />
        )}
      </div>
    </>
  );
}

export default BrandTab;
