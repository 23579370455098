export const GRIS_DATA = [
  {
    name: "MEMBERS",
    count: 1245,
  },
  {
    name: "GROUPS",
    count: 4,
  },
  {
    name: "TEMPLATES",
    count: 345,
  },
  {
    name: "NEWS",
    count: 1245,
  },
  {
    name: "EVENTS",
    count: 1245,
  },
  {
    name: "RESULTS",
    count: 345,
  },
];

export const MEMBERS = [
  {
    name: "John Aardvark",
    age: "23",
    date: "11 November 2025",
  },
  {
    name: "adams ale",
    age: "23",
    date: "01 November 2025",
  },
  {
    name: "pablo escobar",
    age: "43",
    date: "23 November 2025",
  },
  {
    name: "bishnoi lawrence",
    age: "21",
    date: "18 November 2025",
  },
  {
    name: "dikky lance",
    age: "15",
    date: "26 November 2025",
  },
  {
    name: "richard harmster",
    age: "18",
    date: "14 November 2025",
  },
  {
    name: "calvin",
    age: "27",
    date: "12 November 2025",
  },
  {
    name: "denny desuza",
    age: "22",
    date: "12 November 2025",
  },
  {
    name: "michael jackson",
    age: "21",
    date: "12 November 2025",
  },
  {
    name: "prince chawl",
    age: "65",
    date: "12 November 2025",
  },
  {
    name: "hero janny",
    age: "23",
    date: "15 November 2025",
  },
  {
    name: "avinash panday",
    age: "32",
    date: "28 November 2025",
  },
  {
    name: "vikky dans",
    age: "33",
    date: "30 November 2025",
  },
  {
    name: "queen elezabeth",
    age: "34",
    date: "30 November 2025",
  },
  {
    name: "John Aardvark",
    age: "54",
    date: "26 November 2025",
  },
];

export const GROUP = [
  {
    group_name: "A School",
    member_name: "Johnny and Gina",
    members: "23",
  },
  {
    group_name: "B School",
    member_name: "Harsh and Gina",
    members: "23",
  },
  {
    group_name: "C School",
    member_name: "Boxks and Gina",
    members: "23",
  },
  {
    group_name: "D School",
    member_name: "Dear and Gina",
    members: "23",
  },
  {
    group_name: "E School",
    member_name: "Herry and Gina",
    members: "23",
  },
  {
    group_name: "F School",
    member_name: "Johns and Gina",
    members: "23",
  },
  {
    group_name: "G School",
    member_name: "Johnny and Jack",
    members: "23",
  },
  {
    group_name: "H School",
    member_name: "Johnny and Krish",
    members: "23",
  },
  {
    group_name: "I School",
    member_name: "Newton and Gina",
    members: "23",
  },
  {
    group_name: "J School",
    member_name: "Johnny and Pols",
    members: "23",
  },
  {
    group_name: "K School",
    member_name: "Johnny and Niks",
    members: "23",
  },
];

export const SEND_INVITATION = [
  {
    name: "shobhit",
    email: "shobhit@gmail.com",
  },
  {
    name: "aman",
    email: "aman@gmail.com",
  },
  {
    name: "shyam",
    email: "shyam@gmail.com",
  },
  {
    name: "rocky",
    email: "rocky@gmail.com",
  },
  {
    name: "chintu",
    email: "chintu@gmail.com",
  },
  {
    name: "mony",
    email: "mony@gmail.com",
  },
];

export const WORKOUT_POINT = [
  {
    id: 1,
    point: "Comrades 32km Hill Training - Distance Running",
  },
  {
    id: 2,
    point:
      "Example of a very long headng and how the two lines would look - Distance Running",
  },
  {
    id: 3,
    point: "Big Fred Hill Training - Hillwork",
  },
  {
    id: 4,
    point: "Comrades 32km Hill Training - Distance Running",
  },
  {
    id: 5,
    point: "Anoter Workout Name",
  },
  {
    id: 6,
    point: "Big Fred Hill Training - Hillwork",
  },
];

export const DATE_TYPE = [
  {
    id: 1,
    datetype: "Single Date",
  },
  {
    id: 2,
    datetype: "Date Range",
  },
  {
    id: 3,
    datetype: "Repeating Event",
  },
];

export const REPEATING_DAYS = [
  {
    id: 1,
    day: "Sun",
  },
  {
    id: 2,
    day: "Mon",
  },
  {
    id: 3,
    day: "Tues",
  },
  {
    id: 4,
    day: "Wed",
  },
  {
    id: 5,
    day: "Thurs",
  },
  {
    id: 6,
    day: "Fri",
  },
  {
    id: 7,
    day: "Sat",
  },
];

export const MONTH_OF_YEAR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const MAP_CENTER = {
  lat: -30.559482,
  lng: 22.937506,
};
