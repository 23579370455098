import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import styles from "./WorkoutType.module.css";
import { Form } from "react-bootstrap";
import SublyApi from "../../Helpers/Api";
import { CustomInputField } from "../../ReactBootstrap/CustomInputField/CustomInputField";
import Loader from "../../Utils/Loader/Loader";
import { userLogoutClear } from "../../store/slices/userSlice";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useLocation, useNavigate } from "react-router-dom";

function AddWorkoutType({}) {
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const workoutTypeId = location?.state?.workoutTypeId;
  const workoutName = location?.state?.name;
  const [workoutType, setWorkoutType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState, reset, setValue, clearErrors } =
    useForm({
      mode: "onChange",
    });

  //======= function for show workout type prefilled update form=======
  useEffect(() => {
    if (workoutTypeId) {
      setWorkoutType(workoutName);
      setValue("ADD_WORKOUT_TYPES", workoutName);
    }
  }, [workoutTypeId]);

  // ======  function for calling save workout type=====
  const onSubmit = () => {
    const PostData = new FormData();
    if (workoutTypeId) {
      PostData.append("workoutTypeId", workoutTypeId);
    }
    PostData.append("workoutTypeName", workoutType);
    setIsLoading(true);
    SublyApi.saveWorkoutType(userToken, PostData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODES.SUCCESS) {
          setIsLoading(false);
          if (workoutTypeId) {
            Toster(t("UPDATE_WORKOUT_TYPE"), "success");
          } else {
            Toster(t("ADD_WORKOUT_TYPE"), "success");
          }
          navigate("/workout-page");
        } else if (response?.data?.code == STATUS_CODES.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    setWorkoutType(e.target.value);
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

 
  return (
    <>
      <div className={styles.typeHeading}>
        <h4>{t("WORKOUT_TYPES_TEXT")}</h4>
      </div>
      <div className={styles.addEDit}>
        {isLoading && <Loader />}

        <div className={styles.workoutTYpes}>
          <p>
            Add / Edit
            <span> Workout Types </span>
          </p>
        </div>

        <div className={styles.typeInput}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CustomInputField
              styleName="inputTextForTraining"
              type="text"
              PlaceHolder={t("WORKOUT_TYPE_TEXT")}
              RegisterName={"ADD_WORKOUT_TYPES"}
              register={register}
              formState={formState}
              value={workoutType}
              clearErrors={clearErrors}
              onChange={handleInputChange}
            ></CustomInputField>
            <div className={styles.workoutTYpes}>
              <div className={styles.bottomBtn}>
                <CustomButton
                  variant="cancelBtn"
                  type="button"
                  onClick={() => {
                    navigate(-1);
                    // workoutTypeId("");
                  }}
                >
                  {t("CENCEL")}
                </CustomButton>
                <CustomButton variant="footerBtnCustom" type="submit">
                  <p>
                    Save <span>Type</span>
                  </p>
                </CustomButton>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddWorkoutType;
