import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS_CODES } from "../../Utils/StatusCode";
import SublyApi from "../../Helpers/Api";

const initialState = {
  currentUser: {},
  brandListData: [],
  userId: null,
  brandDetailData: {},
  loadMore: false,
  currentPage: 1,
  metaList: [],
  memberListData: [],
  memberDetailData: [],
  isLoading: false,
  userToken: null,
  success: false,
  message: false,
  error: null,
  login_email: null,
  login_password: null,
  remember_me: null,
  open_modal: false,
  is_any_change: false,
};

//--------- Thunk for user login-------
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//--- Thunk for brand list---------
export const fetchBrandList = createAsyncThunk(
  "brandList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.brandList(
        data.userToken,
        data.page_no,
        data.search,
        data.listForAddMember
      );
      return {
        response: response,
        page_no: data.page_no,
        onLoadMore: data.onLoadMore,
        listForAddMember: data.listForAddMember,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//--- Thunk for brand list---------
export const fetchBrandDetail = createAsyncThunk(
  "brandDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.brandDetail(data.userToken, data.brandId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for fetch brand info
export const saveBrandInfo = createAsyncThunk(
  "/brandInfo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.brandInfo(
        data.requestData,
        data.userToken
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for fetch branding
export const saveBranding = createAsyncThunk(
  "/branding",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.brandBranding(
        data.requestData,
        data.userToken,
        data.brandId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for fetch subscriptions
export const addSubscription = createAsyncThunk(
  "/subcription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.brandSubscription(
        data.requestData,
        data.userToken,
        data.brandId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//--- Thunk for brand list---------
export const fetchMemberList = createAsyncThunk(
  "memberList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.memberList(
        data.userToken,
        data.page_no,
        data.search,
        data.sort
      );

      return {
        response: response,
        page_no: data.page_no,
        onLoadMore: data.onLoadMore,
        sort: data.sort,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//--- Thunk for brand list---------
export const fetchMemberDetail = createAsyncThunk(
  "memberDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.memberDetail(
        data.userToken,
        data.memberId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    //====function for user logOut====
    userLogoutClear: (state) => {
      state.currentUser = {};
      state.brandListData = [];
      state.brandDetailData = [];
      state.memberListData = [];
      state.isLoading = false;
      state.userToken = null;
    },
    setLoginCredential: (state, action) => {
      state.login_email = action.payload.email;
      state.login_password = action.payload.password;
      state.remember_me = action.payload.remember;
    },

    is_modal_open: (state) => {
      state.open_modal = true;
    },
    is_modal_close: (state) => {
      state.open_modal = false;
    },
    isAnyChange: (state, action) => {
      state.is_any_change = true;
    },
  },
  extraReducers: (builder) => {
    //--------- user login-------------
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      state.userId = response?.data?.id;
      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.userToken = response.data.token;
        state.currentUser = response.data;

        state.success = true;
      } else {
        state.userToken = null;
        state.currentUser = {};
        state.success = false;
      }
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //-------- builder for brand List-----------
    builder.addCase(fetchBrandList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.isLoading = false;
      const { response, onLoadMore } = action.payload;

      if (response && response.code === STATUS_CODES.SUCCESS) {
        if (onLoadMore) {
          state.brandListData = [
            ...state.brandListData,
            ...response.data.brandList,
          ];
        } else {
          state.brandListData = response.data.brandList;
        }
        state.loadMore = response.data.loadMore;
        state.success = true;
        state.currentPage++; // Increment current page after successful load
      } else {
        state.success = false;
        state.error = response?.message || "An error occurred.";
      }
    });
    builder.addCase(fetchBrandList.rejected, (state, action) => {
      state.isLoading = false;
    });

    //----- brand info----------
    builder.addCase(saveBrandInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveBrandInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      // const response = action.payload;
      // state.currentUser = response.data;
    });
    builder.addCase(saveBrandInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //----- brand branding info----------
    builder.addCase(saveBranding.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveBranding.fulfilled, (state, action) => {
      state.isLoading = false;
      // const response = action.payload;
      // state.currentUser = response.data;
    });
    builder.addCase(saveBranding.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //----- brand subscription info----------
    builder.addCase(addSubscription.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addSubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      // state.currentUser = response.data;
    });

    builder.addCase(addSubscription.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //---brand detail-------------
    builder.addCase(fetchBrandDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBrandDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;

      if (response && response.code && response.code === STATUS_CODES.SUCCESS) {
        state.brandDetailData = response.data; // Store brandInfo in brandDetailData state
        state.success = true;
      } else {
        state.success = false;
        state.brandDetailData = {};
      }
    });

    builder.addCase(fetchBrandDetail.rejected, (state, action) => {
      state.isLoading = false;
    });

    //-------- builder for brand List-----------
    builder.addCase(fetchMemberList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMemberList.fulfilled, (state, action) => {
      state.isLoading = false;
      const { response, onLoadMore } = action.payload;
      if (response && response.code === STATUS_CODES.SUCCESS) {
        if (onLoadMore) {
          state.memberListData = [
            ...state.memberListData,
            ...response.data.memberList,
          ];
        } else {
          state.memberListData = response.data.memberList;
        }
        state.loadMore = response.data.loadMore;
        state.success = true;
        state.currentPage++; // Increment current page after successful load
      } else {
        state.success = false;
        state.error = response?.message || "An error occurred.";
      }
    });
    builder.addCase(fetchMemberList.rejected, (state, action) => {
      state.isLoading = false;
    });

    //---brand detail-------------
    builder.addCase(fetchMemberDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMemberDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;

      if (response && response.code && response.code === STATUS_CODES.SUCCESS) {
        state.memberDetailData = response.data; // Store brandInfo in memberDetailData state
        state.success = true;
      } else {
        state.success = false;
        state.memberDetailData = {};
      }
    });

    builder.addCase(fetchMemberDetail.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default userSlice.reducer;
export const {
  userLogoutClear,
  setLoginCredential,
  is_modal_close,
  is_modal_open,
  isAnyChange,
} = userSlice.actions;
