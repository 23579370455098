import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { SidebarJSON } from "./SideBarJSON";
import styles from "./SideBar.module.css";
import { IoMdLogOut } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { userLogoutClear } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../Helpers/Api";
import Loader from "../../Utils/Loader/Loader";

function SideBar() {
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
  }, []);

  var isExpand = windowSize > 991;

  const handleLogout = () => {
    setIsLoading(true);

    SublyApi.HandleLogout(isAuthenticated).then((response) => {
      setIsLoading(false);
      dispatch(userLogoutClear());
      navigate("/");
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.Sidebar}>
        <Navbar data-bs-theme="dark" expand={isExpand} className="NavBody">
          <div className="test">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${"expand"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"expand"}`}
            placement="start"
            style={{ width: "260px" }}
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body className={styles.CanvasBody}>
              <div className={styles.superAdminBg}>
                <div className={styles.SideBarHeader}>
                  <h2>{t("SIDEBAR_SUPERADMIN")}</h2>
                </div>
              </div>

              <div className={`${styles.NavItems} activeClass`}>
                {SidebarJSON.map((item, index) => {
                  return (
                    <NavLink
                      to={item.navigateTo}
                      className={styles.Navlink}
                      key={index}
                    >
                      <div>{item.icon}</div>

                      {item.title}
                    </NavLink>
                  );
                })}
                {/* <NavLink className={styles.NavlinkButton} onClick={() => navigate("/")}>
                  <div  onClick={() => navigate("/")}>
                    <IoMdLogOut size={20} className="mx-3" />
                  </div>

                  {"Logout"}
                </NavLink> */}
                <div
                  className={styles.NavlinkButton}
                  onClick={() => handleLogout()}
                >
                  <div>
                    <IoMdLogOut size={20} className="mx-3" />
                  </div>
                  {"Logout"}
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      </div>
    </>
  );
}

export default SideBar;
