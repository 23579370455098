import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import styles from "../Members/DeleteMember.module.css";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";

function MembershipModal({ show, handleClose, memberBrandName }) {
  const { t } = useTranslation("");
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{ background: "#F5F4F4" }}>
        <Modal.Title>
          <div className={styles.modalHead}>
            <p>
              {t("MEMBER_BTN")} <span>{t("LIMIT")}</span>
            </p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.modalBody}>
          <span> {memberBrandName} has a limit of ACTIVE members.</span>
          <p>
            {" "}
            To activate this member you first need to deactivate another member
            or increase member subscription.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ background: "#F5F4F4" }}>
        <div className={styles.btnSection}>
          <CustomButton
            variant={"cancelBtn"}
            type="button"
            onClick={handleClose}
          >
            {t("CLOSE")}
          </CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default MembershipModal;
