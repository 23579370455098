import Modal from "react-bootstrap/Modal";
import styles from "./ColorPicker.module.css";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { UserContext } from "../../Common/Context/UserContext";

function ColorPicker({ handleClose, show, selectedColor, setSelectedColor }) {
  const { t } = useTranslation();

  const { setIsAnyChange } = useContext(UserContext);

  //====== function for handle color selection=====
  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    setIsAnyChange(true);
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className={styles.heading}>
              <p>
                {t("SELECT")} <span>{t("COLOR")}</span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <div className={styles.colorPickerBox}>
          <SketchPicker
            className={styles.SketchPicker}
            color={selectedColor}
            onChange={handleColorChange}
          />
        </div>
      </Modal>
    </>
  );
}

export default ColorPicker;
