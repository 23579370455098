import styles from "../Brands/Brands.module.css";
import { Form } from "react-bootstrap";
import {useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { fetchBrandList, userLogoutClear } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Loader from "../../Utils/Loader/Loader";
import { debounce } from "lodash";
import companyImage from "../../Assests/Images/CompanyLogo.png";
import CustomTabButton, {
  CustomButton,
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import PlusIcon from "../../Assests/Images/PlusIcon.png";
import Cross from "../../Assests/Images/cross.png";
import Edit from "../../Assests/Images/Edit.png";
import SublyApi from "../../Helpers/Api";
import Toster from "../../Utils/Toster";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import CommonDelete from "../../Common/CommonDelete";

function Brands() {
  const { userToken, isLoading, brandListData, loadMore } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [brandSearch, setBrandSearch] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState("");
  const [brandTabShow, setBrandTabShow] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);
  const [loading, setLoading] = useState(false);


  //========== function for calling get brand list api==========
  async function getBrandList(
    pageNum = 1,
    brandSearch = "",
    onLoadMore = false,
    listForAddMember = 0
  ) {
    await dispatch(
      fetchBrandList({
        userToken: userToken,
        page_no: pageNum,
        search: brandSearch,
        onLoadMore: onLoadMore,
        listForAddMember: listForAddMember,
      })
    ).then((responsejson) => {
      const { response } = responsejson.payload;
      setCount(response.data.count);
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      }
    });
  }

  //===== Define a debounce function for handling search input change==========
  const debouncedSearch = debounce((searchValue) => {
    getBrandList(1, searchValue);
  }, 500);

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    setBrandSearch(searchValue);
    if (searchValue.trim() !== "") {
      debouncedSearch(searchValue);
    } else {
      getBrandList(); 
    }
  };

  //======== function for handle pagination============
  const handleLoadMore = () => {
    getBrandList(page + 1, brandSearch, true);
    setPage(page + 1);
  };

  useEffect(() => {
    getBrandList();
  }, []);

  

  // =======function for calling logOut api=======
  const brandDelete = () => {
    setLoading(true);
    setDeleteModal(false);
    SublyApi.deleteBrand(userToken, brandIdToDelete).then((responsejson) => {
      const ResponseCode =
        STATUS_MSG[
          (responsejson && responsejson.code) ||
            (responsejson && responsejson.data.code)
        ];
      if (responsejson.code === STATUS_CODES.SUCCESS) {
        Toster(t("BRAND_DELETE_SUCCUSS"), "success");
        setLoading(false);
        navigate("/brands");
        setBrandSearch(""); 
        getBrandList();
        setDeleteModal(false);
        setBrandSearch("")
      } else if (responsejson.code === STATUS_CODES.VALIDATION_ERROR) {
        Toster(responsejson.code, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  const handleBrandDelete = (brandId) => {
    setBrandIdToDelete(brandId);
    setDeleteModal(true);
  };

  return (
    <div className={styles.brandBg}>
      <div className={styles.topHead}>
        <h2>{t("BRANDS")}</h2>
      </div>
      <div className={styles.devider}></div>
      <div className={styles.brandOption}>
        <div className={styles.leftOption}>
          <CustomTabButton
            onClick={() => navigate("/brands")}
            variant={brandTabShow === 1 ? "primary" : "secondary"}
          >
            {t("BRAND_TAB")}
          </CustomTabButton>

          <div
            className={styles.plusIcon}
            onClick={() => navigate("/brands/add-brands")}
          >
            <CustomImgButton
              variant="primary"
              onClick={() => setBrandTabShow(2)}
              src={PlusIcon}
              alt="PlusIcon"
            ></CustomImgButton>
          </div>
        </div>

        <div className={styles.searchBar}>
          <Form.Group className={styles.formGroup}>
            <Form.Control
              className={styles.searchControl}
              type="search"
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={brandSearch}
              onChange={handleSearchInputChange}
            />
          </Form.Group>
        </div>
      </div>
      <div className={styles.devider}></div>
      {brandListData && brandListData.length > 0 ? (
        brandListData.map((item, index) => {
          const notActive = item.activeMembership === 0;
          return (
            <div
              key={index}
              className={`${styles.brandList} ${
                notActive ? styles.highlighted : ""
              }`}
            >
              <div
                className={styles.leftContent}
                onClick={() =>
                  navigate(`/brands/brand-info`, {
                    state: { brand_id: item.id },
                  })
                }
              >
                <div className={styles.nameList}>
                  {item.appLogoFilename ? (
                    <img src={item.appLogoFilename} alt={item.title} />
                  ) : (
                    <img src={companyImage} alt="Default" />
                  )}
                </div>

                <div className={styles.paraText}>
                  <h4>{item.clubName}</h4>
                  <p>{item.address}</p>

                  <span>
                    {item.total_members}/{item.memberLimit}{" "}
                    {item.memberLimit === 1 ? "Member" : "Members"}
                  </span>
                </div>
              </div>

              <div className={styles.editCross}>
                <CustomImgButton
                  onClick={() => handleBrandDelete(item.id)}
                  variant="primary"
                  src={Cross}
                  alt="Cross"
                ></CustomImgButton>

                <CustomImgButton
                  variant="primary"
                  onClick={() =>
                    navigate(`/brands/brand-info`, {
                      state: { brand_id: item.id },
                    })
                  }
                  src={Edit}
                  alt="Edit"
                ></CustomImgButton>
              </div>
            </div>
          );
        })
      ) : isLoading ? (
        <Loader />
      ) : (
        <div className={styles.noResultsMessage}>{t("NO_BRAND_FOUND")}</div>
      )}
      {isLoading && <Loader />}

      <div className={styles.eventBox}>
        <div className={styles.btnSection}>
          <div
            className={styles.addBtn}
            onClick={() => navigate("/brands/add-brands")}
          >
            <CustomButton variant="footerBtnCustom" type="button">
              <p>
                {t("ADD_BRAND")}
                <span>{t("BRAND_HIGHLIGHT_TEXT")}</span>
              </p>
            </CustomButton>
          </div>

          <div className={styles.eventText}>
            <p>
              {count}{" "}
              <span>{count <= 1 ? t("BRAND_TAB") : t("BRANDS_TAB")}</span>
            </p>
          </div>

          {loadMore && (
            <div className={styles.loadMore}>
              <CustomButton
                variant="footerBtnCustom"
                type="button"
                onClick={handleLoadMore}
              >
                <p>
                  {t("LOAD__BTN")} <span>{t("MORE_BTN")}</span>
                </p>
              </CustomButton>
            </div>
          )}
        </div>
      </div>

      <CommonDelete
        show={deleteModal}
        handleClose={() => {
          setDeleteModal(false);
        }}
        handleDeleteGroup={brandDelete}
      />
    </div>
  );
}

export default Brands;
