import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Routers from "./Routes/Routers";
import "../src/Assests/Styles/Common.css";
import { BrowserRouter } from "react-router-dom";
import { ContextProvider } from "./Common/Context/UserContext";

function App() {
  return (
    <>
      <BrowserRouter>
        <ContextProvider>
          <I18nextProvider i18n={i18n}>
            <Routers />
          </I18nextProvider>
          <ToastContainer />
        </ContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;

// import "./App.css";
