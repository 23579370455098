import React from "react";

import ReactQuill from "react-quill";
function TextEditor({
  placeholder,
  value,
  onChange,
  onBlur,
  ref,
  error,
  modules,
}) {
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "align", // Add align to formats
  ];


  return (
    <div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className="brandTextEditor"
        ref={ref}
        modules={modules}
        formats={formats}
      />
      <div className="validateError">
        {error && <p className="error-message">{error.message}</p>}
      </div>
    </div>
  );
}

export default TextEditor;
